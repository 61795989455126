import {
  DlcBtnState,
  DlcSaveBtnState,
  GigaNoteDoc,
  GigaAssetState,
  GigaActionState
} from '@gigasoftware/shared/api';
import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromNoteAddEdit from './note-add-edit.reducer';
import {
  DlcInputImageConfig,
  DlcNoteStore,
  DlcNoteTranscribedTextIdNull,
  DlcNoteUserTextIdNull
} from './note.model';

export const noteAddEditState = createFeatureSelector<DlcNoteStore>(
  fromNoteAddEdit.nodeAddEditFeatureKey
);

export const selectNoteAddEditFirestoreDoc = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): GigaNoteDoc | null =>
    state
      ? (JSON.parse(JSON.stringify(state.firestoreDoc)) as GigaNoteDoc)
      : null
);

// use with stringPopulated()
export const selectNoteAddEditFirestorePath = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.firestorePath
);

export const selectNoteAddEditIsDirty = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): boolean => state.isDirty
);

// use with distinctUntilChanged
export const selectNoteAddEditLatestNoteVersion = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.latestNoteVersion
);
// use with stringPopulated()
export const selectAddEditNoteTitle = createSelector(
  selectNoteAddEditFirestoreDoc,
  (firestoreDoc: GigaNoteDoc | null): string | null =>
    firestoreDoc?.title || null
);

// use with stringPopulated()
export const selectNoteAddEditLatestTransitionVersion = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.latestTransitionVersion
);

export const selectNoteAddEditProcessQueueBtnState = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcSaveBtnState => state.processQueueBtnState
);

export const selectNoteAddEditSaveBtnState = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcSaveBtnState => state.saveBtnState
);

// use with stringPopulated()
export const selectNoteAddEditTranscribedNote = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.transcribedNote
);

export const selectNoteAddEditTranscribedTextId = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcNoteTranscribedTextIdNull => ({
    firestorePath: state.firestorePath,
    transcribedTextId: state.transcribedTextId
  })
);

export const selectNoteAddEditUploadConfig = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): Partial<GSFirebaseUploadImageConfig> | null =>
    state.uploadConfig
);

export const selectNoteAddEditUserNote = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.userNote
);

export const selectNoteAddEditUserTextId = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcNoteUserTextIdNull => ({
    firestorePath: state.firestorePath,
    userTextId: state.userTextId
  })
);

export const selectNoteAddEditUuid = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): string | null => state.uuid
);

// use with stringPopulated()
export const selectImageIsUploaded = createSelector(
  selectNoteAddEditFirestoreDoc,
  (firestoreDoc: GigaNoteDoc | null) =>
    firestoreDoc?.imageState === GigaAssetState.UPLOADED
);

// use with distinctUntilJsonChangedOperator()
export const selectUploadConfig = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): Partial<GSFirebaseUploadImageConfig> | null =>
    state.uploadConfig
);

export const selectTranscribeBtnState = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcBtnState => {
    if (state.firestoreDoc) {
      if (state.firestoreDoc.imageState === GigaAssetState.UPLOADED) {
        if (
          state.firestoreDoc.transcribeState === GigaActionState.IN_PROGRESS
        ) {
          return DlcBtnState.IN_PROGRESS;
        }
        return DlcBtnState.ACTIVE;
      }
    }
    return DlcBtnState.DISABLED;
  }
);

// use with
// .pipe(
//     filter((config: DlcInputImageConfig | null) => config !== null),
//     distinctUntilKeyChanged('imagePath')
//   )
export const selectResolvedImageConfig = createSelector(
  noteAddEditState,
  (state: DlcNoteStore): DlcInputImageConfig | null => {
    if (state.uuid && state.uploadConfig && state.firestorePath) {
      return <DlcInputImageConfig>{
        filenameWithoutExtension: state.uuid,
        imagePath: state.firestoreDoc?.imagePath || '',
        uploadConfig: {
          ...state.uploadConfig,
          firestoreDoc: {
            docProperty: 'imagePath',
            firestoreDocPath: state.firestorePath
          }
        }
      };
    }

    return null;
  }
);
