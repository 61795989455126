import {
  DlcSaveBtnState,
  GigaNoteDoc,
  RecursivePartial
} from '@gigasoftware/shared/api';
import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {createAction, createActionGroup, emptyProps, props} from '@ngrx/store';

import {DlcNoteProcessQueue} from './note.model';

export const NoteAddEditActions = createActionGroup({
  events: {
    'Load NoteAddEdits': emptyProps()
  },
  source: 'NoteAddEdit'
});

export const setCurrentNoteEntity = createAction(
  '[Note/API] Set Current Note Entity',
  props<{note: GigaNoteDoc}>()
);

export const clearCurrentNoteEntity = createAction(
  '[Note/API] Clear Current Note Entity'
);

export const updateCurrentNoteEntity = createAction(
  '[Note/API] Update Current Note Entity',
  props<{changes: Partial<GigaNoteDoc>}>()
);

// Save note to firestore
export const saveNoteTitle = createAction(
  '[Note/API] Save Note Title',
  props<DlcNoteProcessQueue>()
);

export const saveUserNoteVersion = createAction(
  '[Note/API] Save User Note Version',
  props<DlcNoteProcessQueue>()
);

export const saveTranscriptionNoteVersion = createAction(
  '[Note/API] Save Transcription Note Version',
  props<DlcNoteProcessQueue>()
);

export const saveFirestoreDoc = createAction(
  '[Note/API] Save Firestore Doc',
  props<DlcNoteProcessQueue>()
);

// export const saveNote = createAction(
//   '[Note/API] Save Note',
//   props<{queue: DlcNoteProcessQueue}>()
// );

export const startNoteProcessQueue = createAction(
  '[Note/API] START NOTE PROCESS QUEUE'
);

export const addFirestoreDoc = createAction(
  '[Note/API] Add Firestore Doc',
  props<{doc: GigaNoteDoc}>()
);

export const _addFirestoreDoc = createAction(
  '[Note/API] Add Firestore Doc Reducer',
  props<{doc: GigaNoteDoc; isDirty: boolean; saveBtnState: DlcSaveBtnState}>()
);

export const patchFirestoreDoc = createAction(
  '[Note/API] Patch Firestore Doc',
  props<{patch: RecursivePartial<GigaNoteDoc>}>()
);

export const updateFirestorePath = createAction(
  '[Note/API] Update Firestore Path',
  props<{path: string}>()
);

export const updateIsDirty = createAction(
  '[Note/API] Update Is Dirty',
  props<{isDirty: boolean}>()
);

export const addNoteUuid = createAction(
  '[Note/API] Add Note UUID',
  props<{uuid: string}>()
);

export const updateLatestNoteVersion = createAction(
  '[Note/API] Update Latest Note Version',
  props<{latestNoteVersion: string}>()
);

export const updateLatestTranscriptionVersion = createAction(
  '[Note/API] Update Latest Transcription Version',
  props<{latestTransitionVersion: string}>()
);

export const updateUploadConfig = createAction(
  '[Note/API] Update Upload Config',
  props<{uploadConfig: Partial<GSFirebaseUploadImageConfig>}>()
);

export const _updateNoteTitle = createAction(
  '[Note/API] Private Update Note Title',
  props<{isDirty: boolean; saveBtnState: DlcSaveBtnState}>()
);

export const updateNoteTitle = createAction(
  '[Note/API] Update Note Title',
  props<{title: string}>()
);

export const _updateTranscribedNote = createAction(
  '[Note/API] Update Transcribed Note',
  props<{
    transcribedNote: string;
    isDirty: boolean;
    saveBtnState: DlcSaveBtnState;
  }>()
);

export const _updateUserNote = createAction(
  '[Note/API] Update User Note',
  props<{isDirty: boolean; saveBtnState: DlcSaveBtnState; userNote: string}>()
);

export const updateSaveBtnState = createAction(
  '[Note/API] Update Save Btn State',
  props<{saveBtnState: DlcSaveBtnState}>()
);

export const updateProcessQueueBtnState = createAction(
  '[Note/API] Update Process Queue Btn State',
  props<{processQueueBtnState: DlcSaveBtnState}>()
);

export const addUserNoteVersion = createAction(
  '[Note/API] Add User Note Version',
  props<{newNote: string}>()
);

export const addTranscribedNoteVersion = createAction(
  '[Note/API] Add Transcribed Note Version',
  props<{newNote: string}>()
);

export const updateFirestoreDocPromise = createAction(
  '[Note/API] Update Firestore Doc Promise',
  props<{update: RecursivePartial<GigaNoteDoc>}>()
);

export const doTranscribeImage = createAction(
  '[Note/API] Do Transcribe Image',
  props<RecursivePartial<GigaNoteDoc>>()
);

export const clearAddEditNoteStore = createAction(
  '[Note/API] Clear Add Edit Note Store'
);
