import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {Actions, createEffect, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';

import {ngPatWebsocketRegistryInitStore} from './websocket-registry.actions';
import {selectNgpatDoConnect} from './websocket-registry.selectors';

@Injectable()
export class NgPatWebsocketRegistryEffects implements OnInitEffects {
  initStore$ = createEffect(
    () => {
      return this.store.select(selectNgpatDoConnect).pipe(
        tap((doConnect: boolean) => {
          this.customFirebase.doConnectToFirestore = doConnect;
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private customFirebase: NgPatFirestoreService,
    private store: Store
  ) {}

  ngrxOnInitEffects(): Action {
    return ngPatWebsocketRegistryInitStore();
  }
}
