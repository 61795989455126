<button
  dlc-rounded-text-icon-button
  (click)="$event.stopPropagation(); onGenerateQuiz()"
  [disabled]="isDisabled()"
  color="primary">
  @if (isActive()) {
      <mat-icon>add</mat-icon>
      Create Quiz } @else {
      <mat-progress-spinner
              class="mr-2"
              diameter="16"
              mode="indeterminate"></mat-progress-spinner>
      {{btnText()}}
    }
</button>
