import {BASE_COLLECTION_NAME} from '../base-collection-path';
import {createEntityParamsFromParentEntity} from '../entity.fns';
import {BaseEntity, CreateEntityParams} from '../entity.model';
import {
  firestoreEntityPathByParams,
  firestoreQueryPathByEntity
} from './aggregate';

export function firestoreQuizGradesByEntity(p: BaseEntity, uid: string) {
  if (
    p.parentEntityID &&
    p.parentEntityID.length &&
    p.parentEntityType !== null &&
    p.parentEntityType !== undefined
  ) {
    const params: CreateEntityParams = createEntityParamsFromParentEntity(p);
    return `${firestoreEntityPathByParams(params, uid, p.parentEntityID)}/${
      BASE_COLLECTION_NAME.QUIZ_RESULTS
    }`;
  } else {
    return `${firestoreQueryPathByEntity(p, uid)}/${
      BASE_COLLECTION_NAME.QUIZ_RESULTS
    }`;
  }
}

export function firestoreQuizGradeByEntity(
  p: BaseEntity,
  uid: string,
  resultID: string
) {
  return `${firestoreQuizGradesByEntity(p, uid)}/${resultID}`;
}
