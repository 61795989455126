import {Inject, Injectable, signal, WritableSignal} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {WINDOW} from '@gigasoftware/shared/utils';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatSwUpdateService {
  updateProgress: WritableSignal<boolean> = signal(false);

  updateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private updates: SwUpdate,
    @Inject(WINDOW) private _window: Window
  ) {}

  /**
   * Not using this for now.
   * The notification effects is checking for updates from firestore and
   * dispatching a notfication to the user.
   * When the user clicks on the notification, the app is reloaded.
   * libs/evolving-cognition/domain/src/lib/+notification/notification.effects.ts:47
   */
  // watchForUpdate(): void {
  //   if (this._updates.isEnabled) {
  //     this._updates.versionUpdates.subscribe((e: VersionEvent) => {
  //       // console.log(e);
  //       if (e.type === 'VERSION_READY') {
  //         this.activateUpdate();
  //       }
  //       if (e.type === 'NO_NEW_VERSION_DETECTED') {
  //         this.updateProgress.set(false);
  //       }
  //     });
  //   } else {
  //     this.updateProgress.set(false);
  //   }
  // }

  async checkForUpdate(): Promise<void> {
    this.updateProgress.set(true);

    try {
      const updateFound = await this.updates.checkForUpdate();

      if (updateFound) {
        console.log(
          updateFound
            ? 'A new version is available.'
            : 'Already on the latest version.'
        );
        this._window.location.reload();
      }
    } catch (err) {
      console.error('Failed to check for updates:', err);
      this.updateProgress.set(false);
      this._window.location.reload();
    }

    // this.updates.versionUpdates
    //   .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
    //   .subscribe(evt => {
    //     // Reload the page to update to the latest version.
    //     this._window.location.reload();
    //   });

    // try {
    //   const updateFound = await this.updates.checkForUpdate();
    //
    //   if (updateFound) {
    //   }
    //
    //   console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
    // } catch (err) {
    //   console.error('Failed to check for updates:', err);
    //   this.updateProgress.set(false);
    // }

    // if (this._updates.isEnabled) {
    //   this.updateProgress.set(true);
    //   this._updates
    //     .checkForUpdate()
    //     .then(() => {
    //       this._window.location.reload();
    //     })
    //     .catch(reason => {
    //       console.error(reason);
    //     });
    // } else {
    //   this._window.location.reload();
    // }
  }

  // activateUpdate(): void {
  //   this._updates.activateUpdate().then(() => {
  //     this._window.location.reload();
  //   });
  // }
}
