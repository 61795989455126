import {InjectionToken} from '@angular/core';
import {Capacitor} from '@capacitor/core';

import {
  IAPOffer,
  IAPProduct,
  IAPProductAndIRegisterProduct,
  IapticInAppPurchaseValidationResponse,
  VerifiedPurchaseWithProductId
} from './in-app-purchase.model';
import {IAP_CONFIG, IAPConfig} from './injection-tokens';
import {InAppPurchaseWrapper} from './wrapper/in-app-purchase.wrapper';

/**
 * Convert a CdvPurchase.Product to an IAPProduct
 * @param product
 */
export function transformCdvPurchaseProduct(
  product: CdvPurchase.Product
): IAPProduct {
  return {
    canPurchase: product.canPurchase,
    className: product.className,
    description: product.description,
    features: [],
    group: product.group || product.title,
    id: product.id,
    offers: product.offers.map(stringifyCdvPurchaseOffer),
    owned: product.owned,
    platform: product.platform,
    title: product.title,
    type: product.type
  };
}

/**
 * @param response
 * @param registeredProducts
 */

export function getActiveInAppPurchase(
  response: IapticInAppPurchaseValidationResponse,
  registeredProducts: IAPProductAndIRegisterProduct[]
): IAPProductAndIRegisterProduct[] {
  if (response && response && response.purchases) {
    // Add product id to the purchase
    return <IAPProductAndIRegisterProduct[]>Object.keys(response.purchases)
      .map(verifiedProductId => {
        const [deviceId, productId] = verifiedProductId.split(':');

        return <VerifiedPurchaseWithProductId>{
          deviceId,
          productId,
          ...response.purchases[productId]
        };
      })

      // Only return active purchases
      .filter((p: VerifiedPurchaseWithProductId) => !p.isExpired)
      .map((p: VerifiedPurchaseWithProductId) => {
        const registeredProduct = registeredProducts.find(
          rp => rp.id === p.productId
        );
        if (registeredProduct) {
          return {
            ...registeredProduct,
            owned: true
          };
        }
        return null;
      })
      .filter(p => p !== null);
  }

  return [];
}

export function stringifyCdvPurchaseOffer(offer: CdvPurchase.Offer): IAPOffer {
  return {
    canPurchase: offer.canPurchase,
    id: offer.id,
    platform: offer.platform,
    pricingPhases: offer.pricingPhases,
    productGroup: offer.productGroup,
    productId: offer.productId
  };
}

/**
 *
 * @param _config - IAPConfig
 */
export function createIAPConfigProvider<T>(_config: IAPConfig): {
  provide: InjectionToken<IAPConfig>;
  useValue: IAPConfig;
} {
  const config = {
    ..._config
  };

  if (Capacitor.getPlatform() === 'ios') {
    config.webSubscriptionProducts = {};
    config.subscriptionProducts = config.subscriptionProducts.filter(
      p => p.platform === InAppPurchaseWrapper.Platform.APPLE_APPSTORE
    );
  } else if (Capacitor.getPlatform() === 'android') {
    config.webSubscriptionProducts = {};
    config.subscriptionProducts = config.subscriptionProducts.filter(
      p => p.platform === InAppPurchaseWrapper.Platform.GOOGLE_PLAY
    );
  } else {
    config.subscriptionProducts = [];
  }

  return {
    provide: IAP_CONFIG,
    useValue: config
  };
}
