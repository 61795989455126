import {Type} from '@angular/core';

import {NgPatAccountEffects} from './+account/account.effects';
import {
  ngPatAccountFeatureKey,
  ngPatInitialAccountState
} from './+account/account.model';
import * as fromAccountState from './+account/account.reducer';
import * as fromBookmarks from './+bookmarks/bookmark.reducer';
import * as fromComponentState from './+compontent-to-global/component-to-global';
import {NgPatDeviceEffects} from './+device/device.effects';
import {
  ngPatDeviceFeatureKey,
  ngPatDeviceReducer,
  ngPatInitialDeviceState
} from './+device/device.reducer';
import {
  ngPatIInitialLocalStorageState,
  ngPatLocalStoragesFeatureKey
} from './+local-storage';
import {NgPatLocalStorageEffects} from './+local-storage/local-storage.effects';
import * as fromLocalStorageState from './+local-storage/local-storage.reducer';
import {NoteAddEditEffects} from './+note-add-edit/note-add-edit.effects';
import * as fromNoteAddEdit from './+note-add-edit/note-add-edit.reducer';
import {NoteEffects} from './+notes/note.effects';
import * as fromNotes from './+notes/note.reducer';
import {NotificationEffects} from './+notification/notification.effects';
import * as fromNotification from './+notification/notification.reducer';
import {NgPatInAppPurchaseEffects} from './+pricing/+in_app_purchase/in-app-purchase.effects';
import * as fromAppStore from './+pricing/+in_app_purchase/in-app-purchase.reducer';
import {NgPatInAppPurchaseService} from './+pricing/+in_app_purchase/ng-pat-in-app-purchase.service';
import {
  NG_PAT_WEB_EFFECTS,
  NG_PAT_WEB_SERVICES
} from './+pricing/+stripe/index';
import {NgPatRemoteConfigEffects} from './+remote-config/remote-config.effects';
import * as fromRemoteConfigState from './+remote-config/remote-config.reducer';
import {
  ngPatInitialRemoteConfigState,
  ngPatRemoteConfigFeatureKey
} from './+remote-config/remote-config.reducer';
import {NgPatWebsocketRegistryEffects} from './+websocket-registry/websocket-registry.effects';
import {
  ngPatInitialWebsocketRegistryState,
  ngPatWebsocketRegistryFeatureKey
} from './+websocket-registry/websocket-registry.models';
import * as fromFirebaseConnectionsState from './+websocket-registry/websocket-registry.reducer';

export const NG_PAT_FIREBASE_ROOT_REDUCERS = {
  [fromAppStore.appstoreInAppPurchaseFeatureKey]: fromAppStore.iapReducer,
  [fromBookmarks.bookmarksFeatureKey]: fromBookmarks.reducer,
  [fromComponentState.componentStateFeatureKey]:
    fromComponentState.componentStateReducer,
  [fromNoteAddEdit.nodeAddEditFeatureKey]: fromNoteAddEdit.reducer,
  [fromNotes.noteFeatureKey]: fromNotes.reducer,
  [fromNotification.notificationFeatureKey]:
    fromNotification.notificationReducer,
  [ngPatAccountFeatureKey]: fromAccountState.ngPatAccountReducer,
  // [ngPatDialogsFeatureKey]: ngPatDialogueQueueReducer,
  [ngPatDeviceFeatureKey]: ngPatDeviceReducer,
  [ngPatLocalStoragesFeatureKey]: fromLocalStorageState.localStorageReducer,
  [ngPatRemoteConfigFeatureKey]: fromRemoteConfigState.ngPatRemoteConfigReducer,
  [ngPatWebsocketRegistryFeatureKey]:
    fromFirebaseConnectionsState.ngPatWebSocketReducer
};

export const NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS = {
  [fromAppStore.appstoreInAppPurchaseFeatureKey]:
    fromAppStore.initialAppstoreInAppPurchaseState,
  [fromBookmarks.bookmarksFeatureKey]: fromBookmarks.initialBookmarkState,
  [fromNoteAddEdit.nodeAddEditFeatureKey]:
    fromNoteAddEdit.initialNoteAddEditState,
  [fromNotes.noteFeatureKey]: fromNotes.initialNoteState,
  [fromNotification.notificationFeatureKey]:
    fromNotification.initialNotificationState,
  [ngPatAccountFeatureKey]: ngPatInitialAccountState,
  // [ngPatDialogsFeatureKey]: ngPatInitialDialogState,
  [ngPatDeviceFeatureKey]: ngPatInitialDeviceState,
  [ngPatLocalStoragesFeatureKey]: ngPatIInitialLocalStorageState,
  [ngPatRemoteConfigFeatureKey]: ngPatInitialRemoteConfigState,
  [ngPatWebsocketRegistryFeatureKey]: ngPatInitialWebsocketRegistryState
};

export const NG_PAT_FIREBASE_ROOT_EFFECTS: Type<unknown>[] = [
  NgPatAccountEffects,
  NgPatRemoteConfigEffects,
  NgPatLocalStorageEffects,
  // NgPatDialogQueue,
  NgPatDeviceEffects,
  NotificationEffects,
  NgPatInAppPurchaseEffects,
  NgPatWebsocketRegistryEffects,
  NoteEffects,
  NoteAddEditEffects,
  ...NG_PAT_WEB_EFFECTS
];

export const NG_PAT_FIREBASE_ROOT_PROVIDERS = [
  NgPatInAppPurchaseService,
  ...NG_PAT_WEB_SERVICES
];
