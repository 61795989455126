import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {pipe} from 'rxjs';
import {distinctUntilKeyChanged, filter, map} from 'rxjs/operators';

import {NgPatDeviceState, UiIosNotchCalc} from './device.model';
import * as fromDevice from './device.reducer';

export const selectNgPatDeviceState = createFeatureSelector<NgPatDeviceState>(
  fromDevice.ngPatDeviceFeatureKey
);

export const selectNgPatIsMobile = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState) => {
    return state.isNativePlatform;
  }
);

export const ngPatIsMobile$ = pipe(
  select(selectNgPatDeviceState),
  distinctUntilKeyChanged('isLoaded'),
  filter((state: NgPatDeviceState) => state.isLoaded),
  map((state: NgPatDeviceState) => {
    return state.isNativePlatform;
  })
);

export const selectIsIOSToolbarContent = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState): UiIosNotchCalc => {
    const buttonHeight = 28;
    const paddingTopBottom = 14;

    if (state.ios && state.isPortrait) {
      const targetHeight = 105;

      return {
        contentTopPx: 56 + targetHeight - (buttonHeight + paddingTopBottom * 2),
        paddingTopPx: targetHeight - (buttonHeight + paddingTopBottom * 2),
        toolbarHeightPx: targetHeight
      };
    }

    return {
      contentTopPx: 56,
      paddingTopPx: 0,
      toolbarHeightPx: 56
    };
  }
);

export const selectNgPatIsIOS = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState) => {
    return state.ios;
  }
);

export const selectNgPatNotIOS = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState) => {
    return !state.ios;
  }
);

export const selectNgPatIsAndroid = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState) => {
    return state.android;
  }
);

export const selectNgPatNotAndroid = createSelector(
  selectNgPatDeviceState,
  (state: NgPatDeviceState) => {
    return !state.android;
  }
);
