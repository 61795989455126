import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {ENVIRONMENT} from '@gigasoftware/shared/models';

import {NgPatFirebaseLoginComponent} from '../firebase-login/index';
import {AuthLoginService} from './+login/auth.login.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-login-views'
  },
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    NgPatFirebaseLoginComponent
  ],
  selector: 'dlc-login-views',
  styleUrl: './login-views.component.scss',
  templateUrl: './login-views.component.html'
})
export class LoginViewsComponent implements OnInit {
  showVerticalHeader = true;

  testStates = false;

  constructor(
    private firestore: NgPatFirestoreService,
    public authLoginService: AuthLoginService,
    @Inject(ENVIRONMENT) public environment: any
  ) {
    effect(() => {
      const action = this.authLoginService.action();
      if (action === this.authLoginService.loginStatus.SUCCESS) {
        window.close();
      }
    });
    // console.log('ENV', environment);
  }

  ngOnInit() {
    this.firestore.logEvent('auth_opened');
  }
}
