import {InjectionToken} from '@angular/core';
import {AuthURLs} from '@gigasoftware/shared/api';

export const AUTH_URLS: InjectionToken<AuthURLs> = new InjectionToken(
  'AUTH_URLS'
);

export const devAuthURLs_domain: AuthURLs = {
  appURL: 'https://dev-app-evolving-cognition-com.firebaseapp.com',
  // authURL: 'https://dev.auth.evolving-cognition.com',
  authURL: 'https://evolving-cognition-dev.firebaseapp.com'
};

export const prdAuthURLs_domain: AuthURLs = {
  appURL: 'https://app-evolving-cognition-com.firebaseapp.com',
  // authURL: 'https://auth.evolving-cognition.com',
  authURL: 'https://evolving-cognition-app.firebaseapp.com'
};
