import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  WritableSignal
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatProgressBar} from '@angular/material/progress-bar';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {NgPatNormalizedOffer} from '@gigasoftware/shared/store';

import {APP} from '../../../../../entites';
import {DlcWebCancelSubscription} from '../../dlc-web-pricing/dlc-web-pricing.models';

export interface DlcCancelWebModalData {
  app: APP;
  price: NgPatNormalizedOffer;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDialogModule, MatButton, MatProgressBar],
  selector: 'dlc-cancel-web-subscription-modal',
  styleUrl: './cancel-web-subscription-modal.component.scss',
  templateUrl: './cancel-web-subscription-modal.component.html'
})
export class CancelWebSubscriptionModalComponent {
  error: WritableSignal<boolean> = signal(false);
  showProgress: WritableSignal<boolean> = signal(false);

  constructor(
    public dialogRef: MatDialogRef<CancelWebSubscriptionModalComponent>,
    private customFirestore: NgPatFirestoreService,
    @Inject(MAT_DIALOG_DATA) public data: DlcCancelWebModalData
  ) {}

  async onConfirm() {
    this.showProgress.set(true);

    const cancelStripeSubscription = this.customFirestore.httpsCallable(
      EC_HTTPS_CALLABLE.STRIBE_CANCEL_SUBSCRIPTION
    );

    if (cancelStripeSubscription) {
      cancelStripeSubscription(<DlcWebCancelSubscription>{
        app: this.data.app,
        env: this.customFirestore.env,
        subscriptionId: this.data.price.webPriceSubscriptionId
      })
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          this.error.set(true);
        });
    }
  }
}
