function isDeepKey(key) {
  switch (typeof key) {
    case 'number':
    case 'symbol':
      {
        return false;
      }
    case 'string':
      {
        return key.includes('.') || key.includes('[') || key.includes(']');
      }
  }
}
export { isDeepKey };