import {Classroom} from '@gigasoftware/shared/api';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import * as ClassroomActions from './classroom.actions';

export const classroomFeatureKey = 'classroom';

export interface ClassroomState extends EntityState<Classroom> {
  // additional entities state properties
  selectedClassroomID: string | null;
}

export interface PartialClassroomState {
  readonly [classroomFeatureKey]: ClassroomState;
}

export const classroomAdapter: EntityAdapter<Classroom> =
  createEntityAdapter<Classroom>();

export const initialClassroomState: ClassroomState =
  classroomAdapter.getInitialState({
    // additional entity state properties
    selectedClassroomID: null
  });

export const reducer = createReducer(
  initialClassroomState,
  on(ClassroomActions.addClassroom, (state, action) =>
    classroomAdapter.addOne(action.classroom, state)
  ),
  on(ClassroomActions.setClassroom, (state, action) =>
    classroomAdapter.setOne(action.classroom, state)
  ),
  on(ClassroomActions.addClassrooms, (state, action) =>
    classroomAdapter.addMany(action.classrooms, state)
  ),
  on(ClassroomActions.updateClassroom, (state, action) =>
    classroomAdapter.updateOne(action.classroom, state)
  ),
  on(ClassroomActions.updateClassrooms, (state, action) =>
    classroomAdapter.updateMany(action.classrooms, state)
  ),
  on(ClassroomActions.upsertClassroom, (state, action) =>
    classroomAdapter.upsertOne(action.classroom, state)
  ),
  on(ClassroomActions.upsertClassrooms, (state, action) =>
    classroomAdapter.upsertMany(action.classrooms, state)
  ),
  on(ClassroomActions.mapClassroom, (state, {entityMap}) => {
    return classroomAdapter.mapOne(entityMap, state);
  }),
  on(ClassroomActions.mapClassrooms, (state, {entityMap}) => {
    return classroomAdapter.map(entityMap, state);
  }),
  on(ClassroomActions.deleteClassroom, (state, action) =>
    classroomAdapter.removeOne(action.id, state)
  ),
  on(ClassroomActions.deleteClassrooms, (state, action) =>
    classroomAdapter.removeMany(action.ids, state)
  ),
  on(ClassroomActions.loadClassrooms, (state, action) =>
    classroomAdapter.setAll(action.classrooms, state)
  ),
  on(ClassroomActions.setClassrooms, (state, action) =>
    classroomAdapter.setMany(action.classrooms, state)
  ),
  on(ClassroomActions.clearClassrooms, state =>
    classroomAdapter.removeAll(state)
  ),
  on(ngPatLogout, state => ({
    ...initialClassroomState,
    ...classroomAdapter.removeAll(state)
  })),
  on(ClassroomActions.selectClassroomID, (state, action) => {
    return {
      ...state,
      selectedClassroomID: action.id
    };
  })
);
