import {createReducer, on} from '@ngrx/store';

import * as DeviceActions from './device.actions';
import {NgPatDeviceState} from './device.model';

export const ngPatDeviceFeatureKey = 'ngPat_Device';

export const ngPatInitialDeviceState: NgPatDeviceState = {
  android: false,
  handset: false,
  ios: false,
  isLandscape: false,
  isLargeScreen: false,

  isLoaded: false,
  isNativePlatform: false,
  isPortrait: false,
  large: false,
  medium: false,
  small: false,
  tablet: false,
  web: true,
  xLarge: false,

  xSmall: false
};

export const ngPatDeviceReducer = createReducer(
  ngPatInitialDeviceState,

  on(DeviceActions.ngPatLoadDevices, (state, action): NgPatDeviceState => {
    return {
      ...state,
      ...action.device,
      isLoaded: true
    };
  })
);
