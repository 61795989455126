import {GigaAssetState} from './firestore';

export enum GigaGCFunctionActionType {
  /**
   * Transcription is complete, create quiz
   */
  CREATE_QUIZ_QUESTIONS_FROM_NOTE_IMAGE = 'CREATE_QUIZ_QUESTIONS_FROM_NOTE_IMAGE',
  /**
   * No transcription yet,
   * First create transcription,
   * then create quiz
   */
  TRANSCRIBE_IMAGE_CREATE_QUIZ_QUESTIONS = 'TRANSCRIBE_IMAGE_CREATE_QUIZ_QUESTIONS',

  /**
   * Quiz is created and has image,
   * No transcription yet,
   * First create transcription,
   * Then create quiz questions
   */
  TRANSCRIBE_QUIZ_IMAGE_CREATE_QUIZ_QUESTIONS = 'TRANSCRIBE_QUIZ_IMAGE_CREATE_QUIZ_QUESTIONS'
}

export interface GigaGCFunctionAction<T> {
  firestoreActionDocPath: string;
  props: T;
  type: GigaGCFunctionActionType;
}

export interface GigaGCFunctionActionPayload {
  // Path to this document in Firestore
  firestorePath: string;
  id: string;
  // Personal or uuid of Study Group or Classroom
  parentId: string;
}

export interface GigaGCFunctionActionCreateQuizQuestionsFromImagePayload
  extends GigaGCFunctionActionPayload {
  // Path to the image in Firebase Storage
  imagePath: string;
  imageState: GigaAssetState;
  title: string;
}

export const ofFirestoreActionType = <T>(
  compare: GigaGCFunctionActionType,
  action: GigaGCFunctionAction<T>
) => {
  return action.type === compare;
};

export type FirestorePathCreatorFn = (firestoreActionDocId: string) => string;
export type FirestoreActionFn<T> = (
  firestoreActionDocId: string,
  props: T
) => GigaGCFunctionAction<T>;

export const createFirestoreAction = <T>(
  type: GigaGCFunctionActionType,
  firestorePathCreatorFn: FirestorePathCreatorFn
): FirestoreActionFn<T> => {
  return (firestoreActionDocId: string, props: T): GigaGCFunctionAction<T> => {
    return {
      firestoreActionDocPath: firestorePathCreatorFn(firestoreActionDocId),
      props,
      type
    };
  };
};
