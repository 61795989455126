import {Injectable} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {ReferenceLink} from '@gigasoftware/shared/api';

import {AbstractFormArray} from '../../core/utils/abstract-form-array';
import {urlNeedsValue} from './dlc-input-link.fns';

@Injectable()
export class ReferenceLinksService extends AbstractFormArray<ReferenceLink> {
  constructor(protected override fb: FormBuilder) {
    super(fb);
  }

  addLinks(links: ReferenceLink[]) {
    links.forEach((link: ReferenceLink) => this.addLink(link));
  }

  setLinks(links: ReferenceLink[]) {
    this.clear();
    this.addLinks(links);
  }

  addLink(ref: ReferenceLink | null) {
    const {label, url} = ref || {label: '', url: ''};
    this.array.push(
      this.fb.group(
        {
          label: new FormControl(label),
          url: new FormControl(url)
        },
        {validators: [urlNeedsValue]}
      )
    );
  }

  // undoReferenceLink(linkIndex: number, question: Question) {
  //   const linkForm: AbstractControl = this.array.at(linkIndex);
  //
  //   if (getReferenceLinksFromQuestion(question)[linkIndex]) {
  //     linkForm.reset({url: getReferenceLinksFromQuestion(question)[linkIndex]});
  //   }
  // }

  value() {
    return this.array.value;
  }

  values() {
    return this.array.value;
  }
}
