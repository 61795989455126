import {inject, Injectable} from '@angular/core';
import {
  CreateQuizAndQuestionsWithAIParams,
  CreateQuizAndQuestionsWithAIParamsUid,
  createQuizBasedOnParams,
  createQuizEntity,
  CreateQuizParamsV2,
  CreateQuizParamsV2Uid,
  createSubQuizBasedOnParams,
  CreateSubQuizParams,
  Exists,
  Quiz
} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {selectNgPatLoggedInUID} from '../+account/account.selectors';
import {addQuestionsToQuizWithAi$} from './quiz.fns';

@Injectable({
  providedIn: 'root'
})
export class QuizCreatorService {
  private store = inject(Store);
  private customFirestoreService: NgPatFirestoreService = inject(
    NgPatFirestoreService
  );

  createQuizWithParamsV2$ = (params: CreateQuizParamsV2): Observable<Quiz> => {
    return of(params).pipe(
      concatLatestFrom(() => this.store.select(selectNgPatLoggedInUID)),
      map(([params, uid]) => {
        return {
          ...params,
          uid
        };
      }),
      switchMap((config): Observable<Quiz> => {
        if (config.uid) {
          const entity = createQuizEntity(config as CreateQuizParamsV2Uid);

          return this.customFirestoreService
            .upsertDoc$<Quiz>(entity.firestoreDocumentPath, entity)
            .pipe(map((exists: Exists<Quiz>) => exists.data));
        }

        return EMPTY;
      })
    );
  };

  createQuizAndQuestionsWithParamsV2$ = (
    config: CreateQuizAndQuestionsWithAIParams
  ) => {
    return of(config).pipe(
      concatLatestFrom(() => this.store.select(selectNgPatLoggedInUID)),
      map(([config, uid]) => ({
        ...config,
        uid
      })),
      switchMap(
        ({
          uid
        }: {
          aiPrompt: string;
          params: CreateQuizParamsV2;
          uid: string | null;
        }): Observable<Quiz> => {
          if (uid) {
            const entity = createQuizEntity(config.params);

            return this.customFirestoreService
              .upsertDoc$<Quiz>(entity.firestoreDocumentPath, entity)
              .pipe(
                map((exists: Exists<Quiz>) => exists.data),
                addQuestionsToQuizWithAi$(
                  uid,
                  config.aiPrompt,
                  this.customFirestoreService
                )
              );
          }

          return EMPTY;
        }
      )
    );
  };
}
