import {GigaNoteDoc} from '@gigasoftware/shared/api';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {Store} from '@ngrx/store';

import {NgPatAccountState} from '../+account/account.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {NoteQueryStoreEntity} from './note-query.store.model';
import {deleteNotes, updateNotes, upsertNotes} from './note.actions';

export class NoteQueryEngine implements NgPatFirebaseConnectionService {
  private _noteFirestoreQuery!: NgPatFirestoreCollectionQuery<GigaNoteDoc>;

  connection!: NgPatServiceConnector;

  /**
   * Connect to firestore
   */
  connectionKey = this.noteQueryStoreEntity.firestoreCollectionPath;
  id = this.noteQueryStoreEntity.id;

  constructor(
    private noteQueryStoreEntity: NoteQueryStoreEntity,
    private store: Store,
    private firestore: NgPatFirestoreService
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  ngPatOnInit() {
    this._noteFirestoreQuery = new NgPatFirestoreCollectionQuery(
      {
        deleteManyAction: (ids: string[]) => deleteNotes({ids}),
        queryMember: false,
        updateManyAction: (notes: GigaNoteDoc[]) =>
          updateNotes({notes: aggregateUpdates(notes)}),
        upsertManyAction: (notes: GigaNoteDoc[]) => upsertNotes({notes})
      },
      this.store,
      this.firestore
    );
  }

  onConnect(user: NgPatAccountState) {
    this._noteFirestoreQuery.onConnect(
      this.noteQueryStoreEntity.firestoreCollectionPath
    );
  }

  onDisconnect(user: NgPatAccountState) {
    if (this._noteFirestoreQuery) {
      this._noteFirestoreQuery.onDisconnect();
    }
  }
}
