import {Injectable} from '@angular/core';
import {generate8CharCodeLowercase} from '@gigasoftware/shared/api';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, forkJoin} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {
  updateFirestorePartialResearch,
  updateResearchEffect,
  upsertResearchs
} from './research.actions';
import {Research} from './research.model';
import {PartialResearchState} from './research.reducer';
import {ResearchService} from './research.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchEffects {
  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _researchService
   */
  // deleteDoc$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(deleteResearchFromfirestore),
  //     withLatestFrom(this.store.select(selectUserAccount)),
  //     switchMap(([action, account]) =>
  //       this.store.pipe(
  //         select(getClassroomByID(action.id)),
  //         switchMap((classRoom: Classroom | undefined) =>
  //           this._researchService
  //             .deleteDoc$(classRoom, <string>account.uid)
  //             .pipe(map(() => deleteResearch({id: action.id})))
  //         )
  //       )
  //     )
  //   )
  // );

  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _researchService
   */
  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteResearchs),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getClassroomByID(action.ids[0])),
  //           switchMap((classRoom: Classroom | undefined) =>
  //             this._researchService.deleteDocs$(classRoom, action.ids, <string>account.uid)
  //           )
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialResearch),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._researchService.updatePartialFirestore$(
            action.changes,
            action.research,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  updateResearch$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateResearchEffect),
        switchMap(action => {
          return this._researchService.updateDoc(action.research);
        })
      ),
    {dispatch: false}
  );

  updateResearchEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateResearchEffect),
        switchMap(action => {
          return this._researchService.updateDoc(action.research);
        })
      ),
    {dispatch: false}
  );

  upsertResearchs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(upsertResearchs),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          interface ResearchUpdate {
            changes: Partial<Research>;
            research: Research;
          }

          const _researchsUpdate: ResearchUpdate[] = action.researchs.reduce(
            (a: ResearchUpdate[], s: Research) => {
              const changes: Partial<Research> = {};

              let update = false;

              if (!s.mentorCode) {
                changes.mentorCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.teacherCode) {
                changes.teacherCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.studentCode) {
                changes.studentCode = generate8CharCodeLowercase();
                update = true;
              }

              if (update) {
                a.push({
                  changes,
                  research: {...s}
                });
              }

              return a;
            },
            <ResearchUpdate[]>[]
          );

          if (_researchsUpdate.length) {
            return forkJoin(
              _researchsUpdate.map((s: ResearchUpdate) =>
                this._researchService.updatePartialFirestore$(
                  s.changes,
                  s.research,
                  account.uid
                )
              )
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialResearchState>,
    private _researchService: ResearchService
  ) {}
}
