import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {Bookmark} from '@gigasoftware/shared/api';

import {DlcBookmarkService} from '../dlc-create-bookmark/dlc-bookmark.service';
import {DlcBookmarkComponent} from './dlc-bookmark/dlc-bookmark.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-bookmark-list'
  },
  imports: [CommonModule, DlcBookmarkComponent],
  selector: 'dlc-bookmark-list',
  styleUrl: './dlc-bookmark-list.component.scss',
  templateUrl: './dlc-bookmark-list.component.html'
})
export class DlcBookmarkListComponent {
  bookmarkService = inject(DlcBookmarkService);

  doEditBookmark: OutputEmitterRef<Bookmark> = output();
}
