import {InjectionToken} from '@angular/core';

export const NAV_ITEM_DICT = new InjectionToken('NAV_ITEMS');
export const SUBSCRIBE_NAV_ITEM = new InjectionToken('SUBSCRIBE_NAV_ITEM');
export const DEFAULT_NAV_ITEM_ID = '0';

export interface NavItem {
  icon: string;
  id: string;
  includeLogoForParent: boolean;
  includeTopNavForParent: boolean;
  name: string;
  navigateTo: string[];
  paramKeys: string[];
  previousNavItemID: string;
  url: string;
}
