import {BaseEntityType, hasDuplicatesByKey} from '@gigasoftware/shared/api';
import {map} from 'rxjs/operators';

export const checkForDuplicateEntityTypes = map((d: BaseEntityType[]) => {
  if (hasDuplicatesByKey(d, 'id')) {
    const errorMsg =
      'Platform Configuration Error: Duplicate BaseEntityType Value detected.';
    console.error(errorMsg);
    return new Error(errorMsg);
  } else if (hasDuplicatesByKey(d, 'name')) {
    const errorMsg =
      'Platform Configuration Error: Duplicate BaseEntityType Name detected.';
    console.error(errorMsg);
    return new Error(errorMsg);
  } else {
    return d;
  }
});
