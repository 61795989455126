import {Injectable} from '@angular/core';
import {
  DlcSaveBtnState,
  GigaAssetState,
  GigaNoteDoc,
  GigaNoteTranscribeNoteVersionUpdate,
  RecursivePartial
} from '@gigasoftware/shared/api';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {clone, cloneMerge} from '@gigasoftware/shared/fn';
import {NgPatProcessQueueState} from '@gigasoftware/shared/utils';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {
  concatMap,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

import {
  _addFirestoreDoc,
  _updateNoteTitle,
  _updateTranscribedNote,
  _updateUserNote,
  addFirestoreDoc,
  addTranscribedNoteVersion,
  addUserNoteVersion,
  clearAddEditNoteStore,
  doTranscribeImage,
  patchFirestoreDoc,
  startNoteProcessQueue,
  updateFirestoreDocPromise,
  updateNoteTitle,
  updateProcessQueueBtnState
} from './note-add-edit.actions';
import {
  addProcessQueueIdNoteDoc,
  addProcessQueueIdNoteTitle,
  addProcessQueueIdNoteVersion,
  addProcessQueueIdTranscriptionVersion
} from './note-add-edit.fns';
import {
  noteAddEditState,
  selectNoteAddEditFirestoreDoc,
  selectNoteAddEditFirestorePath
} from './note-add-edit.selectors';
import {DlcNoteProcessQueue, DlcNoteSaveAction} from './note.model';
import {DlcNoteFirestoreService} from './services/dlc-note-firestore.service';
import {DlcTranscribeNoteService} from './services/dlc-note-transcribed.service';
import {DlcNoteUserService} from './services/dlc-note-user.service';
import {
  ofDlcNoteActionTypeWithFirestoreDoc,
  ofDlcNoteActionTypeWithTranscription
} from './services/note.fns';
import {SaveAddEditNoteService} from './services/save-add-edit-note.service';

@Injectable()
export class NoteAddEditEffects {
  // saveNote$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(saveNote),
  //       tap(({queue}) => {
  //         this.noteStore.save(queue);
  //       })
  //     ),
  //   {
  //     dispatch: false
  //   }
  // );

  startNoteProcessQueue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNoteProcessQueue),
        tap(async () => {
          // console.log('startNoteProcessQueue');
          await this.noteStore.saveDataProcessQueue.next();
        })
      ),
    {dispatch: false}
  );

  saveFirestoreDoc$ = createEffect(
    () =>
      this.noteStore.saveDataProcessQueue.currentItem$.pipe(
        // tap((action: DlcNoteProcessQueue) => {
        //   console.log(action);
        // }),
        ofDlcNoteActionTypeWithFirestoreDoc(
          DlcNoteSaveAction.SAVE_FIRESTORE_DOC
        ),
        concatLatestFrom(() =>
          this.store.select(selectNoteAddEditFirestoreDoc)
        ),
        tap(
          async ([action, doc]: [DlcNoteProcessQueue, GigaNoteDoc | null]) => {
            // console.log('currentItem$ dlc-note-firestore', action);
            const _doc = doc
              ? {...doc, ...action.firestoreDoc}
              : action.firestoreDoc;
            // console.log(action);
            // await this.noteStore.updateFirestoreDocPromise(_doc);
            await this.firestore.merge(<string>_doc?.firestorePath, _doc);
            await this.noteStore.saveDataProcessQueue.next(action.id);
          }
        )
      ),
    {dispatch: false}
  );

  saveTranscription4 = createEffect(
    () =>
      this.noteStore.saveDataProcessQueue.currentItem$.pipe(
        ofDlcNoteActionTypeWithTranscription(
          DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION
        ),
        tap(async (action: DlcNoteProcessQueue) => {
          // console.log('currentItem$ dlc-note-transcribed', action);
          if (action.transcriptionNoteVersion) {
            await this.noteStore.addTranscribedVersion(
              action.transcriptionNoteVersion
            );
            await this.noteStore.saveDataProcessQueue.next(action.id);
          }
        })
      ),
    {dispatch: false}
  );

  saveUserNote$ = createEffect(
    () =>
      this.noteStore.saveDataProcessQueue.currentItem$.pipe(
        ofDlcNoteActionTypeWithFirestoreDoc(
          DlcNoteSaveAction.SAVE_USER_NOTE_VERSION
        ),
        concatLatestFrom(() =>
          this.store.select(selectNoteAddEditFirestoreDoc)
        ),
        tap(
          async ([action, doc]: [DlcNoteProcessQueue, GigaNoteDoc | null]) => {
            // console.log('currentItem$ dlc-note-user', action);

            const _doc = doc
              ? {...doc, ...action.firestoreDoc}
              : action.firestoreDoc;

            const payload = <GigaNoteTranscribeNoteVersionUpdate>{
              doc: _doc,
              text: action.userNoteVersion
            };

            const result = await this.userNote.addUserNoteVersion(payload);

            await this.noteStore.saveDataProcessQueue.next(action.id);
          }
        )
      ),
    {dispatch: false}
  );

  saveTitle$ = createEffect(
    () =>
      this.noteStore.saveDataProcessQueue.currentItem$.pipe(
        ofDlcNoteActionTypeWithFirestoreDoc(DlcNoteSaveAction.SAVE_NOTE_TITLE),
        concatLatestFrom(() =>
          this.store.select(selectNoteAddEditFirestorePath)
        ),
        tap(
          async ([action, firestorePath]: [
            DlcNoteProcessQueue,
            string | null
          ]) => {
            await this.firestore.merge(
              firestorePath as string,
              action.firestoreDoc
            );
            await this.noteStore.saveDataProcessQueue.next(action.id);
          }
        )
      ),
    {dispatch: false}
  );

  dlcSaveBtnState$ = createEffect(() => {
    return this.noteStore.saveDataProcessQueue.processingState$.pipe(
      distinctUntilChanged(),
      map((state: NgPatProcessQueueState) => {
        switch (state) {
          case NgPatProcessQueueState.IDLE:
            return DlcSaveBtnState.DISABLED;
          case NgPatProcessQueueState.PROCESSING:
            return DlcSaveBtnState.IN_PROGRESS;
          case NgPatProcessQueueState.PENDING:
            return DlcSaveBtnState.ACTIVE;
          default:
            return DlcSaveBtnState.DISABLED;
        }
      }),
      map((processQueueBtnState: DlcSaveBtnState) => {
        return updateProcessQueueBtnState({processQueueBtnState});
      })
    );
  });

  updateNoteTitle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateNoteTitle),
      concatLatestFrom(() => this.store.select(noteAddEditState)),
      concatMap(([{title}, state]) => {
        const isDirty = state.isDirty || title !== state.firestoreDoc?.title;

        this.noteStore.saveDataProcessQueue.upsertOne(
          addProcessQueueIdNoteTitle({
            action: DlcNoteSaveAction.SAVE_NOTE_TITLE,
            firestoreDoc: {
              title
            },
            id: 0 // overridden by id function
          })
        );

        this.noteStore.autoSaveTimer$.next(true);

        return [
          _updateNoteTitle({
            isDirty,
            saveBtnState: isDirty
              ? DlcSaveBtnState.ACTIVE
              : DlcSaveBtnState.DISABLED
          })
        ];
      })
    );
  });

  addFirestoreDoc$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addFirestoreDoc),
      concatMap(({doc}) => {
        // console.log('addFirestoreDoc', doc);
        this.noteStore.saveDataProcessQueue.upsertOne(
          addProcessQueueIdNoteDoc({
            action: DlcNoteSaveAction.SAVE_FIRESTORE_DOC,
            firestoreDoc: clone(doc),
            id: 0 // overridden by id function
          })
        );

        this.noteStore.autoSaveTimer$.next(true);

        return [
          _addFirestoreDoc({
            doc,
            isDirty: true,
            saveBtnState: DlcSaveBtnState.ACTIVE
          })
        ];
      })
    );
  });

  addUserNoteVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addUserNoteVersion),
      concatLatestFrom(() => this.store.select(noteAddEditState)),
      concatMap(([{newNote}, state]) => {
        const isDirty = state.isDirty || newNote !== state.userNote;

        this.noteStore.saveDataProcessQueue.upsertOne(
          addProcessQueueIdNoteVersion({
            action: DlcNoteSaveAction.SAVE_USER_NOTE_VERSION,
            firestoreDoc: <GigaNoteDoc>state.firestoreDoc,
            id: 0, // overridden by id function
            userNoteVersion: newNote
          })
        );

        this.noteStore.autoSaveTimer$.next(true);

        return [
          _updateUserNote({
            isDirty,
            saveBtnState: isDirty
              ? DlcSaveBtnState.ACTIVE
              : DlcSaveBtnState.DISABLED,
            userNote: newNote
          })
        ];
      })
    );
  });

  addTranscribedNoteVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addTranscribedNoteVersion),
      concatLatestFrom(() => this.store.select(noteAddEditState)),
      concatMap(([{newNote}, state]) => {
        const isDirty = state.isDirty || newNote !== state.userNote;

        this.noteStore.saveDataProcessQueue.upsertOne(
          addProcessQueueIdTranscriptionVersion({
            action: DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION,
            firestoreDoc: <GigaNoteDoc>state.firestoreDoc,
            id: 0, // overridden by id function
            transcriptionNoteVersion: newNote
          })
        );

        this.noteStore.autoSaveTimer$.next(true);

        return [
          _updateTranscribedNote({
            isDirty,
            saveBtnState: isDirty
              ? DlcSaveBtnState.ACTIVE
              : DlcSaveBtnState.DISABLED,
            transcribedNote: newNote
          })
        ];
      })
    );
  });

  updateFirestoreDocPromise$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFirestoreDocPromise),
      concatLatestFrom(() => this.store.select(selectNoteAddEditFirestoreDoc)),
      switchMap(
        ([{update}, storeDoc]: [
          {update: RecursivePartial<GigaNoteDoc>},
          GigaNoteDoc | null
        ]) => {
          const payload: GigaNoteDoc = cloneMerge(storeDoc, update);

          return this.firestore
            .merge$<GigaNoteDoc>(payload.firestorePath, payload)
            .pipe(map(() => patchFirestoreDoc({patch: payload})));
        }
      )
    );
  });

  doTranscribeImage = createEffect(() => {
    return this.actions$.pipe(
      ofType(doTranscribeImage),
      concatLatestFrom(() => this.store.select(selectNoteAddEditFirestoreDoc)),

      concatMap(
        async ([partialDoc, doc]: [
          RecursivePartial<GigaNoteDoc>,
          GigaNoteDoc | null
        ]) => {
          // console.log('transcribe image');
          // const firebaseFunction = this.firestore.httpsCallable(this.noteFirestore.httpsCallableFnNameTranscribeImage);
          const firebaseFunction = this.firestore.httpsCallable(
            EC_HTTPS_CALLABLE.TRANSCRIBE_IMAGE
          );

          if (doc) {
            await firebaseFunction({
              ...doc,
              imageState: GigaAssetState.UPLOADED
            });
          }

          return patchFirestoreDoc({
            patch: partialDoc
          });
        }
      )
    );
  });

  clearAddEditNoteStore$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(clearAddEditNoteStore),
        tap(() => {
          this.transcribedNoteService.onDestroy();
          this.userNote.onDestroy();
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private firestore: NgPatFirestoreService,
    private store: Store,
    private actions$: Actions,
    private noteStore: SaveAddEditNoteService,
    private noteFirestore: DlcNoteFirestoreService,
    private transcribedNoteService: DlcTranscribeNoteService,
    private userNote: DlcNoteUserService
  ) {}
}
