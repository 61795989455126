import {Classroom} from '@gigasoftware/shared/api';
import {AddedAndDeletedMemoizedSelector} from '@gigasoftware/shared/models';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {selectNgPatAddedAndDeletedEntities} from '../selectors';
import * as ClassroomReducer from './classroom.reducer';

export const selectClassroomState =
  createFeatureSelector<ClassroomReducer.ClassroomState>(
    ClassroomReducer.classroomFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  ClassroomReducer.classroomAdapter.getSelectors();

export const selectAllClassrooms = createSelector(
  selectClassroomState,
  selectAll
);
export const selectClassroomEntities = createSelector(
  selectClassroomState,
  selectEntities
);
export const selectClassroomIds = createSelector(
  selectClassroomState,
  selectIds
);
export const selectClassroomTotal = createSelector(
  selectClassroomState,
  selectTotal
);
export const selectedClassroomID = createSelector(
  selectClassroomState,
  (state: ClassroomReducer.ClassroomState) => state.selectedClassroomID
);

export const selectClassroomMemberMap = createSelector(
  selectAllClassrooms,
  convertCollaborativeEntityToMemberUIDEntityID
);

export const getClassroomByID = (id: string | null | undefined) =>
  createSelector(selectClassroomEntities, (entities: Dictionary<Classroom>) => {
    if (id) {
      return entities[<string>id];
    }
    return undefined;
  });

export const selectAddedAndDeletedClassrooms: AddedAndDeletedMemoizedSelector<Classroom> =
  selectNgPatAddedAndDeletedEntities<Classroom>(selectClassroomEntities);
