import {ReferenceLink} from '../dlc-input-link.model';
import {
  BaseEntity,
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
} from '../entity.model';
import {GigaNoteDoc} from '../firestore';
import {GsImageThumbails} from '../media/sizes';

export interface TakeQuizAggregateMultiChoiceAnswers {
  aggregateFalseAnswers: boolean;
  totalAnswers: number;
}

export enum QUIZ_STATE {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  COPYING_IMAGES = 'copying_images',
  COPYING_QUESTIONS = 'copying_questions',
  CREATING = 'creating',
  DRAFT = 'draft',
  GENERATING_QUESTIONS_WITH_AI = 'generating_questions_with_ai',
  PUBLISHED = 'published',
  TRANSCRIBING_IMAGE = 'transcribing_image'
}

/**
 * Used for Quiz state and quiz action state
 * @param state
 */
export const getQuizStateText = (state: QUIZ_STATE): string => {
  switch (state) {
    case QUIZ_STATE.ACTIVE:
      return 'Active';
    case QUIZ_STATE.ARCHIVED:
      return 'Archived';
    case QUIZ_STATE.CREATING:
      return 'Creating Quiz';
    case QUIZ_STATE.COPYING_IMAGES:
      return 'Copying images to quiz';
    case QUIZ_STATE.COPYING_QUESTIONS:
      return 'Copying Questions';
    case QUIZ_STATE.DRAFT:
      return 'Draft';
    case QUIZ_STATE.PUBLISHED:
      return 'Published';
    case QUIZ_STATE.GENERATING_QUESTIONS_WITH_AI:
      return 'Generating Questions';
    case QUIZ_STATE.TRANSCRIBING_IMAGE:
      return 'Transcribing Image';
  }
};

export interface Quiz extends CollaborativeEntity {
  aggregateFalseAnswers: boolean;
  assigned: boolean;
  // description of quiz
  description: string | null;
  // grade level of quiz - 1st grade, 2nd grade, etc
  // formatted as number - 1, 2, 3, etc
  gradeLevel: number | null;
  id: string;
  imagePath: undefined | null | string;

  isPublished: boolean;
  // name of quiz
  name: string;
  publisherUID?: string;
  state: QUIZ_STATE;
  //Math, Chemistry, English, Music, etc
  subjects: string | null;
  // deprecated
  totalAnswers: number;
  totalQuestions: number;
  // createdAt formatted in unix timestamp for algolia
  unixTimestamp: number | null;
}

export interface GradeLevel {
  level: number;
  name: string;
}

/**
 * Copy to Algolioa firestore config
 * id,name,description,imagePath,gradeLevel,subject,totalQuestions,unixTimestamp
 */
export type AlgoliaPublishedQuizProps = Pick<
  Quiz,
  | 'id'
  | 'name'
  | 'description'
  | 'imagePath'
  | 'gradeLevel'
  | 'subjects'
  | 'totalQuestions'
  | 'unixTimestamp'
>;

export interface AlgoliaMatchWords {
  matchedWords: string[];
  matchLevel: 'none' | 'partial' | 'full';
  value: string;
}

export interface AlgoliaPublishedQuiz extends AlgoliaPublishedQuizProps {
  _highlightResult: {
    id: AlgoliaMatchWords;
    lastmodified: AlgoliaMatchWords;
    name: AlgoliaMatchWords;
    path: AlgoliaMatchWords;
    description: AlgoliaMatchWords;
    gradeLevel: AlgoliaMatchWords;
    subject: AlgoliaMatchWords;
  };
  lastmodified: number;
  objectID: string;
  path: string;
  publisherUID: string;
}

export interface AlgoliaPublishedQuizIsMyQuiz extends AlgoliaPublishedQuiz {
  canDeletePublishedQuiz: boolean;
}

export interface CreateQuizParams {
  aggregateFalseAnswers: boolean;
  assigned?: boolean;
  description: string;
  entityType: number;
  entityTypeValue: number;
  gradeLevel: number | null;
  id: string;
  imagePath: undefined | null | string;
  isCollaborative: boolean;
  isPrivate: boolean;
  name: string;
  subjects: string | null;
}

export interface CreateSubQuizParams extends CreateQuizParams {
  parentEntity: BaseEntity;
}

export interface CreateQuizParamsV2 extends CreateQuizParams {
  parentEntity?: BaseEntity;
}

export interface CreateQuizParamsV2Uid extends CreateQuizParamsV2 {
  uid: string;
}

export interface CreateQuizAndQuestionsWithAIParams {
  aiPrompt: string;
  gigaNoteDoc: GigaNoteDoc;
  // ec/images/f6dfda4e-04fd-4502-827a-27d84862197e/f6dfda4e-04fd-4502-827a-27d84862197e.png
  noteImagePath: string;
  notificationDocPath: string;
  params: CreateQuizParamsV2Uid;
  quizState: QUIZ_STATE;
}

export interface CreateQuizAndQuestionsWithAIParamsUid
  extends CreateQuizAndQuestionsWithAIParams {
  uid: string;
}

export const DEFAULT_QUIZ_PARAMS: CreateQuizParams = {
  aggregateFalseAnswers: true,
  assigned: false,
  description: '',
  entityType: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
  entityTypeValue: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
  gradeLevel: null,
  id: '',
  imagePath: null,
  isCollaborative: false,
  isPrivate: true,
  name: '',
  subjects: null
};

export type QuizWithComputedProperties = Quiz & GsImageThumbails;

export interface QuestionType {
  MULTIPLE_CHOICE: number;
  TRUE_FALSE: number;
}

export const QUESTION_TYPE: QuestionType = {
  MULTIPLE_CHOICE: 1,
  TRUE_FALSE: 0
};

export interface Answer {
  answerText: string;
  id: string;
  imagePath: undefined | null | string;
  isCorrect: boolean;

  /**
   * Used only for True / False answer.
   */
  trueFalseAnswer: boolean;
}

export interface UserAnswer {
  answer: Answer;
  multipleChoiceChecked: boolean;
  /**
   * Used only for True / False answer.
   */
  trueFalseAnswer: boolean;
}

export interface Question {
  answers: {[answerID: string]: Answer};
  id: string;
  imagePath: undefined | null | string;
  placeholder: string;
  question: string;
  questionType: number;

  quizID: string;
  // html url to an external reference source
  /**
   * @deprecated
   */
  referenceLink?: ReferenceLink | string;

  referenceLinks: ReferenceLink[] | string[];
}

export type QuestionWithComputedProperties = Question & GsImageThumbails;

export interface QuestionAction {
  question: Question;
  quiz: Quiz;
}

export interface QuestionPropertyAction extends QuestionAction {
  update: any;
}

export interface QuestionWithPath {
  path: string;
  question: Question;
}

/**
 * Taking a quiz
 */

export interface QuestionWithAnswer {
  /**
   * The answer from the user
   */
  answers: {
    [answerID: string]: UserAnswer;
  } | null;
  isAnswered: boolean;

  isCorrect: boolean | null;
  /**
   * The original question
   */
  question: Question;
  questionID: string;
  timeToAnswerMS: number;
}

export interface QuestionWithComputedPropertiesWithAnswer
  extends QuestionWithAnswer {
  question: QuestionWithComputedProperties;
}

/**
 * Only used to display quiz result
 */
export interface ResultAnswer extends QuestionWithAnswer {
  questionText: string;
}

export interface TakeQuizResult {
  createdByUID: string;
  id: string;
  isPrivate: boolean;

  /**
   * User answered questions
   */
  questions: {[questionID: string]: QuestionWithAnswer};
  quiz: Quiz;
  quizID: string;
  timestamp: number;
}

export interface QuizGrade<T> {
  // BulletChartData
  chartData: {
    max: number;
    progress: number;
    min: number;
    /* units suffix such as Kb, Db, MHz */
    units: string;
    status: 'error' | 'warn' | 'success' | null;
  };
  /**
   * Grade and progress are the same
   * Grade is for user experience in quiz item page
   * Progress is for chart data
   */
  grade: number;
  id: string; // quiz id
  name: string; // quiz name
  rawData: T;
  result: TakeQuizResult;
  timestamp: number;

  totalCorrect: number;

  /**
   * Total time to take quiz in milliseconds
   */
  totalTimeToTakeQuizMS: number;

  totalWrong: number;
}

export interface HighestGradeTimeToTakeQuiz {
  highestGrade: number;
  totalTimeToTakeQuizMS: number;
}

export interface CreateReviewQuizFromWrongAnswers {
  questions: Question[];
  quiz: Quiz;
}

export interface CopyQuizParams {
  copyToParentEntities: BaseEntity | null;
  entityTypeName: string;
  isPersonal: boolean;
  name: string;
  uid: string;
}
