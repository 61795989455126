import {inject, Injectable} from '@angular/core';
import {
  firestoreTranscribedNoteVersionDoc,
  GigaAssetState,
  GigaActionState,
  GigaUserNoteVersion
} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {jsonPopulated} from '@gigasoftware/shared/rxjs';
import {Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';

import {
  doTranscribeImage,
  updateLatestTranscriptionVersion
} from '../note-add-edit.actions';
import {selectNoteAddEditTranscribedTextId} from '../note-add-edit.selectors';
import {
  DlcNoteTranscribedTextId,
  DlcNoteTranscribedTextIdNull
} from '../note.model';
import {SaveAddEditNoteService} from './save-add-edit-note.service';

@Injectable({
  providedIn: 'root'
})
export class DlcTranscribeNoteService {
  private store: Store = inject(Store);
  private noteStore: SaveAddEditNoteService = inject(SaveAddEditNoteService);
  private firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  constructor() {
    const that = this;

    // GET LATEST VERSION OF TRANSCRIBED NOTE
    this.store
      .select(selectNoteAddEditTranscribedTextId)
      .pipe(
        filter(
          (doc: DlcNoteTranscribedTextIdNull) =>
            (doc &&
              doc.firestorePath &&
              doc.transcribedTextId.length > 0) as boolean
        ),
        map((doc: DlcNoteTranscribedTextIdNull) => {
          return doc as DlcNoteTranscribedTextId;
        }),
        map((doc: DlcNoteTranscribedTextId) =>
          firestoreTranscribedNoteVersionDoc(
            doc.firestorePath,
            doc.transcribedTextId
          )
        ),
        this.firestore.onSnapshotDoc$<GigaUserNoteVersion | null | undefined>(),
        // tap(note => {
        //   console.log(note);
        // }),
        jsonPopulated<GigaUserNoteVersion>(),
        map((doc: GigaUserNoteVersion) => doc.text)
      )
      .subscribe((doc: string) => {
        this.store.dispatch(
          updateLatestTranscriptionVersion({latestTransitionVersion: doc})
        );
      });

    // this.noteStore.saveDataProcessQueue.currentItem$
    //   .pipe(
    //     ofDlcNoteActionTypeWithTranscription(
    //       DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION
    //     )
    //   )
    //   .subscribe(async (action: DlcNoteProcessQueue) => {
    //     console.log('currentItem$ dlc-note-transcribed', action);
    //     if (action.transcriptionNoteVersion) {
    //       await this.noteStore.addTranscribedVersion(
    //         action.transcriptionNoteVersion
    //       );
    //       await that.noteStore.saveDataProcessQueue.next(action.id);
    //     }
    //   });
  }

  init() {
    // console.log('init transcribe note service');
  }

  async onImageUploadStart(pathOfImage: string) {
    // console.log('onImageUploadStart', pathOfImage);
    await this.noteStore.updateFirestoreDocPromise({
      // imagePath: pathOfImage,
      imageState: GigaAssetState.PENDING
    });
  }

  onDeleteImage() {
    // TODO: update firestore doc
  }

  // TODO move to store, update image path to firestore doc
  async onUploadComplete(pathOfImage: string) {
    await this.noteStore.updateFirestoreDocPromise({
      imagePath: pathOfImage,
      imageState: GigaAssetState.UPLOADED
    });
  }

  async doTranscribeImage() {
    // this.noteStore.doTranscribeImage({
    //   transcribeState: GigaActionState.IN_PROGRESS
    // });

    this.store.dispatch(
      doTranscribeImage({
        transcribeState: GigaActionState.IN_PROGRESS
      })
    );
  }

  onDestroy() {
    // console.log('destroying transcribe note service');
    // this._onDestroy$.next(true);
    // this._onDestroy$.complete;
  }
}
