export enum EC_HTTPS_CALLABLE {
  ACTIONS_QUIZZES = 'actionsQuizzes',
  COPY_IMAGE_ASSETS = 'copyImageAssetsV2',
  RECURSIVE_DELETE = 'recursiveDeleteV2',
  CREATE_AUTH_TOKEN = 'createAuthTokenV2',
  OPEN_AI_QUIZ_QUESTION_ASSISTANT = 'openAIQuizQuestionAssistantGen2',
  TRANSCRIBE_IMAGE = 'openAITranscribeTextFromImageGen2',
  ADD_TRANSCRIBED_IMAGE_TEXT_VERSION = 'openAIAddTranscribedTextVersionGen2',
  ADD_USER_NOTES_VERSSION = 'addUserNotesVersionGen2',
  STRIBE_WEB_CHECKOUT_SCRIPT = 'webCheckoutScriptV2',
  STRIBE_CANCEL_SUBSCRIPTION = 'cancelStripeSubscriptionV2'
}
