export interface NOTIFICATION_ID {
  [key: string]: string;
  APP_UPDATE: string;
}

export const DEFAULT_NOTIFICATION: NOTIFICATION_ID = {
  APP_UPDATE: 'APP_UPDATE_NOTIFICATION'
};

export interface UiNotification {
  id: string;

  message: string;
  title: string;
}
