import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DlcBtnState} from '@gigasoftware/shared/api';

import {DlcRoundedTextIconButtonComponent} from '../../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-transcribe-btn'
  },
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  selector: 'dlc-transcribe-btn',
  styleUrl: './dlc-transcribe-btn.component.scss',
  templateUrl: './dlc-transcribe-btn.component.html'
})
export class DlcTranscribeBtnComponent {
  btnState = signal(DlcBtnState.DISABLED);

  doTranscribe: OutputEmitterRef<boolean> = output();

  isDisabled = computed(() => {
    const state = this.btnState();
    return (
      state === DlcBtnState.DISABLED ||
      state === DlcBtnState.IN_PROGRESS ||
      state === DlcBtnState.PENDING
    );
  });

  transcribeInProgress = computed(() => {
    return this.btnState() === DlcBtnState.IN_PROGRESS;
  });

  transcribePending = computed(() => {
    return this.btnState() === DlcBtnState.PENDING;
  });

  onTranscribe() {
    this.doTranscribe.emit(true);
    this.btnState.set(DlcBtnState.PENDING);
  }

  @Input()
  set state(state: DlcBtnState | undefined) {
    if (state !== undefined) {
      this.btnState.set(state);
    }
  }
}
