import {DlcSaveBtnState, GigaNoteDoc} from '@gigasoftware/shared/api';
import {
  GSFirebaseUploadImageConfig,
  GSFirebaseUploadImageWithDocConfig
} from '@gigasoftware/shared/media';

export enum DlcNoteSaveAction {
  SAVE_NOTE_TITLE = 'title', // done
  SAVE_USER_NOTE_VERSION = 'user_note', // done
  SAVE_TRANSCRIPTION_NOTE_VERSION = 'transcription_update', // done
  SAVE_FIRESTORE_DOC = 'doc' // done
}

export interface DlcNoteStore {
  firestoreDoc: GigaNoteDoc | null;
  firestorePath: string | null;
  isDirty: boolean;
  latestNoteVersion: string | null;
  latestTransitionVersion: string | null;
  processQueueBtnState: DlcSaveBtnState;
  saveBtnState: DlcSaveBtnState;
  transcribedNote: string | null;
  transcribedTextId: string;
  uploadConfig: Partial<GSFirebaseUploadImageConfig> | null;
  userNote: string | null;
  userTextId: string;
  uuid: string | null;
  // transcribeBtnState: DlcBtnState;
}

export interface DlcNoteProcessQueue {
  action: DlcNoteSaveAction;
  firestoreDoc: Partial<GigaNoteDoc>;
  id: number;
  title?: string;
  transcriptionNoteVersion?: string;
  userNoteVersion?: string;
}

export interface DlcNoteBasePathImageConfig {
  basePath: string;
  parentId: string;
  uploadImageConfig: Partial<GSFirebaseUploadImageConfig>;
}

export interface DlcNoteTranscribedTextIdNull {
  firestorePath: string | null;
  transcribedTextId: string;
}

export interface DlcNoteTranscribedTextId {
  firestorePath: string;
  transcribedTextId: string;
}

export interface DlcNoteUserTextIdNull {
  firestorePath: string | null;
  userTextId: string;
}

export interface DlcNoteUserTextId {
  firestorePath: string;
  userTextId: string;
}

export interface DlcInputImageConfig {
  filenameWithoutExtension: string;
  // full image path including extension
  // e.g. 'ec/images/1234567890.png'
  imagePath: string;
  uploadConfig: GSFirebaseUploadImageWithDocConfig;
}

export enum DlcInputImageStateEnum {
  UPLOADING = 'uploading',
  DOWNLOADING = 'downloading',
  EDIT = 'edit',
  DELETING = 'deleting',
  SAVE_CANCEL = 'save-cancel',
  ERROR = 'error',
  NO_IMAGE = 'no-image'
}

export interface DlcInputImageState {
  hasImage: boolean;
  showDeleteProgress: boolean;
  showDownloadProgress: boolean;
  showEditButtons: boolean;
  showError: boolean;
  showSaveCancelButtons: boolean;
  showUploadProgress: boolean;
  state: DlcInputImageStateEnum;
}
