import {DlcSaveBtnState, GigaNoteDoc} from '@gigasoftware/shared/api';
import {clone, cloneMerge} from '@gigasoftware/shared/fn';
import {createReducer, on} from '@ngrx/store';

import * as NoteActions from './note-add-edit.actions';
import {DlcNoteStore} from './note.model';

export const nodeAddEditFeatureKey = 'ngPat_note_add_edit';

export const initialNoteAddEditState: DlcNoteStore = {
  firestoreDoc: null,
  firestorePath: null,
  isDirty: false,
  latestNoteVersion: null,
  latestTransitionVersion: null,
  processQueueBtnState: DlcSaveBtnState.DISABLED,
  saveBtnState: DlcSaveBtnState.DISABLED,
  transcribedNote: null,
  transcribedTextId: '',
  uploadConfig: null,
  userNote: null,
  userTextId: '',
  uuid: null
};

export const reducer = createReducer(
  {
    ...initialNoteAddEditState
  },
  on(NoteActions.setCurrentNoteEntity, (state, action) => {
    return {
      ...state,
      firestoreDoc: action.note
    };
  }),
  on(NoteActions.clearCurrentNoteEntity, state => {
    return {
      ...state,
      firestoreDoc: null
    };
  }),
  on(NoteActions.updateCurrentNoteEntity, (state, action) => {
    const newState = {
      ...state
    };

    if (newState.firestoreDoc) {
      newState.firestoreDoc = {
        ...newState.firestoreDoc,
        ...action.changes
      };
    }

    return newState;
  }),
  on(NoteActions.patchFirestoreDoc, (state, action) => {
    const newDoc: GigaNoteDoc = cloneMerge(
      state.firestoreDoc || {},
      action.patch
    );

    return {
      ...clone(state),
      firestoreDoc: newDoc,
      transcribedTextId: newDoc.transcribedTextId || '',
      userTextId: newDoc.userNoteId || '',
      uuid: newDoc.id
    };
  }),
  on(NoteActions._addFirestoreDoc, (state, action) => {
    // console.log('_addFirestoreDoc', action.doc);
    // const newDoc: GigaNoteDoc = cloneMerge(
    //   state.firestoreDoc || {},
    //   action.doc
    // );

    return {
      ...JSON.parse(JSON.stringify(state)),
      firestoreDoc: {
        ...action.doc
      },
      isDirty: action.isDirty,
      saveBtnState: action.saveBtnState,
      transcribedTextId: action.doc.transcribedTextId || '',
      userTextId: action.doc.userNoteId || '',
      uuid: action.doc.id
    };
  }),
  on(NoteActions.updateFirestorePath, (state, action) => {
    return {
      ...JSON.parse(JSON.stringify(state)),
      firestorePath: action.path
    };
  }),
  on(NoteActions.updateIsDirty, (state, action) => {
    return {
      ...state,
      isDirty: action.isDirty
    };
  }),
  on(NoteActions.addNoteUuid, (state, action) => {
    return {
      ...state,
      uuid: action.uuid
    };
  }),
  on(NoteActions.updateLatestNoteVersion, (state, action) => {
    return {
      ...state,
      latestNoteVersion: action.latestNoteVersion
    };
  }),
  on(NoteActions.updateLatestTranscriptionVersion, (state, action) => {
    return {
      ...state,
      latestTransitionVersion: action.latestTransitionVersion
    };
  }),
  on(NoteActions.updateUploadConfig, (state, action) => {
    return {
      ...state,
      uploadConfig: action.uploadConfig
    };
  }),
  on(NoteActions._updateNoteTitle, (state, action) => {
    return {
      ...state,
      isDirty: action.isDirty,
      saveBtnState: action.saveBtnState
    };
  }),
  on(NoteActions._updateUserNote, (state, action) => {
    return {
      ...state,
      isDirty: action.isDirty,
      saveBtnState: action.saveBtnState,
      userNote: action.userNote
    };
  }),
  on(NoteActions.updateSaveBtnState, (state, action) => {
    return {
      ...state,
      saveBtnState: action.saveBtnState
    };
  }),
  on(NoteActions.updateProcessQueueBtnState, (state, action) => {
    return {
      ...state,
      processQueueBtnState: action.processQueueBtnState
    };
  }),
  on(NoteActions._updateNoteTitle, (state, action) => {
    return {
      ...state,
      isDirty: action.isDirty,
      saveBtnState: action.saveBtnState
    };
  }),
  on(NoteActions._updateUserNote, (state, action) => {
    return {
      ...state,
      isDirty: action.isDirty,
      saveBtnState: action.saveBtnState,
      userNote: action.userNote
    };
  }),
  on(NoteActions.clearAddEditNoteStore, state => {
    return {
      ...initialNoteAddEditState
    };
  })
);
