import {searchClient, SearchResponse} from '@algolia/client-search';
import {SearchClient} from '@algolia/client-search/dist/node';
import {AlgoliaSearchKeys} from '@gigasoftware/secrets';
import {Observable} from 'rxjs';

// export interface AlgoliaSearchKeys {
//   apiKey: string;
//   appId: string;
//   index: string;
// }

export class AlgoliaSearch {
  private client: SearchClient;

  constructor(private params: AlgoliaSearchKeys) {
    this.client = searchClient(this.params.appId, this.params.apiKey);

    // this.searchIndex = _client.searchSingleIndex(this.params.index);
  }

  search<T>() {
    const that = this;

    return (observable: Observable<string | null>) => {
      return new Observable(subscriber => {
        // this function will be called each time this
        // Observable is subscribed to.
        const subscription = observable.subscribe({
          complete() {
            // noop
          },
          error(err) {
            // We need to make sure we're propagating our errors through.
            subscriber.error(err);
          },
          next(value: string | null) {
            if (value && value.length) {
              that.client
                .searchSingleIndex({
                  indexName: that.params.index,
                  searchParams: {query: value}
                })
                .then((results: SearchResponse) => {
                  subscriber.next(results.hits);
                })
                .catch((err: any) => {
                  // We need to make sure we're propagating our errors through.
                  subscriber.error(err);
                });
            } else {
              subscriber.next([]);
            }
          }
        });

        // Return the finalization logic. This will be invoked when
        // the result errors, completes, or is unsubscribed.
        return () => {
          subscription.unsubscribe();
        };
      });
    };
  }
}
