import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FormControlStatus} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {DlcSaveBtnState} from '@gigasoftware/shared/api';

import {DlcRoundedTextIconButtonComponent} from '../dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';

export function mapFormControlStatusToSaveBtnState(
  status: FormControlStatus
): DlcSaveBtnState {
  switch (status) {
    case 'VALID':
      return DlcSaveBtnState.ACTIVE;
    case 'INVALID':
      return DlcSaveBtnState.DISABLED;
    case 'PENDING':
      return DlcSaveBtnState.IN_PROGRESS;
    default:
      return DlcSaveBtnState.DISABLED;
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-save-button'
  },
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatProgressSpinner
  ],
  selector: 'dlc-save-button',
  styleUrl: './dlc-save-button.component.scss',
  templateUrl: './dlc-save-button.component.html'
})
export class DlcSaveButtonComponent {
  btnState: WritableSignal<DlcSaveBtnState> = signal(DlcSaveBtnState.DISABLED);

  doSave: OutputEmitterRef<boolean> = output();

  isDisabled = computed(() => {
    const state = this.btnState();
    return (
      state === DlcSaveBtnState.DISABLED ||
      state === DlcSaveBtnState.IN_PROGRESS
    );
  });

  label = input('Save');

  saveInProgress = computed(() => {
    return this.btnState() === DlcSaveBtnState.IN_PROGRESS;
  });

  @Input({
    transform: (state: DlcSaveBtnState | undefined) => {
      return state ? state : DlcSaveBtnState.DISABLED;
    }
  })
  set state(state: DlcSaveBtnState) {
    this.btnState.set(state);
  }
}
