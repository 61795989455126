import {
  BaseEntity,
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  getQuizByParentEntityIdOrAllQuizzes,
  Quiz
} from '@gigasoftware/shared/api';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {selectNgPatAddedAndDeletedEntities} from '../selectors';
import {quizAdapter, quizFeatureKey, QuizState} from './quiz.reducer';

export const selectQuizState = createFeatureSelector<QuizState>(quizFeatureKey);

const {selectAll, selectEntities, selectIds, selectTotal} =
  quizAdapter.getSelectors();

// export const selectAllQuizzes = createSelector(selectQuizState, selectAll);
export const selectQuizEntities = createSelector(
  selectQuizState,
  selectEntities
);
export const selectQuizIds = createSelector(selectQuizState, selectIds);
// export const selectQuizTotal = createSelector(selectQuizState, selectTotal);

export const selectAllQuizzes = createSelector(selectQuizState, selectAll);
export const selectQuizTotal = createSelector(selectQuizState, selectTotal);

export const selectQuizMemberMap = createSelector(
  selectAllQuizzes,
  (quizzes: Quiz[]) => {
    return convertCollaborativeEntityToMemberUIDEntityID(
      quizzes as CollaborativeEntity[]
    );
  }
);

export const getQuizByID = (id: string | null | undefined) =>
  createSelector(selectQuizEntities, (entities: Dictionary<Quiz>) => {
    if (id) {
      return entities[<string>id];
    }
    return undefined;
  });

export const getQuizzesByParentID = (parentID: string) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz | undefined) => q !== undefined && q.parentEntityID === parentID
    );
  });

export const getQuizAssignedByParentID = (parentID: string) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz[] => {
    return quizs
      .filter(
        (q: Quiz | undefined) =>
          q !== undefined && q.parentEntityID === parentID
      )
      .filter((q: Quiz) => q.assigned);
  });

export const getQuizAssignedByParentIDAndQuizId = (
  parentID: string,
  quizId: string
) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz | undefined => {
    return quizs
      .filter(
        (q: Quiz | undefined) =>
          q !== undefined && q.parentEntityID === parentID
      )
      .filter((q: Quiz) => q.assigned)
      .filter((q: Quiz) => q.id === quizId)[0];
  });

export const getQuizByTimestamp = (nanoseconds: string | null | undefined) =>
  createSelector(selectAllQuizzes, (quiz: Quiz[]) => {
    return quiz.reduce(
      (found: Quiz | null | undefined, i: Quiz | undefined) => {
        if (
          !found &&
          i &&
          getCreatedAtNanoseconds(i as BaseEntity) === nanoseconds
        ) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getQuizById = (id: string | null | undefined) =>
  createSelector(selectAllQuizzes, (quiz: Quiz[]) => {
    return quiz.reduce(
      (found: Quiz | null | undefined, i: Quiz | undefined) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectAllPersonalQuizzes = createSelector(
  selectAllQuizzes,
  (quizzes: Quiz[]) => quizzes.filter((q: Quiz) => !q.isCollaborative)
);

export const selectAllPersonalQuizzesIds = createSelector(
  selectAllPersonalQuizzes,
  (quizzes: Quiz[]) => quizzes.map((q: Quiz) => q.id)
);

export const getQuizByParentIdOrAllQuizzes = (
  parentID: string | null | undefined
) =>
  createSelector(
    selectAllQuizzes,
    selectAllPersonalQuizzes,
    (quizs: Quiz[], personalQuizzes: Quiz[]): Quiz[] => {
      return getQuizByParentEntityIdOrAllQuizzes(
        quizs,
        personalQuizzes,
        parentID
      );
    }
  );

export const selectAllStudyGroupQuizzes = createSelector(
  selectAllQuizzes,
  (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz) =>
        q.parentEntityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id
    );
  }
);

export const selectAllClassroomQuizzes = createSelector(
  selectAllQuizzes,
  (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz) =>
        q.parentEntityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
    );
  }
);

export const selectAddedAndDeletedQuizzes =
  selectNgPatAddedAndDeletedEntities<Quiz>(selectQuizEntities);

export const selectQuizBtnActionState = createSelector(
  selectQuizState,
  (state: QuizState) => state.btnQuizActionState
);

export const selectQuizSaveBtnState = createSelector(
  selectQuizState,
  (state: QuizState) => state.btnQuizSaveState
);
