import {Dictionary} from '@ngrx/entity';
import {createSelector} from '@ngrx/store';

import {WebSubscriptionProducts} from '../+in_app_purchase/index';
import {transformStripePriceToUiPrice} from '../normalized-price.fns';
import {NgPatNormalizedPrice} from '../normalized-price.model';
import {selectNgPatAllStripePrices} from './+prices';
import {
  NgPatStripeProduct,
  NgPatStripeProductPrice,
  selectHardCodedSubscriptionWebProducts,
  selectNgPatStripeAllProducts
} from './+product';
import {selectStripeActiveSubscriptionPriceDictionary} from './+subscriptions/index';
import {StripeProductWithPrices} from './stripe.model';

export const selectNgPatCombineWebProductWithPrices = createSelector(
  selectNgPatStripeAllProducts,
  selectNgPatAllStripePrices,
  (
    products: NgPatStripeProduct[],
    prices: NgPatStripeProductPrice[]
  ): StripeProductWithPrices[] => {
    return products.map((product: NgPatStripeProduct) => {
      return <StripeProductWithPrices>{
        prices: prices.filter(
          (price: NgPatStripeProductPrice) => price.product === product.id
        ),
        product
      };
    });
  }
);

export const selectAllStripeTransformedProducts = createSelector(
  selectNgPatCombineWebProductWithPrices,
  selectHardCodedSubscriptionWebProducts,
  selectStripeActiveSubscriptionPriceDictionary,
  (
    products: StripeProductWithPrices[],
    hardCodedSubscriptionProducts: WebSubscriptionProducts,
    activeSubscriptionPriceDictionary: Dictionary<string>
  ): NgPatNormalizedPrice[] => {
    return transformStripePriceToUiPrice(
      products,
      hardCodedSubscriptionProducts,
      activeSubscriptionPriceDictionary
    );
  }
);
