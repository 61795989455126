import {BASE_COLLECTION_NAME} from '../base-collection-path';
import {CollaborativeEntity} from '../entity.model';

export function firestoreUserBookmarkCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

export function firestoreBookmarkCollectionPath(
  id: string,
  uid: string,
  collaborativeEntity?: CollaborativeEntity | null
) {
  if (collaborativeEntity) {
    return `${collaborativeEntity.firestoreDocumentPath}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
  }

  return `${firestoreUserBookmarkCollection(uid)}`;
}

export function firestoreBookmarkDocumentPath(
  id: string,
  uid: string,
  collaborativeEntity?: CollaborativeEntity | null
) {
  if (collaborativeEntity) {
    return `${collaborativeEntity.firestoreDocumentPath}/${BASE_COLLECTION_NAME.BOOKMARKS}/${id}`;
  }

  return `${firestoreUserBookmarkCollection(uid)}/${id}`;
}

export function firestoreStudyGroupBookmarkDoc(
  studyGroupID: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreStudyGroupBookmarkCollection(
  studyGroupID: string
): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

// CLASS BOOKMARKS
export function firestoreClassBookmarkDoc(
  classID: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.CLASSES}/${classID}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreClassBookmarkCollection(classID: string): string {
  return `${BASE_COLLECTION_NAME.CLASSES}/${classID}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

export function firestoreResearchBookmarkDoc(
  researchID: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreResearchBookmarkCollection(
  researchID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

export function firestoreInvestigationBookmarkDoc(
  investigationsID: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationsID}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreInvestigationBookmarkCollection(
  investigationsID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationsID}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

export function firestoreProjectBookmarkDoc(
  projectsID: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectsID}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreProjectBookmarkCollection(projectsID: string): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectsID}/${BASE_COLLECTION_NAME.BOOKMARKS}`;
}

export function firestoreUserBookmarkDoc(
  uid: string,
  bookmarkID: string
): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.BOOKMARKS}/${bookmarkID}`;
}

export function firestoreBookmarksCollection(
  baseCollection: BASE_COLLECTION_NAME,
  parentId: string
) {
  if (baseCollection === BASE_COLLECTION_NAME.USERS) {
    return firestoreUserBookmarkCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.STUDY_GROUPS) {
    return firestoreStudyGroupBookmarkCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.CLASSES) {
    return firestoreClassBookmarkCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.RESEARCH) {
    return firestoreResearchBookmarkCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.INVESTIGATIONS) {
    return firestoreInvestigationBookmarkCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.PROJECTS) {
    return firestoreProjectBookmarkCollection(parentId);
  }

  return '';
}

export function firestoreBookmarksDoc(
  baseCollection: BASE_COLLECTION_NAME,
  parentId: string,
  bookmarkID: string
) {
  if (baseCollection === BASE_COLLECTION_NAME.USERS) {
    return firestoreUserBookmarkDoc(parentId, bookmarkID);
  } else if (baseCollection === BASE_COLLECTION_NAME.STUDY_GROUPS) {
    return firestoreStudyGroupBookmarkDoc(parentId, bookmarkID);
  } else if (baseCollection === BASE_COLLECTION_NAME.CLASSES) {
    return firestoreClassBookmarkDoc(parentId, bookmarkID);
  } else if (baseCollection === BASE_COLLECTION_NAME.RESEARCH) {
    return firestoreResearchBookmarkDoc(parentId, bookmarkID);
  } else if (baseCollection === BASE_COLLECTION_NAME.INVESTIGATIONS) {
    return firestoreInvestigationBookmarkDoc(parentId, bookmarkID);
  } else if (baseCollection === BASE_COLLECTION_NAME.PROJECTS) {
    return firestoreProjectBookmarkDoc(parentId, bookmarkID);
  }

  return '';
}
