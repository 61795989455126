import {GigaNoteDoc} from '@gigasoftware/shared/api';
import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as NoteReducer from './note.reducer';

export const selectNoteState = createFeatureSelector<NoteReducer.NoteState>(
  NoteReducer.noteFeatureKey
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  NoteReducer.noteAdapter.getSelectors();

export const selectAllNotes = createSelector(
  selectNoteState,
  (state: NoteReducer.NoteState) => selectAll(state)
);
export const selectNoteEntities = createSelector(
  selectNoteState,
  (state: NoteReducer.NoteState) => selectEntities(state)
);
export const selectNoteIds = createSelector(
  selectNoteState,
  (state: NoteReducer.NoteState) => selectIds(state)
);
export const selectNoteTotal = createSelector(
  selectNoteState,
  (state: NoteReducer.NoteState) => selectTotal(state)
);

export const selectedNoteID = createSelector(
  selectNoteState,
  (state: NoteReducer.NoteState) => state.selectedNoteID
);

export const getNoteByID = (id: string) =>
  createSelector(selectNoteEntities, (entities: Dictionary<GigaNoteDoc>) => {
    return entities[id];
  });

export const getNotesByParentId = (parentId: string) =>
  createSelector(selectAllNotes, (notes: GigaNoteDoc[]) => {
    return notes.filter(note => note.parentId === parentId);
  });

export const getSelectedNote = createSelector(
  selectNoteEntities,
  selectedNoteID,
  (
    entities: Dictionary<GigaNoteDoc>,
    selectedID: string | null
  ): GigaNoteDoc | undefined | null => {
    return selectedID ? entities[selectedID] : null;
  }
);
