export enum BASE_COLLECTION_NAME {
  ACTIONS_NOTES = 'actions_notes',
  ACTIONS_QUIZZES = 'actions_quizzes',
  ANSWERS = 'answers',
  APP = 'app',
  BOOKMARKS = 'bookmarks',
  CHAT_TOPICS = 'chatTopics',
  CLASSES = 'classes',
  COURSES = 'courses',
  INVESTIGATIONS = 'investigations',
  JOURNAL = 'journal',
  NOTE_TRANSCRIBED_VERSION = 'transcribedVersions',
  NOTES = 'notes',
  NOTES_VERSIONS = 'noteVersions',
  PROJECTS = 'projects',
  PROMO_CODES = 'promoCodes',
  PUBLISHED_QUIZZES = 'publishedQuizzes',
  QUESTIONS = 'questions',
  QUIZ_RESULTS = 'quizGrades',
  QUIZZES = 'quizzes',
  RESEARCH = 'researchTopics',
  STUDY_GROUPS = 'studyGroups',
  SUBJECTS = 'subjects',
  USERS = 'users'
}
