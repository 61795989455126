import {toSignal} from '@angular/core/rxjs-interop';
import {cloneMerge} from '@gigasoftware/shared/fn';
import {ComponentStore} from '@ngrx/component-store';
import {
  Comparer,
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityState,
  IdSelector
} from '@ngrx/entity';
import {Update} from '@ngrx/entity/src/models';
import {Observable} from 'rxjs';

import {NgPatProcessQueueState} from './process-queue.model';

export interface EntityComponentStoreState<T> extends EntityState<T> {
  paused: boolean;
  processingState: NgPatProcessQueueState;
  selectedId: string | number | null;
}

export interface EntityComponentStoreSelectAllAndSelectedId<T> {
  all: T[];
  selectedId: string | number | null;
}

export interface EntityComponentStoreOptions<T> {
  // property to use as the Id
  idKey?: string;

  // function to select Id
  idSelector?: IdSelector<T>;

  // stop processing
  paused: boolean;

  // sort comparer
  sortComparer?: false | Comparer<T>;
}

function createSelectIdFunction<T>(idKey = 'id'): IdSelector<T> {
  return (entity: T | any) => entity[idKey];
}

function createSortComparerFunction<T>(idKey = 'id'): Comparer<T> {
  return (a: T, b: T) => {
    return (<any>a)[idKey] - (<any>b)[idKey];
  };
}

export class EntityComponentStore<T> extends ComponentStore<
  EntityComponentStoreState<T>
> {
  get ids(): string[] | number[] {
    return this.state().ids;
  }

  private _adapter!: EntityAdapter<T>;

  get idKey(): string {
    return this.options?.idKey || 'id';
  }

  private _entitySelector!: (
    entities: Dictionary<T>,
    id: string
  ) => T | undefined;

  private _selectIds!: (state: EntityState<T>) => string[] | number[];
  private _selectEntities!: (state: EntityState<T>) => Dictionary<T>;
  private _selectAll!: (state: EntityState<T>) => T[];
  private _selectTotal!: (state: EntityState<T>) => number;

  constructor(public options?: EntityComponentStoreOptions<T>) {
    const adapter = createEntityAdapter<T>({
      selectId: options?.idSelector || createSelectIdFunction(options?.idKey),
      sortComparer:
        options?.sortComparer || createSortComparerFunction(options?.idKey)
    });
    super(
      adapter.getInitialState({
        paused: options?.paused || false,
        processingState: NgPatProcessQueueState.IDLE,
        selectedId: null
      })
    );

    this._adapter = adapter;
    this._entitySelector = (
      entities: Dictionary<T>,
      id: string
    ): T | undefined => {
      return entities[id];
    };

    const {selectAll, selectEntities, selectIds, selectTotal} =
      adapter.getSelectors();

    this._selectIds = selectIds;
    this._selectEntities = selectEntities;
    this._selectAll = selectAll;
    this._selectTotal = selectTotal;
  }

  updateProcessingState(
    state: EntityComponentStoreState<T>,
    update?: NgPatProcessQueueState
  ): EntityComponentStoreState<T> {
    // not processing, no ids, set to idle
    const _state = {...state};

    if (
      state.processingState !== NgPatProcessQueueState.PROCESSING &&
      state.processingState !== NgPatProcessQueueState.PENDING &&
      state.ids.length === 0
    ) {
      _state.processingState = NgPatProcessQueueState.IDLE;
    }

    if (update) {
      _state.processingState = update;
    }

    // if (state.processingState === NgPatProcessQueueState.PROCESSING) {
    //   if (state.ids.length === 0) {
    //     state.processingState = NgPatProcessQueueState.IDLE;
    //   }
    // }

    return _state;
  }

  readonly addOne = this.updater((state, entity: T) => {
    return this.updateProcessingState(
      this._adapter.addOne(entity, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly addMany = this.updater((state, entities: T[]) => {
    return this.updateProcessingState(
      this._adapter.addMany(entities, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly setAll = this.updater((state, entities: T[]) => {
    return this.updateProcessingState(
      this._adapter.setAll(entities, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly setOne = this.updater((state, entity: T) => {
    return this.updateProcessingState(
      this._adapter.setOne(entity, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly setMany = this.updater((state, entities: T[]) => {
    return this.updateProcessingState(
      this._adapter.setMany(entities, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly removeOne = this.updater((state, id: string | number) => {
    return this.updateProcessingState(
      this._adapter.removeOne(id.toString(), state)
    );
  });

  readonly removeMany = this.updater((state, ids: string[] | number[]) => {
    return this.updateProcessingState(
      this._adapter.removeMany(
        ids.map((id: string | number) => id.toString()),
        state
      )
    );
  });

  readonly removeAll = this.updater(state => {
    return this.updateProcessingState(this._adapter.removeAll(state));
  });

  readonly updateOne = this.updater((state, update: Update<T>) => {
    const _id = update.id;
    let _entity: Partial<T> | undefined = this._entitySelector(
      state.entities,
      _id as string
    );

    if (_entity) {
      _entity = cloneMerge(_entity, update.changes);
    } else {
      _entity = update.changes;
    }

    return this.updateProcessingState(
      this._adapter.updateOne(
        {changes: _entity as T, id: update.id as string},
        state
      ),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly updateMany = this.updater((state, updates: Update<T>[]) => {
    return this.updateProcessingState(
      this._adapter.updateMany(updates, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly upsertOne = this.updater((state, entity: T) => {
    const _id = (<any>entity)[this.idKey];
    let _entity: T | undefined = this._entitySelector(state.entities, _id);

    if (_entity) {
      _entity = cloneMerge(_entity, entity);
    } else {
      _entity = entity;
    }

    return this.updateProcessingState(
      this._adapter.upsertOne(_entity as T, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly upsertMany = this.updater((state, entities: T[]) => {
    const _entities: T[] = [];

    for (const _entity of entities) {
      const _id = (<any>_entity)[this.idKey];
      const _e = this._entitySelector(state.entities, _id);

      if (_e) {
        _entities.push(cloneMerge(_e, _entity));
      } else {
        _entities.push(_entity);
      }
    }

    return this.updateProcessingState(
      this._adapter.upsertMany(_entities, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly mapOne = this.updater((state, entityMap: any) => {
    return this.updateProcessingState(
      this._adapter.mapOne(entityMap, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly map = this.updater((state, entityMap: any) => {
    return this.updateProcessingState(
      this._adapter.map(entityMap, state),
      NgPatProcessQueueState.PENDING
    );
  });

  readonly clear = this.updater(() => {
    return this.updateProcessingState(
      this._adapter.removeAll({...this.state(), selectedId: null})
    );
  });

  readonly selectId = this.updater((state, id: string | number | null) => {
    return {...state, selectedId: id};
  });

  readonly setProcessingState = this.updater(
    (state, processingState: NgPatProcessQueueState) => {
      return {...state, processingState};
    }
  );

  /** SELECTORS **/
  /** SELECTORS **/
  /** SELECTORS **/
  /** SELECTORS **/
  readonly selectIds$ = this.select(state => this._selectIds(state));
  readonly selectEntities$ = this.select(state => this._selectEntities(state));
  readonly selectAll$ = this.select(state => this._selectAll(state));
  readonly selectAll = toSignal(this.selectAll$);
  readonly selectTotal$ = this.select(state => this._selectTotal(state));

  selectedId$ = this.select(state => state.selectedId);
  selectedEntity$ = this.select(state => {
    if (!state.selectedId) {
      return null;
    }
    return state.entities[state.selectedId];
  });

  readonly selectItemById$ = (id: string | number) => {
    return this.select(state => state.entities[id]);
  };

  readonly has$ = (id: string | number) => {
    return this.select(state => (<any>state.ids).includes(id));
  };

  readonly hasEntity$ = (entity: T) => {
    return this.select(state => {
      const _id = (<any>entity)[this.idKey];
      return this._entitySelector(state.entities, _id) !== undefined;
    });
  };

  readonly isEmpty$ = this.select(state => state.ids.length === 0);

  readonly selectById$ = (id: string | number) => {
    return this.select(state => state.entities[id]);
  };

  readonly selectAllAndSelectedId$: Observable<
    EntityComponentStoreSelectAllAndSelectedId<T>
  > = <Observable<EntityComponentStoreSelectAllAndSelectedId<T>>>this.select(
    state => {
      return {
        all: this._selectAll(state),
        selectedId: state.selectedId
      };
    }
  );

  readonly processingState$ = this.select(state => state.processingState);

  readonly isProcessing$ = this.select(
    state => state.processingState === NgPatProcessQueueState.PROCESSING
  );
}
