import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {map, tap} from 'rxjs/operators';

import {IAP_CONFIG, IAPConfig} from '../../+in_app_purchase/injection-tokens';
import {
  loadHardCodedSubscriptionToStripeProducts,
  ngPatInitStripeProduct
} from './product.actions';
import {ProductService} from './product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductEffects implements OnInitEffects {
  onInitEffects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ngPatInitStripeProduct),
      tap(() => {
        this._productService.initState$.next(true);
      }),
      map(() => {
        return loadHardCodedSubscriptionToStripeProducts({
          hardCodedSubscriptionProducts: this.iapConfig.webSubscriptionProducts
        });
      })
    );
  });
  constructor(
    private _actions$: Actions,
    private store: Store,
    private _productService: ProductService,
    @Inject(IAP_CONFIG) private iapConfig: IAPConfig
  ) {}

  ngrxOnInitEffects(): Action {
    return ngPatInitStripeProduct();
  }
}
