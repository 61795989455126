import {
  EC_QUIZ_ROLES,
  getMemberOwnerUID,
  UserIdWithRole
} from '@gigasoftware/shared/api';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {NgPatMonitorAccounts} from '../+account/account.model';
import {selectNgPatMentorAccountsDict} from '../+account/account.selectors';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Member, MemberListItem} from './members.model';
import * as MembersReducer from './members.reducer';

export const selectMembersState =
  createFeatureSelector<MembersReducer.MembersState>(
    MembersReducer.membersFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  MembersReducer.membersAdapter.getSelectors();

export const selectAllMemberss = createSelector(selectMembersState, selectAll);
export const selectMembersEntities = createSelector(
  selectMembersState,
  selectEntities
);
export const selectMembersIds = createSelector(selectMembersState, selectIds);
export const selectMembersTotal = createSelector(
  selectMembersState,
  selectTotal
);
export const selectedMembersID = createSelector(
  selectMembersState,
  (state: MembersReducer.MembersState) => state.selectedMembersID
);

export const getMemberByID = (id: string | null | undefined) =>
  createSelector(selectMembersEntities, (entities: Dictionary<Member>) => {
    if (id) {
      return entities[id];
    }

    return undefined;
  });

export const getMemberUserNameByID = (id: string) =>
  createSelector(selectMembersEntities, (entities: Dictionary<Member>) => {
    if (entities[id]) {
      return entities[id]?.username;
    }
    return '';
  });

export const getMemberUserNameByEntityOwner = (members: {
  [uid: string]: UserIdWithRole;
}) =>
  createSelector(
    selectMembersEntities,
    (entities: Dictionary<Member>): string => {
      const uid: string | undefined | null = getMemberOwnerUID(members);
      if (uid && entities[uid] !== undefined) {
        return entities[uid]?.username as string;
      }
      return '';
    }
  );

export function getMemberListItemsByEntityID(
  entityID: string | null | undefined,
  loggedInUserIsMentor: boolean,
  mentorAccounts: NgPatMonitorAccounts,
  members: Member[]
): MemberListItem[] {
  if (entityID) {
    return members
      .filter((m: Member) => m.entityIDs.includes(entityID))
      .filter((m: Member) => {
        if (loggedInUserIsMentor) {
          return mentorAccounts[m.uid];
        }

        return true;
      })
      .map(
        (m: Member) =>
          <MemberListItem>{
            entityID,
            member: m,
            role: m.entities[entityID].role,
            uid: m.uid,
            username: m.username
          }
      );
  }

  return [];
}

export const getMembersByEntityID = (entityID: string | null | undefined) =>
  createSelector(
    selectHasActiveSubscription,
    selectNgPatMentorAccountsDict,
    selectAllMemberss,
    (
      loggedInUserIsMentor: boolean,
      mentorAccounts: NgPatMonitorAccounts,
      members: Member[]
    ): MemberListItem[] => {
      return getMemberListItemsByEntityID(
        entityID,
        loggedInUserIsMentor,
        mentorAccounts,
        members
      );
    }
  );

export const getStudentsByEntityID = (entityID: string) =>
  createSelector(getMembersByEntityID(entityID), (members: MemberListItem[]) =>
    members.filter((m: MemberListItem) => m.role === EC_QUIZ_ROLES.Student)
  );
