import {
  Answer,
  QUESTION_TYPE,
  QuestionWithAnswer,
  TakeQuizAggregateMultiChoiceAnswers,
  TakeQuizResult
} from '@gigasoftware/shared/api';
import {getRandomRangeFromArray, toDictionary} from '@gigasoftware/shared/fn';

function createAllAnswerDict(questions: QuestionWithAnswer[]): {
  [answerID: string]: Answer;
} {
  return questions
    .filter(
      (q: QuestionWithAnswer) =>
        q.question.questionType === QUESTION_TYPE.MULTIPLE_CHOICE
    )
    .reduce((a: {[answerID: string]: Answer}, q: QuestionWithAnswer) => {
      // const answers: {[id: string]: Answer} = toDictionary(Object.values(q.question.answers).map((_a: Answer) => ({
      //   ..._a,
      //   isCorrect: false
      // })));

      for (const prop in q.question.answers) {
        if (q.question.answers[prop]) {
          a[prop] = {
            ...q.question.answers[prop],
            isCorrect: false
          };
        }
      }

      return a;
    }, {});
}

function filterNonQuestionAnswers(q: Answer[], allAnswers: Answer[]) {
  const ids: string[] = q.map((_q: Answer) => _q.id);

  return allAnswers.filter((a: Answer) => {
    return !ids.includes(a.id);
  });
}

export function aggregateAnswers(
  questions: QuestionWithAnswer[],
  c: TakeQuizAggregateMultiChoiceAnswers
) {
  const dict: {[answerID: string]: Answer} = createAllAnswerDict(questions);
  const allAnswers: Answer[] = Object.values(dict);

  return questions.map((q: QuestionWithAnswer) => {
    const questionAnswers = Object.values(q.question.answers);
    const missingAnswersLength = c.totalAnswers - questionAnswers.length;

    if (
      q.question.questionType === QUESTION_TYPE.MULTIPLE_CHOICE &&
      missingAnswersLength > 0
    ) {
      const remainingAnswers = filterNonQuestionAnswers(
        questionAnswers,
        allAnswers
      );

      const additionalAnswers: {[id: string]: Answer} =
        remainingAnswers.length >= missingAnswersLength
          ? toDictionary(
              getRandomRangeFromArray(remainingAnswers, missingAnswersLength)
            )
          : {};

      q.question.answers = {
        ...q.question.answers,
        ...additionalAnswers
      };
    }

    return q;
  });
}

export interface QuizTestProgress {
  /**
   * Average time per question in milliseconds
   */
  averageTimePerQuestionMS: number;

  /**
   * Average time per question in seconds
   */
  averageTimePerQuestionSeconds: number;

  /**
   * Percentage of current score
   */
  currentPctScore: number;

  /**
   * Number of answered questions
   */
  numberAnswered: number;

  /**
   * Percentage of correct answers
   */
  pctCorrect: number;

  /**
   * Total percentage of questions
   */
  pctProgress: number;

  /**
   * Percentage of wrong answers
   */
  pctWrong: number;

  /**
   * Total number of correct answers
   */
  totalCorrect: number;

  /**
   * Total number of questions
   */
  totalQuestions: number;

  /**
   * Total number of wrong answers
   */
  totalWrong: number;
}

export function calculateProgressBasedOnTakeQuizResult(
  result: TakeQuizResult
): QuizTestProgress {
  const totalQuestions = Object.values(result.questions).length;
  const numberAnswered = Object.values<QuestionWithAnswer>(
    result.questions
  ).filter((q: QuestionWithAnswer): boolean => q.isAnswered).length;

  const totalCorrect = Object.values<QuestionWithAnswer>(
    result.questions
  ).filter((q: QuestionWithAnswer) => q.isAnswered && q.isCorrect).length;

  const totalWrong = Object.values<QuestionWithAnswer>(result.questions).filter(
    (q: QuestionWithAnswer) => q.isAnswered && !q.isCorrect
  ).length;

  const averageTimePerQuestionMS =
    Object.values<QuestionWithAnswer>(result.questions).reduce(
      (a: number, q: QuestionWithAnswer) => {
        return a + q.timeToAnswerMS;
      },
      0
    ) / totalQuestions;

  const averageTimePerQuestionSeconds = averageTimePerQuestionMS / 1000;

  const pctProgress = Math.floor((numberAnswered / totalQuestions) * 100);

  const pctCorrect = Math.floor(
    (Object.values(result.questions).filter(
      (q: QuestionWithAnswer) => q.isAnswered && q.isCorrect
    ).length /
      totalQuestions) *
      100
  );

  const pctWrong = Math.floor(
    (Object.values(result.questions).filter(
      (q: QuestionWithAnswer) => q.isAnswered && !q.isCorrect
    ).length /
      totalQuestions) *
      100
  );

  const currentPctScore =
    Math.floor(
      (Object.values(result.questions).filter(
        (q: QuestionWithAnswer) => q.isAnswered && q.isCorrect
      ).length /
        numberAnswered) *
        100
    ) || 0;

  return {
    averageTimePerQuestionMS,
    averageTimePerQuestionSeconds,
    currentPctScore,
    numberAnswered,
    pctCorrect,
    pctProgress,
    pctWrong,
    totalCorrect,
    totalQuestions,
    totalWrong
  };
}

export const initialQuizTestProgress: QuizTestProgress = {
  averageTimePerQuestionMS: 0,
  averageTimePerQuestionSeconds: 0,
  currentPctScore: 0,
  numberAnswered: 0,
  pctCorrect: 0,
  pctProgress: 0,
  pctWrong: 0,
  totalCorrect: 0,
  totalQuestions: 0,
  totalWrong: 0
};

export function cloneInitialQuizTestProgress(): QuizTestProgress {
  return {
    ...initialQuizTestProgress
  };
}
