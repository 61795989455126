import {BaseEntityType} from '@gigasoftware/shared/api';
import {createAction, props} from '@ngrx/store';

/**
 * Should only be called by the app component.
 */
export const configurePlatform = createAction(
  '[Platform] Configure',
  props<{
    themes: string[];
    defaultProjectType: BaseEntityType;
    entityTypes: BaseEntityType[];
  }>()
);
