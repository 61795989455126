import {formatDate} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {NgPatTimeStamp} from '@gigasoftware/shared/api';

@Pipe({
  name: 'firebaseTimestamp',
  standalone: true
})
export class FirebaseTimestampPipe implements PipeTransform {
  transform(
    value: NgPatTimeStamp | null | undefined,
    format = 'short',
    locale = 'en-US',
    timezone?: string
  ): unknown {
    /**
     * With the input value:
     * {
     *   nanoseconds: 0,
     *   seconds: 1619788800
     * }
     *
     * convert to unix timestamp:
     */
    const unixTimestamp = ((value && value.seconds) || 0) * 1000;
    const nanosecondsToMilliseconds =
      ((value && value.nanoseconds) || 0) / 1000000;
    const milliseconds = unixTimestamp + nanosecondsToMilliseconds;

    return formatDate(milliseconds, format, locale, timezone);
  }
}
