import {inject, Injectable} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {
  AlgoliaPublishedQuiz,
  BaseEntity,
  CreateQuizParams,
  CreateQuizParamsV2,
  DEFAULT_QUIZ_PARAMS,
  Quiz
} from '@gigasoftware/shared/api';
import {uuid} from '@gigasoftware/shared/fn';
import {PartialStateUpdater, patchState, signalState} from '@ngrx/signals';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AlgoliaChipsAutocomplete} from '../firebase/algolia/algolia-chips-autocomplete';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '../models/environmnent';
import {QuizCreatorService} from './quiz-creator.service';
import {updateFirestorePartialQuiz} from './quiz.actions';

const initialState: CreateQuizParamsV2 = {
  ...DEFAULT_QUIZ_PARAMS,
  parentEntity: undefined
};

@Injectable()
export class QuizCreatorComponentService {
  private store = inject(Store);
  private quizService: QuizCreatorService = inject(QuizCreatorService);

  private environment: EvolutionCognitionEnvironment =
    inject<EvolutionCognitionEnvironment>(EC_ENVIRONMENT);

  readonly state = signalState(initialState);

  private _onDestroy$: Subject<boolean> = new Subject();

  private _editQuiz: Quiz | null = null;

  get editQuiz(): Quiz | null {
    return this._editQuiz;
  }

  private partialUpdateState = (
    update: Partial<CreateQuizParams>
  ): PartialStateUpdater<CreateQuizParamsV2> => {
    return state => ({
      ...state,
      ...update
    });
  };

  private setParentEntityState = (
    parentEntity: BaseEntity
  ): PartialStateUpdater<CreateQuizParamsV2> => {
    return state => ({
      ...state,
      parentEntity
    });
  };

  private setUuidState = (
    id: string
  ): PartialStateUpdater<CreateQuizParamsV2> => {
    return state => ({
      ...state,
      id
    });
  };

  private setSubjectState = (
    subjects: string
  ): PartialStateUpdater<CreateQuizParamsV2> => {
    return state => ({
      ...state,
      subjects
    });
  };

  // AUTOCOMPLETE FOR SUBJECTS
  algoliaSubjectAutocomplete: AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz> =
    new AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz>({
      apiKey: this.environment.algoliaSubjects.apiKey,
      appId: this.environment.algoliaSubjects.appId,
      index: this.environment.algoliaSubjects.index
    });

  get defaultQuizParams(): CreateQuizParams {
    return {
      ...DEFAULT_QUIZ_PARAMS
    };
  }

  constructor() {
    this.setUUID(this.createUUID());
  }

  createUUID(): string {
    return uuid();
  }

  setUUID(id: string): void {
    patchState(this.state, this.setUuidState(id));
  }

  setParentEntity(parentEntity: BaseEntity): void {
    patchState(this.state, this.setParentEntityState(parentEntity));
  }

  setSubjects = (subjects: string): void => {
    patchState(this.state, this.setSubjectState(subjects));
  };

  patchState(update: Partial<CreateQuizParamsV2>): void {
    patchState(this.state, this.partialUpdateState(update));
  }

  addAlgoliaSubjectAutocomplete(event: MatChipInputEvent): void {
    this.algoliaSubjectAutocomplete.add(event);
    this.setSubjects(this.algoliaSubjectAutocomplete.getSelectedAsString());
  }

  removeAlgoliaSubjectAutocomplete(subject: string): void {
    this.algoliaSubjectAutocomplete.remove(subject);
    this.setSubjects(this.algoliaSubjectAutocomplete.getSelectedAsString());
  }

  selectAlgoliaSubjectAutocomplete(event: MatAutocompleteSelectedEvent): void {
    this.algoliaSubjectAutocomplete.selected(event);
    this.setSubjects(this.algoliaSubjectAutocomplete.getSelectedAsString());
  }

  setManyCommaDelimitedAlgoliaSubjectAutocomplete(subjects: string): void {
    this.algoliaSubjectAutocomplete.setManyCommaDelimited(subjects);
    this.setSubjects(this.algoliaSubjectAutocomplete.getSelectedAsString());
  }

  createQuiz = () => {
    this.quizService.createQuizWithParamsV2$(this.state()).subscribe(() => {
      // quiz created
    });

    // this.store.dispatch(
    //     createQuizWithParamsV2({
    //       params: {
    //         ...params
    //       }
    //     })
    // );
  };

  createQuiz$ = (): Observable<Quiz> => {
    return this.quizService.createQuizWithParamsV2$(this.state());
  };

  // edit quiz
  setEditQuiz = (quiz: Quiz) => {
    /**
     * Copy quiz properties to state properties of only used in this form structure conditionally
     * if the quiz property has value in the correspoding state property
     *  aggregateFalseAnswers: new FormControl<boolean>(true),
     *     description: new FormControl<string>(''),
     *     gradeLevel: new FormControl<number | null>(null),
     *     name: new FormControl<string>('', [
     *       Validators.minLength(3),
     *       Validators.required
     *     ]),
     *     subjects: new FormControl<string>('')
     *
     *
     */
    this._editQuiz = quiz;
    const params: Partial<CreateQuizParamsV2> = {
      aggregateFalseAnswers: DEFAULT_QUIZ_PARAMS.aggregateFalseAnswers,
      description: DEFAULT_QUIZ_PARAMS.description,
      gradeLevel: DEFAULT_QUIZ_PARAMS.gradeLevel,
      name: DEFAULT_QUIZ_PARAMS.name,
      subjects: DEFAULT_QUIZ_PARAMS.subjects
    };

    if (quiz.aggregateFalseAnswers) {
      params.aggregateFalseAnswers = quiz.aggregateFalseAnswers;
    }

    if (quiz.description) {
      params.description = quiz.description;
    }

    if (quiz.gradeLevel) {
      params.gradeLevel = quiz.gradeLevel;
    }

    if (quiz.name) {
      params.name = quiz.name;
    }

    if (quiz.subjects) {
      params.subjects = quiz.subjects;
    }

    this.patchState(params);
  };

  startLiveUpdateChanges = () => {
    this.algoliaSubjectAutocomplete.valueChanges$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((subjects: string) => {
        const quiz: Quiz | null = this.editQuiz;

        if (quiz) {
          this.algoliaSubjectAutocomplete.clear();

          this.store.dispatch(
            updateFirestorePartialQuiz({
              changes: <Quiz>{
                subjects: subjects
              },
              quiz
            })
          );
        }
      });
  };

  updateQuiz = () => {
    if (this.editQuiz) {
      /**
       * Copy state properties only needed for edit quiz params
       */
      const params: Partial<CreateQuizParamsV2> = {
        aggregateFalseAnswers: this.state().aggregateFalseAnswers,
        description: this.state().description,
        gradeLevel: this.state().gradeLevel,
        name: this.state().name,
        subjects: this.state().subjects
      };

      this.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>params,
          quiz: this.editQuiz
        })
      );
    }
  };

  destroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
