import {Bookmark} from '@gigasoftware/shared/api';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {
  bookmarkAdapter,
  bookmarksFeatureKey,
  BookmarkState
} from './bookmark.reducer';

export const selectBookmarkState =
  createFeatureSelector<BookmarkState>(bookmarksFeatureKey);

export const {selectAll, selectEntities, selectIds, selectTotal} =
  bookmarkAdapter.getSelectors();

export const selectAllBookmarks = createSelector(
  selectBookmarkState,
  selectAll
);

export const selectBookmarkEntities = createSelector(
  selectBookmarkState,
  selectEntities
);

export const selectBookmarkIds = createSelector(selectBookmarkState, selectIds);

export const selectBookmarkTotal = createSelector(
  selectBookmarkState,
  selectTotal
);

export const selectBookmarksByEntityID = (
  entityId: string | undefined | null
) =>
  createSelector(selectAllBookmarks, (bookmarks: Bookmark[]) =>
    bookmarks.filter(bookmark => bookmark.parentEntityID === entityId)
  );
