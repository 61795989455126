import {Injectable} from '@angular/core';
import {
  Classroom,
  createClass,
  createOwnerUserRole,
  UserIdWithRole
} from '@gigasoftware/shared/api';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {
  createClassroomEffect,
  deleteClassroom,
  deleteClassrooms,
  updateClassroomEffect,
  updateFirestorePartialClassroom
} from './classroom.actions';
import {PartialClassroomState} from './classroom.reducer';
import {getClassroomByID} from './classroom.selectors';
import {ClassroomService} from './classroom.service';

@Injectable({
  providedIn: 'root'
})
export class ClassroomEffects {
  deleteDoc$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteClassroom),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.id)),
            switchMap((classRoom: Classroom | undefined) =>
              this._classroomService.deleteDoc$(classRoom, <string>account.uid)
            )
          )
        )
      ),
    {dispatch: false}
  );

  deleteDocs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteClassrooms),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this._classroomService.deleteDocs$(
                classRoom,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  createClassroomEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(createClassroomEffect),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
            const classroom: Classroom = createClass(action.params, user);
            return this._classroomService.createClassroom(classroom);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateClassroomEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateClassroomEffect),
        switchMap(action => {
          return this._classroomService.updateDoc(action.classroom);
        })
      ),
    {dispatch: false}
  );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialClassroom),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._classroomService.updatePartialFirestore$(
            action.changes,
            action.classroom,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialClassroomState>,
    private _classroomService: ClassroomService
  ) {}
}
