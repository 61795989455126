import {
  DatabasePaths,
  NgPatFirebaseAppConfig,
  NgPatFirebaseConfigCreatorParams,
  NgPatTimeStamp,
  RemoteConfigParams
} from '@gigasoftware/shared/api';
import {cloneDeep, isPlainObject} from 'es-toolkit';
import {get} from 'es-toolkit/compat';
import {DocumentSnapshot} from 'firebase/firestore';
import {Observable, Observer} from 'rxjs';

export type RemoveCtorTimeStampFn<T> = (data: any) => T;

function removeCtorTimeStamp<T>(_data: T): T;
function removeCtorTimeStamp(_data: any): any {
  if (_data && _data['createdAt']) {
    const createdAt: NgPatTimeStamp = {
      nanoseconds: _data.createdAt
        ? parseInt(_data.createdAt.nanoseconds, 10)
        : 0,
      seconds: _data.createdAt ? parseInt(_data.createdAt.seconds, 10) : 0
    };

    delete _data.createdAt;
    _data.createdAt = createdAt;
  }

  if (_data && _data['updatedAt']) {
    const updatedAt = <NgPatTimeStamp>{
      nanoseconds: _data.updatedAt
        ? parseInt(_data.updatedAt.nanoseconds, 10)
        : 0,
      seconds: _data.updatedAt ? parseInt(_data.updatedAt.seconds) : 0
    };

    delete _data.updatedAt;
    _data.updatedAt = updatedAt;
  }

  return _data;
}

function recurseDataObject<T>(
  data: any | null,
  removeCtorFn: RemoveCtorTimeStampFn<T>,
  recurseFn: (...args: any[]) => T
): T {
  if (data) {
    const keys: string[] = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      if (isPlainObject(data[keys[index]])) {
        data[keys[index]] = removeCtorFn(data[keys[index]]);
      } else if (Array.isArray(keys[index])) {
        for (let arrIndex = 0; arrIndex < keys[index].length; arrIndex++) {
          data[keys[index]][arrIndex] = recurseFn(
            keys[index],
            removeCtorFn,
            recurseFn
          );
        }
      }
    }
  }

  return removeCtorFn(data);
}

export function removeTimeStampCTorFromData<T>(_data: any): T {
  return cloneDeep<T>(
    recurseDataObject(
      _data,
      removeCtorTimeStamp,
      recurseDataObject
    ) as unknown as T
  );
}

export function removeTimestampCTorFromDocumentSnapshot<T>(
  snap: DocumentSnapshot
): T {
  return removeTimeStampCTorFromData(snap.data());
}

export function getUpdatedAtSeconds(data: any) {
  return get(data, 'updatedAt.seconds', null);
}

// export const clearFirebaseLocalStorage

export function clearFirestoreStorage(): Observable<boolean> {
  return new Observable((observer: Observer<boolean>) => {
    const dbName = 'firebaseLocalStorageDb';

    const req = indexedDB.deleteDatabase(dbName);
    req.onsuccess = function () {
      console.log(`Deleted ${dbName} successfully`);
      observer.next(true);
    };
    req.onerror = function () {
      console.log(`Couldn't delete ${dbName} `);
      observer.error(true);
    };
    req.onblocked = function () {
      console.log(
        `Couldn't delete ${dbName}  due to the operation being blocked`
      );
      observer.error(true);
    };
  });
}

export function clearFiresbaseInstallations(): Observable<boolean> {
  return new Observable((observer: Observer<boolean>) => {
    const dbName = 'firebaseConfigParams-installations-database';

    const req = indexedDB.deleteDatabase(dbName);
    req.onsuccess = function () {
      console.log(`Deleted ${dbName} successfully`);
      observer.next(true);
    };
    req.onerror = function () {
      console.log(`Couldn't delete ${dbName} `);
      observer.error(true);
    };
    req.onblocked = function () {
      console.log(
        `Couldn't delete ${dbName}  due to the operation being blocked`
      );
      observer.error(true);
    };
  });
}

/**
 *
 * @param minimumFetchIntervalMillis default is 12 hours
 */
export function addRemoteConfigParams(
  // the default value is 12 hours in milliseconds
  minimumFetchIntervalMillis = 3600000,
  refreshIntervalMillis = 3600000
): RemoteConfigParams {
  return {
    settings: {
      minimumFetchIntervalMillis,
      refreshIntervalMillis
    }
  };
}

export function addDatabasePaths(
  databasePaths: DatabasePaths = {users: 'users'}
): DatabasePaths {
  return {
    users: databasePaths.users
  };
}

export const createDefaultFirebaseConfig = (
  c: NgPatFirebaseConfigCreatorParams
): NgPatFirebaseAppConfig => {
  return {
    appName: c.appName,
    appNameInFirebaseConfig: c.appNameInFirebaseConfig,
    databasePaths: c.databasePaths,
    env: c.env,
    firebase: c.firebase,
    remoteConfigParams: c.remoteConfigParams
  };

  //   ngPatFirebaseAppConfig = addRemoteConfigParams(ngPatFirebaseAppConfig);
  //
  //   return pipe(
  //     addRemoteConfigParams,
  //     addDatabasePaths
  //   )({
  //     env,
  //     firebaseConfigParams: config,
  //     appName
  //   });
};
