import {inject, Injectable} from '@angular/core';
import {
  GigaActionState,
  GigaNoteDoc,
  GigaNoteTranscribeNoteVersionUpdate,
  RecursivePartial
} from '@gigasoftware/shared/api';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {EntityProcessQueue} from '@gigasoftware/shared/utils';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {of, Subject} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {linkToGlobalState} from '../../+compontent-to-global/component-to-global';
import {patchFirestoreDoc} from '../note-add-edit.actions';
import {selectNoteAddEditFirestoreDoc} from '../note-add-edit.selectors';
import {DlcNoteProcessQueue} from '../note.model';

@Injectable({
  providedIn: 'root'
})
export class SaveAddEditNoteService {
  private store: Store = inject(Store);
  private firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  saveDataProcessQueue: EntityProcessQueue<DlcNoteProcessQueue> =
    new EntityProcessQueue();

  autoSaveTimer$ = new Subject();

  constructor() {
    linkToGlobalState(
      this.saveDataProcessQueue.state$,
      'SaveAddEditNoteService_saveDataProcessQueue',
      this.store
    );

    // this.autoSaveTimer$
    //   .pipe(
    //     switchMap(() => {
    //       // Only save if the process queue is pending something to save
    //       return this.saveDataProcessQueue.processingState$.pipe(
    //         filter(
    //           (state: NgPatProcessQueueState) =>
    //             state === NgPatProcessQueueState.PENDING
    //         ),
    //         debounceTime(2000)
    //       );
    //     })
    //   )
    //   .subscribe(() => {
    //     this.saveDataProcessQueue.next();
    //   });
  }

  // save(processQueue: DlcNoteProcessQueue) {
  //   console.log('save', processQueue);
  //   switch (processQueue.action) {
  //     case DlcNoteSaveAction.SAVE_NOTE_TITLE:
  //       this.saveDataProcessQueue.upsertOne(
  //         addProcessQueueIdNoteTitle(processQueue)
  //       );
  //       this.autoSaveTimer$.next(true);
  //       break;
  //
  //     case DlcNoteSaveAction.SAVE_USER_NOTE_VERSION:
  //       this.saveDataProcessQueue.upsertOne(
  //         addProcessQueueIdNoteVersion(processQueue)
  //       );
  //       this.autoSaveTimer$.next(true);
  //       break;
  //
  //     case DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION:
  //       this.saveDataProcessQueue.upsertOne(
  //         addProcessQueueIdTranscriptionVersion(processQueue)
  //       );
  //       this.autoSaveTimer$.next(true);
  //       break;
  //   }
  // }

  addTranscribedVersion = (update: string) => {
    return new Promise((resolve, reject) => {
      of(update)
        .pipe(
          concatLatestFrom(() =>
            this.store.select(selectNoteAddEditFirestoreDoc)
          )
        )
        .subscribe({
          error: (value: any) => {
            reject(value);
          },
          next: async ([text, storeDoc]: [string, GigaNoteDoc | null]) => {
            if (storeDoc) {
              this.store.dispatch(
                patchFirestoreDoc({
                  patch: {
                    transcribeState: GigaActionState.IN_PROGRESS
                  }
                })
              );

              const payload: GigaNoteTranscribeNoteVersionUpdate = {
                doc: storeDoc,
                text: text
              };

              const firebaseCallableFunction = this.firestore.httpsCallable(
                EC_HTTPS_CALLABLE.ADD_TRANSCRIBED_IMAGE_TEXT_VERSION
              );

              await firebaseCallableFunction(payload);
              resolve('success');
            } else {
              reject('storeDoc is null');
            }
          }
        });
    });
  };

  updateFirestoreDocPromise = async (update: RecursivePartial<GigaNoteDoc>) => {
    // const payload: GigaNoteDoc = merge(storeDoc, update);

    // await this.firestore.merge(payload.firestorePath, payload);
    //
    // this.store.dispatch(patchFirestoreDoc({patch: payload}));
    //
    // return payload;

    return new Promise((resolve, reject) => {
      of(update)
        .pipe(
          concatLatestFrom(() =>
            this.store.select(selectNoteAddEditFirestoreDoc)
          ),
          switchMap(
            ([update, doc]: [
              RecursivePartial<GigaNoteDoc>,
              GigaNoteDoc | null
            ]) => {
              if (doc) {
                // console.log('updateFirestoreDocPromise', update, doc);

                const _doc: GigaNoteDoc = {...doc, ...update};
                return this.firestore
                  .merge$<GigaNoteDoc>(_doc.firestorePath, _doc)
                  .pipe(map(() => _doc));
              }

              return of(update);
            }
          )
        )
        .subscribe({
          error: (value: any) => {
            reject(value);
          },
          next: (value: RecursivePartial<GigaNoteDoc>) => {
            // this.store.dispatch(patchFirestoreDoc({patch: value}));
            resolve(value);
          }
        });
    });
  };
}
