import {WriteBatch} from '@firebase/firestore';
import {
  BaseEntity,
  createQuestionsFromOpenAiResponse,
  firestoreQuizQuestionsByEntity,
  Question,
  Quiz
} from '@gigasoftware/shared/api';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {
  httpsCallable,
  HttpsCallable,
  HttpsCallableResult
} from 'firebase/functions';
import {Observable} from 'rxjs';

import {GenerateQuizResponse} from '../firebase';

export const addQuestionsToQuizWithAi$ = <T>(
  uid: string,
  aiPrompt: string,
  customFirestoreService: NgPatFirestoreService
) => {
  const openAiCompletionsFn: HttpsCallable<string, GenerateQuizResponse> =
    httpsCallable(
      customFirestoreService.functions,
      EC_HTTPS_CALLABLE.OPEN_AI_QUIZ_QUESTION_ASSISTANT
    );

  return (observable: Observable<Quiz>) =>
    new Observable<Quiz>(subscriber => {
      // this function will be called each time this
      // Observable is subscribed to.
      const subscription = observable.subscribe({
        complete() {
          subscriber.complete();
        },
        error(err) {
          // We need to make sure we're propagating our errors through.
          subscriber.error(err);
        },
        next(quiz: Quiz) {
          openAiCompletionsFn(aiPrompt)
            .then((result: HttpsCallableResult<GenerateQuizResponse>) => {
              try {
                const response = result.data;
                const quizQuestions = JSON.parse(response.value);

                // console.log(quizQuestions);

                const questions: Question[] = createQuestionsFromOpenAiResponse(
                  quiz,
                  quizQuestions.quiz
                );

                const batch: WriteBatch = customFirestoreService.writeBatch();

                questions.forEach((question: Question) => {
                  const docPath = `${firestoreQuizQuestionsByEntity(
                    quiz as BaseEntity,
                    uid
                  )}/${question.id}`;

                  batch.set(customFirestoreService.docRef(docPath), question);
                });

                batch.commit().then(() => {
                  subscriber.next(quiz);
                });
              } catch (e) {
                subscriber.error(e);
              }
            })
            .catch((error: any) => {
              subscriber.error(error);
            });
        }
      });

      // Return the finalization logic. This will be invoked when
      // the result errors, completes, or is unsubscribed.
      return () => {
        subscription.unsubscribe();
        // clean up
      };
    });
};
