import {Injectable} from '@angular/core';
import {
  BaseEntity,
  Classroom,
  combineQuestionsAndPath,
  DlcBtnActionState,
  firestoreQueryPathByEntity,
  QuestionWithPath,
  Quiz
} from '@gigasoftware/shared/api';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from '@ngrx/store';
import {delay, filter, map, switchMap, tap} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {DynamicStoreService} from '../custom-store/dynamic-store';
import {PLATFORM_DYN_STORE} from '../models/dyn-store';
import {QuizCreatorService} from './quiz-creator.service';
import {
  createQuizAndQuestionsWithParamsV2,
  createQuizWithParamsV2,
  createReviewQuizFromWrongAnswers,
  deleteQuizs,
  updateFirestorePartialQuiz,
  updateQuizActionState
} from './quiz.actions';
import {PartialQuizState} from './quiz.reducer';
import {QuizService} from './quiz.service';

@Injectable({
  providedIn: 'root'
})
export class QuizEffects {
  deleteDocs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteQuizs),
        concatLatestFrom(() => this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this.quizService.deleteQuizzesFirestore$(
                classRoom,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateFirestorePartialQuiz),
        concatLatestFrom(() => this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.quizService.updatePartialFirestore$(
            action.changes,
            action.quiz,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  createReviewQuizFromWrongAnswers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createReviewQuizFromWrongAnswers),
        concatLatestFrom(() => this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          const firestoreDestinationPath: string = firestoreQueryPathByEntity(
            action.payload.quiz as BaseEntity,
            <string>account.uid
          );

          const questions: QuestionWithPath[] = combineQuestionsAndPath(
            action.payload.questions,
            action.payload.quiz,
            <string>account.uid
          );

          return this.quizService
            .setQuizAndQuestions$(
              firestoreDestinationPath,
              action.payload.quiz,
              questions
            )
            .pipe(
              tap((q: Quiz) => {
                this.dynStore
                  .getStore(PLATFORM_DYN_STORE.QUIZ_CREATED, {
                    isEmitterStore: true
                  })
                  .dispatch(q);
              })
            );
        })
      ),
    {dispatch: false}
  );

  createQuizWithParamsV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createQuizWithParamsV2),
        switchMap(({params}) => {
          return this.quizCreatorService.createQuizWithParamsV2$(params);
        })
      ),
    {dispatch: false}
  );

  /**
   * @deprecated
   */
  createQuizAndQuestionsWithParamsV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createQuizAndQuestionsWithParamsV2),
        switchMap(action => {
          return this.quizCreatorService.createQuizAndQuestionsWithParamsV2$(
            action
          );
        })
      ),
    {dispatch: false}
  );

  // Quiz Save button action, flip back to idle after half a second
  updateQuizActionStateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateQuizActionState),
      filter(({state}) => state === DlcBtnActionState.DO_SAVE),
      delay(500),
      map(() => updateQuizActionState({state: DlcBtnActionState.IDLE}))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<PartialQuizState>,
    private quizService: QuizService,
    private dynStore: DynamicStoreService,
    private quizCreatorService: QuizCreatorService
  ) {}

  // createEntityParams$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(createQuizParams),
  //       concatLatestFrom(() => this.store.select(selectNgPatUserAccount)),
  //       switchMap(([{params}, {uid}]) => {
  //         if (uid) {
  //           // const params: CreateQuizParams = {
  //           //   ...action.params
  //           // };
  //           // params.isCollaborative =
  //           //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
  //           //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
  //           //     ? false
  //           //     : params.isCollaborative;
  //
  //           // const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
  //           // let entity: BaseEntity | CollaborativeEntity = action.params
  //           //   .isCollaborative
  //           //   ? createCollaborativeEntity(user, action.params)
  //           //   : createEntity(action.params);
  //           //
  //           // entity = {
  //           //   ...entity,
  //           //   ...action.params
  //           // };
  //
  //           // entity = {
  //           //   ...entity,
  //           //   ...params
  //           // };
  //
  //           const entity = createQuizBasedOnParams(params, uid);
  //
  //           return this.customFirestoreService.upsertDoc$(
  //             entity.firestoreDocumentPath,
  //             entity
  //           );
  //
  //           // return this.entityCreatorService.createEntity$(
  //           //   createQuizBasedOnParams(params, uid),
  //           //   params,
  //           //   uid
  //           // );
  //         }
  //
  //         return EMPTY;
  //       })
  //     ),
  //   {dispatch: false}
  // );
  //
  // createSubEntityParams$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(createSubQuizParams),
  //       concatLatestFrom(() => this.store.select(selectNgPatUserAccount)),
  //       switchMap(([{params}, {uid}]) => {
  //         if (uid) {
  //           // const params: CreateSubQuizParams = {
  //           //   ...action.params
  //           // };
  //
  //           // const baseParams: CreateSubQuizParams = {
  //           //   ...params,
  //           //   entityTypeValue:
  //           //     params.parentEntity.entityTypeValue ||
  //           //     params.parentEntity.entityType
  //           // };
  //
  //           // params.isCollaborative =
  //           //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
  //           //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
  //           //     ? false
  //           //     : params.isCollaborative;
  //
  //           // const user: UserIdWithRole = createOwnerUserRole(uid);
  //           //
  //           // let entity: BaseEntity | CollaborativeEntity =
  //           //   params.isCollaborative
  //           //     ? createCollaborativeEntity(user, params)
  //           //     : createEntity(params);
  //           //
  //           // entity = {
  //           //   ...entity,
  //           //   ...params
  //           // };
  //           //
  //           // return this.entityCreatorService.createSubEntity$(
  //           //   entity,
  //           //   baseParams,
  //           //   params,
  //           //   uid
  //           // );
  //
  //           const entity = createSubQuizBasedOnParams(params, uid);
  //
  //           return this.customFirestoreService.upsertDoc$(
  //             entity.firestoreDocumentPath,
  //             entity
  //           );
  //         }
  //
  //         return EMPTY;
  //       })
  //     ),
  //   {dispatch: false}
  // );
}
