import {formatDate} from '@angular/common';
import {inject, Injectable, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  GigaNoteDoc,
  GigaAssetState,
  GigaActionState,
  createQuizQuestionsFromNotesImage,
  GigaGCFunctionAction,
  CreateQuizAndQuestionsWithAIParams,
  CreateQuizParamsV2Uid,
  QUIZ_STATE,
  getQuizStateText,
  transcribeNoteImageCreateQuizQuestions
} from '@gigasoftware/shared/api';
import {
  NgPatFirestoreService,
  onSnapshotDoc
} from '@gigasoftware/shared/firebase';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {
  addFirestoreDoc,
  addNoteUuid,
  patchFirestoreDoc,
  updateFirestorePath,
  updateUploadConfig
} from '../note-add-edit.actions';
import {selectNoteAddEditFirestorePath} from '../note-add-edit.selectors';
import {DlcNoteBasePathImageConfig} from '../note.model';
import {getLastSegment, hasOddSegments} from './note.fns';
import {SaveAddEditNoteService} from './save-add-edit-note.service';

@Injectable({
  providedIn: 'root'
})
export class DlcNoteFirestoreService {
  private store: Store = inject(Store);
  private firestoreService: NgPatFirestoreService = inject(
    NgPatFirestoreService
  );
  private noteStore: SaveAddEditNoteService = inject(SaveAddEditNoteService);
  private firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  loadDocFromFirestore$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  defaultTitle = formatDate(new Date(), 'medium', 'en-US');

  constructor() {
    this.store
      .select(selectNoteAddEditFirestorePath)
      .pipe(
        distinctUntilChanged(),
        tap((path: string | null | undefined) => {
          // console.log(path);
        }),
        onSnapshotDoc<GigaNoteDoc>()
        // testDelay<string | null>(1000)
      )
      .subscribe((patch: GigaNoteDoc) => {
        if (patch) {
          // console.log(patch);
          this.store.dispatch(patchFirestoreDoc({patch}));
        }
      });
  }

  /**
   * TODO EFFECT
   *
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param config
   */
  setConfig({
    basePath,
    parentId,
    uploadImageConfig
  }: DlcNoteBasePathImageConfig): void {
    // If the base path has an odd number of segments,
    // then create a uuid for the document id.
    // else use the last segment as the document id.
    const lastSegment = getLastSegment(basePath);
    const createNewDocument = hasOddSegments(basePath);
    const uuid = createNewDocument ? uuidv4() : lastSegment;

    const docPath = createNewDocument ? `${basePath}/${uuid}` : basePath;

    // const imagePath = `${uploadImageConfig.baseImageDirectory}/${uuid}/${uuid}.png`;

    /**
     * Odd segments are new documents.
     */
    if (createNewDocument) {
      const newDoc = this.createDefaultDoc({
        firestorePath: docPath,
        id: uuid,
        parentId
      });

      // console.log(newDoc);

      this.store.dispatch(
        addFirestoreDoc({
          doc: {
            ...newDoc
          }
        })
      );
    }

    this.store.dispatch(
      updateFirestorePath({
        path: docPath
      })
    );

    this.store.dispatch(addNoteUuid({uuid}));

    this.store.dispatch(
      updateUploadConfig({
        uploadConfig: {
          ...JSON.parse(JSON.stringify(uploadImageConfig))
        }
      })
    );
  }

  createDefaultDoc(update: Partial<GigaNoteDoc>): GigaNoteDoc {
    return {
      firestorePath: '',
      id: '',
      imagePath: '', // image is not uploaded yet, therefore empty
      imageState: GigaAssetState.PENDING,
      parentId: 'personal',
      title: this.defaultTitle,
      transcribedTextId: '',
      transcribeState: GigaActionState.PENDING,
      userNoteId: '',
      ...update
    };
  }

  private actionDocPath$: Subject<string> = new Subject<string>();

  actionButtonState: Signal<QUIZ_STATE> = <Signal<QUIZ_STATE>>toSignal(
    this.actionDocPath$.pipe(
      this.firestoreService.onSnapshotDoc$<
        GigaGCFunctionAction<CreateQuizAndQuestionsWithAIParams>
      >(),
      map(
        (action: GigaGCFunctionAction<CreateQuizAndQuestionsWithAIParams>) => {
          return action.props.quizState as QUIZ_STATE;
        },
        startWith(QUIZ_STATE.ACTIVE)
      )
    )
  );

  /**
   * @param gigaNoteDoc
   * @param latestTranscriptionVersion
   * @param notificationDocPath
   * @param params
   */
  createQuizAndQuestionsFromTranscription = (
    gigaNoteDoc: GigaNoteDoc,
    latestTranscriptionVersion: string | null,
    notificationDocPath: string,
    params: Omit<CreateQuizParamsV2Uid, 'uid'>
  ): Promise<void> => {
    const uid = this.firestoreService.uid;

    const quizId = `${gigaNoteDoc.id}-quiz`;

    if (uid) {
      let action: GigaGCFunctionAction<CreateQuizAndQuestionsWithAIParams>;

      if (latestTranscriptionVersion) {
        action = createQuizQuestionsFromNotesImage(uuidv4(), {
          aiPrompt: latestTranscriptionVersion,
          gigaNoteDoc,
          noteImagePath: gigaNoteDoc.imagePath,
          notificationDocPath,
          params: {
            ...params,
            id: quizId,
            uid
          },
          quizState: QUIZ_STATE.CREATING
        });
      } else {
        action = transcribeNoteImageCreateQuizQuestions(uuidv4(), {
          aiPrompt: '',
          gigaNoteDoc,
          noteImagePath: gigaNoteDoc.imagePath,
          notificationDocPath,
          params: {
            ...params,
            id: quizId,
            uid
          },
          quizState: QUIZ_STATE.CREATING
        });
      }

      this.actionDocPath$.next(action.firestoreActionDocPath);

      return this.firestoreService.setDoc(
        action.firestoreActionDocPath,
        action
      );
    }

    return Promise.resolve();
  };
}
