import {InjectionToken} from '@angular/core';
import {
  AuthURLs,
  ENV_SERVER_ENUM,
  FirebaseConfig
} from '@gigasoftware/shared/api';

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');

export const ENV_SERVER: InjectionToken<ENV_SERVER_ENUM> =
  new InjectionToken<ENV_SERVER_ENUM>('ENV_SERVER');

export const EMULATOR_PORTS = {
  AUTH: 9099,
  FIRESTORE: 8080,
  FUNCTIONS: 5001,
  STORAGE: 9199
};

export interface AlgoliaSearchKeys {
  apiKey: string;
  appId: string;
  index: string;
}

export interface DeviceBuild {
  authDisplay: string;
  authHD: string;
  isMobileApp: boolean;
  marketingSite: string;
  marketingSiteSubscribe: string;
}

export interface IEnvironmentState {
  algolia: AlgoliaSearchKeys;
  authURLs: AuthURLs;
  defaultRemoteConfig: {
    trialDays: number;
    maxNumberQuizzesWhileInTrial: number;
  };
  deviceBuild: DeviceBuild;
  environment: string;
  firebase: FirebaseConfig;
  isIosOrAndroid: boolean;
  production: boolean;
  remoteConfigParams: {
    settings: {
      /**
       * Defines the maximum age in milliseconds of an entry in the config cache before
       * it is considered stale. Defaults to 43200000 (Twelve hours).
       */
      minimumFetchIntervalMillis: number;
      /**
       * Defines the maximum amount of milliseconds to wait for a response when fetching
       * configuration from the Remote Config server. Defaults to 60000 (One minute).
       */
      fetchTimeoutMillis: number;
    };
  };
  remoteConfigPollMillis: number;
  stripePublishableKey: string;
}

export interface LogoutRedirect {
  logoutRedirect: string[];
}

export const LOGOUT_REDIRECT = new InjectionToken('LOGOUT_REDIRECT');
