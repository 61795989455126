import {InjectionToken} from '@angular/core';

import {
  IAPProductAndIRegisterProduct,
  WebSubscriptionProducts
} from './in-app-purchase.model';

import IapticConfig = CdvPurchase.IapticConfig;

export interface IAPConfig {
  config: IapticConfig;
  iapticAppName: string;
  subscriptionProducts: IAPProductAndIRegisterProduct[];
  webSubscriptionProducts: WebSubscriptionProducts;
}

/**
 * Register IAP products
 */
export const IAP_CONFIG: InjectionToken<IAPConfig> =
  new InjectionToken<IAPConfig>('IAP_CONFIG');
