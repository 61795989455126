import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import {
  selectNoteAddEditProcessQueueBtnState,
  startNoteProcessQueue
} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';

import {DlcSaveButtonComponent} from '../../../button/dlc-save-button/dlc-save-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-save-note-btn'
  },
  imports: [CommonModule, DlcSaveButtonComponent],
  selector: 'dlc-save-note-btn',
  styleUrl: './dlc-save-note-btn.component.scss',
  templateUrl: './dlc-save-note-btn.component.html'
})
export class DlcSaveNoteBtnComponent {
  store = inject(Store);

  saveBtnState = this.store.selectSignal(selectNoteAddEditProcessQueueBtnState);
  startNoteProcessingQueue() {
    this.store.dispatch(startNoteProcessQueue());
  }
}
