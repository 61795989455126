export interface ResizeConfig {
  filenameExtension: string;
  filenameWithoutExtension: string;
  thumbSize: number;
}

export interface GSStorageAssetBlob {
  blob: Blob | null;
  filenameExtension: string;
  filenameWithoutExtension: string;
}

// 600x600,400x400,200x200,100x100,50x50

export enum FIREBASE_STORAGE_IMAGE_SIZE {
  FULL = 'full',
  THUMB_600 = '600x600',
  THUMB_400 = '400x400',
  THUMB_200 = '200x200',
  THUMB_100 = '100x100',
  THUMB_50 = '50x50'
}

export interface GsImageThumbails {
  imagePath50x50: string;
  imagePath100x100: string;
  imagePath200x200: string;
  imagePath400x400: string;
  imagePath600x600: string;
}

export const GS_INITIAL_THUMBNAIL_PATHS: GsImageThumbails = {
  imagePath50x50: '',
  imagePath100x100: '',
  imagePath200x200: '',
  imagePath400x400: '',
  imagePath600x600: ''
};

export enum GS_IMAGE_THUMBNAIL_PATH_PROPERTY {
  IMAGE_PATH_600 = 'imagePath600x600',
  IMAGE_PATH_400 = 'imagePath400x400',
  IMAGE_PATH_200 = 'imagePath200x200',
  IMAGE_PATH_100 = 'imagePath100x100',
  IMAGE_PATH_50 = 'imagePath50x50'
}

export const FIREBASE_STORAGE_IMAGE_SIZE_ARRAY: FIREBASE_STORAGE_IMAGE_SIZE[] =
  [
    FIREBASE_STORAGE_IMAGE_SIZE.FULL,
    FIREBASE_STORAGE_IMAGE_SIZE.THUMB_600,
    FIREBASE_STORAGE_IMAGE_SIZE.THUMB_400,
    FIREBASE_STORAGE_IMAGE_SIZE.THUMB_200,
    FIREBASE_STORAGE_IMAGE_SIZE.THUMB_100,
    FIREBASE_STORAGE_IMAGE_SIZE.THUMB_50
  ];

export const MAX_IMAGE_SIZES: number[] = [600, 400, 200, 100, 50];
