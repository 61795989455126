import {Injectable} from '@angular/core';
import {
  firestoreNoteVersionDoc,
  GigaNoteTranscribeNoteVersionUpdate,
  GigaUserNoteVersion
} from '@gigasoftware/shared/api';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {jsonPopulated} from '@gigasoftware/shared/rxjs';
import {Store} from '@ngrx/store';
import {HttpsCallableResult} from 'firebase/functions';
import {filter, map} from 'rxjs/operators';

import {updateLatestNoteVersion} from '../note-add-edit.actions';
import {selectNoteAddEditUserTextId} from '../note-add-edit.selectors';
import {DlcNoteUserTextId, DlcNoteUserTextIdNull} from '../note.model';
import {SaveAddEditNoteService} from './save-add-edit-note.service';

@Injectable({
  providedIn: 'root'
})
export class DlcNoteUserService {
  constructor(
    private store: Store,
    private noteStore: SaveAddEditNoteService,
    private firestore: NgPatFirestoreService
  ) {
    // GET LATEST VERSION OF USER NOTE
    this.store
      .select(selectNoteAddEditUserTextId)
      .pipe(
        filter(
          (doc: DlcNoteUserTextIdNull) =>
            (doc && doc.firestorePath && doc.userTextId.length > 0) as boolean
        ),
        map((doc: DlcNoteUserTextIdNull) => {
          return doc as DlcNoteUserTextId;
        }),
        map((doc: DlcNoteUserTextId) =>
          firestoreNoteVersionDoc(doc.firestorePath, doc.userTextId)
        ),
        this.firestore.onSnapshotDoc$<GigaUserNoteVersion | null | undefined>(),
        jsonPopulated<GigaUserNoteVersion>(),
        map((doc: GigaUserNoteVersion) => doc.text)
      )
      .subscribe((doc: string) => {
        // console.log(doc, 'user note');
        this.store.dispatch(updateLatestNoteVersion({latestNoteVersion: doc}));
      });

    // this.noteStore.saveDataProcessQueue.currentItem$
    //   .pipe(
    //     // tap(action => {
    //     //   console.log(action);
    //     // }),
    //     ofDlcNoteActionTypeWithFirestoreDoc(
    //       DlcNoteSaveAction.SAVE_USER_NOTE_VERSION
    //     ),
    //     concatLatestFrom(() => this.store.select(selectNoteAddEditFirestoreDoc))
    //   )
    //   .subscribe(
    //     async ([action, doc]: [DlcNoteProcessQueue, GigaNoteDoc | null]) => {
    //       console.log('currentItem$ dlc-note-user', action);
    //
    //       const _doc = doc
    //         ? {...doc, ...action.firestoreDoc}
    //         : action.firestoreDoc;
    //
    //       const payload = <GigaNoteTranscribeNoteVersionUpdate>{
    //         doc: _doc,
    //         text: action.userNoteVersion
    //       };
    //
    //       const result = await this.addUserNoteVersion(payload);
    //
    //       await this.noteStore.saveDataProcessQueue.next(action.id);
    //     }
    //   );
  }

  async addUserNoteVersion(
    payload: GigaNoteTranscribeNoteVersionUpdate
  ): Promise<HttpsCallableResult | null> {
    const firebaseCallableFunction = this.firestore.httpsCallable(
      EC_HTTPS_CALLABLE.ADD_USER_NOTES_VERSSION
    );

    return await firebaseCallableFunction(payload);
  }

  init() {
    // stub
  }

  onDestroy() {
    // this._onDestroy$.next(true);
    // this._onDestroy$.complete();
  }
}
