import {CommonModule} from '@angular/common';
import {
  Component,
  effect,
  inject,
  Input,
  OnDestroy,
  Signal,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {GigaNoteDoc} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {
  addTranscribedNoteVersion,
  addUserNoteVersion,
  clearAddEditNoteStore,
  DlcInputImageConfig,
  DlcNoteBasePathImageConfig,
  DlcNoteFirestoreService,
  DlcNoteSaveAction,
  DlcNoteUserService,
  DlcTranscribeNoteService,
  ofDlcNoteActionTypeWithFirestoreDoc,
  QuizCreatorComponentService,
  SaveAddEditNoteService,
  selectAddEditNoteTitle,
  selectNoteAddEditFirestoreDoc,
  selectNoteAddEditLatestNoteVersion,
  selectNoteAddEditLatestTransitionVersion,
  selectNoteAddEditUuid,
  selectResolvedImageConfig,
  selectTranscribeBtnState,
  updateNoteTitle
} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {DlcInputImageComponent} from '../../input/dlc-input-image/dlc-input-image.component';
import {SyncfusionRteComponent} from '../syncfusion-rte/syncfusion-rte.component';
import {DlcNoteQuizBtnComponent} from './dlc-note-quiz-btn/dlc-note-quiz-btn.component';
import {DlcTranscribeBtnComponent} from './dlc-transcribe-btn/dlc-transcribe-btn.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-add-note'
  },
  imports: [
    CommonModule,
    FormsModule,
    DlcInputImageComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    SyncfusionRteComponent,
    DlcTranscribeBtnComponent,
    DlcNoteQuizBtnComponent
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [QuizCreatorComponentService],
  selector: 'dlc-add-note',
  styleUrl: './dlc-add-note.component.scss',
  templateUrl: './dlc-add-note.component.html'
})
export class DlcAddNoteComponent implements OnDestroy {
  // Output

  // Inject
  firestore = inject(NgPatFirestoreService);
  hasImage = signal(false);
  noteFirestore: DlcNoteFirestoreService = inject(DlcNoteFirestoreService);
  noteStore: SaveAddEditNoteService = inject(SaveAddEditNoteService);
  store = inject(Store);
  userNote: DlcNoteUserService = inject(DlcNoteUserService);
  quizCreatorService: QuizCreatorComponentService = inject(
    QuizCreatorComponentService
  );

  gigaNoteDoc: Signal<GigaNoteDoc | null> = this.store.selectSignal(
    selectNoteAddEditFirestoreDoc
  );

  // TO CREATE QUIZ
  currentNoteUuid = this.store.selectSignal(selectNoteAddEditUuid);
  // noteImagePath: WritableSignal<string> = signal('');

  // END TO CREATE QUIZ

  resolvedImageConfig: Signal<DlcInputImageConfig | null> = <
    Signal<DlcInputImageConfig | null>
  >this.store.selectSignal(selectResolvedImageConfig);

  selectLatestNoteVersion = this.store.selectSignal(
    selectNoteAddEditLatestNoteVersion
  );

  selectLatestTranscriptionVersion = this.store.selectSignal(
    selectNoteAddEditLatestTransitionVersion
  );

  titleControl: FormControl = new FormControl(this.noteFirestore.defaultTitle, [
    Validators.minLength(3),
    Validators.required
  ]);

  /**
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param basePath
   */

  transcribeBtnState = this.store.selectSignal(selectTranscribeBtnState);

  transcribedNoteService: DlcTranscribeNoteService = inject(
    DlcTranscribeNoteService
  );

  updateUserNote$: Subject<string> = new Subject();
  updateTranscription$: Subject<string> = new Subject();

  constructor() {
    effect(() => {
      const uuid = this.currentNoteUuid();

      if (uuid) {
        this.quizCreatorService.setUUID(uuid);
      }
    });

    this.userNote.init();
    this.transcribedNoteService.init();

    this.titleControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((title: string | null | undefined) => {
        if (title && title.length) {
          this.quizCreatorService.patchState({name: title});
          this.store.dispatch(updateNoteTitle({title}));
        }
      });

    this.store
      .select(selectAddEditNoteTitle)
      .pipe(takeUntilDestroyed(), distinctUntilChanged())
      .subscribe((title: string | null) => {
        if (title && title.length > 0) {
          this.quizCreatorService.patchState({name: title});
          this.titleControl.setValue(title, {emitEvent: false});
        }
      });

    this.noteStore.saveDataProcessQueue.currentItem$
      .pipe(
        ofDlcNoteActionTypeWithFirestoreDoc(DlcNoteSaveAction.SAVE_NOTE_TITLE),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        this.titleControl.markAsPristine();
        this.titleControl.markAsUntouched();
      });

    this.updateUserNote$
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((evt: string) => {
        this.store.dispatch(addUserNoteVersion({newNote: evt}));
      });

    this.updateTranscription$
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((evt: string) => {
        this.store.dispatch(addTranscribedNoteVersion({newNote: evt}));
      });
  }

  @Input({required: true})
  set config(config: DlcNoteBasePathImageConfig | null | undefined) {
    if (
      config &&
      config.basePath &&
      config.basePath.length > 0 &&
      config.uploadImageConfig &&
      config.uploadImageConfig?.baseImageDirectory &&
      config.uploadImageConfig?.baseImageDirectory?.length > 0 &&
      config.parentId?.length > 0
    ) {
      this.noteFirestore.setConfig(config);
    }
  }

  clearTitleForm() {
    this.titleControl.setValue('');
    this.titleControl.setErrors({required: true});
  }

  /**
   * Called from parent component directly, instead of using @Input, to save the note.
   */
  async doTranscribeImage() {
    await this.transcribedNoteService.doTranscribeImage();
  }

  async doCreateQuiz() {
    const latestTranscriptionVersion = this.selectLatestTranscriptionVersion();
    const gigaNoteDoc = this.gigaNoteDoc();

    if (gigaNoteDoc) {
      this.quizCreatorService.patchState({
        description: 'Generated from transcribed note'
      });

      await this.noteFirestore.createQuizAndQuestionsFromTranscription(
        gigaNoteDoc,
        latestTranscriptionVersion,
        '',
        {
          ...this.quizCreatorService.state()
        }
      );
    }
  }

  ngOnDestroy() {
    // this.transcribedNoteService.onDestroy();
    // this.userNote.onDestroy();
    this.store.dispatch(clearAddEditNoteStore());
  }

  addUserNoteVersion(evt: string) {
    this.updateUserNote$.next(evt);
  }

  addTranscribedNoteVersion(evt: string) {
    this.updateTranscription$.next(evt);
  }
}
