import {createFeatureSelector, createSelector} from '@ngrx/store';

import {transformIapPriceToUiPrice} from '../normalized-price.fns';
import {NgPatNormalizedPrice} from '../normalized-price.model';
import {
  IAPProduct,
  IAPProductAndIRegisterProduct
} from './in-app-purchase.model';
import * as AppstoreInAppPurchaseReducer from './in-app-purchase.reducer';

export const selectAppstoreInAppPurchaseState =
  createFeatureSelector<AppstoreInAppPurchaseReducer.AppstoreInAppPurchaseState>(
    AppstoreInAppPurchaseReducer.appstoreInAppPurchaseFeatureKey
  );

const {selectAll} =
  AppstoreInAppPurchaseReducer.appstoreInAppPurchaseAdapter.getSelectors();

export const selectAllAppstoreInAppProducts = createSelector(
  selectAppstoreInAppPurchaseState,
  (
    state: AppstoreInAppPurchaseReducer.AppstoreInAppPurchaseState
  ): IAPProduct[] => {
    return selectAll(state);
  }
);

export const selectNgPatHasActiveMobileSubscription = createSelector(
  selectAllAppstoreInAppProducts,
  (subs: IAPProduct[]): boolean => {
    const hasOwned = subs.find((s: IAPProduct) => s.owned);

    return hasOwned !== undefined && hasOwned !== null;
  }
);

export const selectHardCodedSubscriptionProducts = createSelector(
  selectAppstoreInAppPurchaseState,
  (
    state: AppstoreInAppPurchaseReducer.AppstoreInAppPurchaseState
  ): IAPProductAndIRegisterProduct[] => {
    return state.hardCodedSubscriptionProducts;
  }
);

export const selectAllIapTransformedProducts = createSelector(
  selectAllAppstoreInAppProducts,
  selectHardCodedSubscriptionProducts,
  (
    products: IAPProduct[],
    hardCodedSubscriptionProducts: IAPProductAndIRegisterProduct[]
  ): NgPatNormalizedPrice[] => {
    // combine  hardCodedSubscriptionProducts features with products features
    const combinedProducts = products.map((product: IAPProduct) => {
      const hardCodedProduct = hardCodedSubscriptionProducts.find(
        (p: IAPProductAndIRegisterProduct) => p.id === product.id
      );

      if (hardCodedProduct) {
        return {...product, features: hardCodedProduct.features};
      }

      return product;
    });

    if (combinedProducts && combinedProducts.length > 0) {
      return transformIapPriceToUiPrice(combinedProducts);
    }

    return [];
  }
);
