import {BaseEntity, TimeStamp} from '@gigasoftware/shared/api';

export function getTimestampValue(
  timestamp: TimeStamp | null | undefined
): string {
  return timestamp && timestamp.nanoseconds
    ? timestamp.nanoseconds.toString(10)
    : Date.now().valueOf().toString(10);
}

export function getCreatedAtNanoseconds(
  p: BaseEntity | null | undefined
): string {
  return getTimestampValue(p?.createdAt || null);
}

/**
 *
 * @description
 * Create an object with a property and value from an object and id.
 *
 * @example
 * createParamsById({id: '123'}, 'quizId', 'id') => {quizId: '123'};
 *
 * @param obj the object to get the id from
 * @param prop the property to create on the returned object
 * @param id use 'id' by default, the id property of the object
 */
export function createParamById(
  obj: any,
  prop: string,
  id = 'id'
): {[key: string]: string} {
  if (!obj) {
    return {};
  }

  return {
    [prop]: (<any>obj)[id]
  };
}
