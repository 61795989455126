export interface DlcFormStatus {
  dirty: boolean;
  invalid: boolean;
  pristine: boolean;
  valid: boolean;
}

export enum APP {
  EC = 'ec'
}

export interface DlcHtmlAttribute {
  name: string;
  value: string;
}

export interface DlcHtml {
  attributes: DlcHtmlAttribute[];
  tag: string;
}
