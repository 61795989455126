import {Injectable} from '@angular/core';
import {
  BaseEntity,
  firestoreCourseCollection,
  firestoreUserCourseCollection
} from '@gigasoftware/shared/api';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';

import {NgPatAccountState} from '../+account/account.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {deleteCourses, updateCourses, upsertCourses} from './course.actions';
import {Course} from './course.model';
import {courseFeatureKey} from './course.reducer';

@Injectable({
  providedIn: 'root'
})
export class CourseService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Course>;
  private _queryPrivateService!: NgPatFirestoreCollectionQuery<Course>;

  connection: NgPatServiceConnector;
  connectionKey = courseFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  deleteDoc$(course: BaseEntity | undefined, uid: string): Observable<boolean> {
    if (course) {
      const path: string = course.isPrivate
        ? firestoreUserCourseCollection(uid)
        : firestoreCourseCollection();

      // const path = studyGroup.isPrivate ?
      return <any>this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(
    course: BaseEntity | undefined,
    ids: string[],
    uid: string
  ): Observable<boolean | any> {
    if (course) {
      const isPrivate = course.isPrivate;
      const path: string = isPrivate
        ? firestoreUserCourseCollection(uid)
        : firestoreCourseCollection();
      return <any>this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService = new NgPatFirestoreCollectionQuery<Course>(
      {
        deleteManyAction: (ids: string[]) => deleteCourses({ids}),
        queryMember: true,
        updateManyAction: (courses: Course[]) =>
          updateCourses({courses: aggregateUpdates(courses)}),
        upsertManyAction: (courses: Course[]) => upsertCourses({courses})
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateService = new NgPatFirestoreCollectionQuery<Course>(
      {
        deleteManyAction: (ids: string[]) => deleteCourses({ids}),
        queryMember: false,
        updateManyAction: (courses: Course[]) =>
          updateCourses({courses: aggregateUpdates(courses)}),
        upsertManyAction: (courses: Course[]) => upsertCourses({courses})
      },
      this.store,
      this.customFirestoreService
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreCourseCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateService.onConnect(
      firestoreUserCourseCollection(<string>user.uid),
      null,
      null
    );
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateService.onDisconnect();
  }
}
