import {Quiz} from '@gigasoftware/shared/api';

export interface MemberQuizMapValue {
  name: string; // quiz name
  quizID: string; // quiz id
  // result: number;
}

export type MemberQuizMap = Map<string, MemberQuizMapValue>;

export function memberQuizMap(quizzes: Quiz[]): MemberQuizMap {
  return quizzes.reduce((m: MemberQuizMap, q: Quiz) => {
    m.set(q.id, {name: q.name, quizID: q.id});
    return m;
  }, new Map());
}
