import {IAPProduct} from './+in_app_purchase/in-app-purchase.model';

export type DlcPriceIntervalValue = 'monthly' | 'annual';

export enum NgPatNormalizedPriceIntervalValueEnum {
  Monthly = 'month',
  Annually = 'year',
  Custom = 'custom'
}

/**
 * Used to display prices in the UI
 */
export interface NgPatNormalizedOffer {
  currencyCode: string;
  description: string;
  interval: NgPatNormalizedPriceIntervalValueEnum;
  isMostPopular: boolean;
  offerId: string;
  owned: boolean;
  platform: NgPatNormalizedPricePlatform;
  price: string;
  productId: string;
  title: string;
  // If is owned and has a subscription, this is the subscription id
  webPriceSubscriptionId?: string;
}

export enum NgPatNormalizedPricePlatform {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android'
}

/**
 * Used to display prices in the UI
 */
export interface NgPatNormalizedPrice {
  customPriceId?: string;
  /** see https://angular.dev/api/common/formatCurrency  */
  // currencyCode: string;
  // isMostPopular: boolean;
  features: string[];
  isCustomPrice?: boolean;
  // owned: boolean;
  paymentPlatform: NgPatNormalizedPricePlatform;
  prices: NgPatNormalizedOffer[];

  title: string;
}

export interface AggregatedPriceByGroup {
  [group: string]: IAPProduct[];
}
