import {
  CreateBaseSubEntityParams,
  CreateEntityParams
} from '@gigasoftware/shared/api';
import {createAction, props} from '@ngrx/store';

export const createEntityParams = createAction(
  '[BaseEntity/API] Create BaseEntity By Type Value',
  props<{params: CreateEntityParams}>()
);

export const createSubEntityParams = createAction(
  '[BaseEntity/API] Create Sub BaseEntity By Type Value',
  props<{params: CreateBaseSubEntityParams}>()
);
