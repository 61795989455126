import {createFirestoreAction, GigaGCFunctionActionType} from '../api-actions';
import {
  firestoreActionsNotesCollection,
  firestoreActionsQuizzesCollection
} from '../database-paths/actions-quizzes';
import {GigaNoteDoc} from '../firestore';
import {CreateQuizAndQuestionsWithAIParams, QUIZ_STATE} from './quiz.model';

/**
 * Based on notes
 */
export const createQuizQuestionsFromNotesImage =
  createFirestoreAction<CreateQuizAndQuestionsWithAIParams>(
    GigaGCFunctionActionType.CREATE_QUIZ_QUESTIONS_FROM_NOTE_IMAGE,
    firestoreActionsNotesCollection
  );

export const transcribeNoteImageCreateQuizQuestions =
  createFirestoreAction<CreateQuizAndQuestionsWithAIParams>(
    GigaGCFunctionActionType.TRANSCRIBE_IMAGE_CREATE_QUIZ_QUESTIONS,
    firestoreActionsNotesCollection
  );

/**
 * Based on Quiz
 */
export const transcribeQuizImageCreateQuizQuestions =
  createFirestoreAction<CreateQuizAndQuestionsWithAIParams>(
    GigaGCFunctionActionType.TRANSCRIBE_QUIZ_IMAGE_CREATE_QUIZ_QUESTIONS,
    firestoreActionsQuizzesCollection
  );

/**
 * Example of a new quiz question from an image
 */
export const newQuizQuestionsFromImage = createQuizQuestionsFromNotesImage(
  'f123',
  {
    aiPrompt: 'What is the capital of France?',
    gigaNoteDoc: {} as GigaNoteDoc,
    noteImagePath: 'path/to/image',
    notificationDocPath: 'path/to/notification',
    params: {
      aggregateFalseAnswers: true,
      assigned: false,
      description: 'A quiz about France',
      entityType: 1,
      entityTypeValue: 1,
      gradeLevel: 1,
      id: 'f123',
      imagePath: 'https://path/to/image',
      isCollaborative: false,
      isPrivate: false,
      name: 'France Quiz',
      subjects: 'Geography',
      uid: 'u123'
    },
    quizState: QUIZ_STATE.CREATING
  }
);
