import {GigaNoteDoc} from '@gigasoftware/shared/api';
import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

export const addNote = createAction(
  '[Note/API] Add Note',
  props<{note: GigaNoteDoc}>()
);

export const setNote = createAction(
  '[Note/API] Set Note',
  props<{note: GigaNoteDoc}>()
);

export const upsertNote = createAction(
  '[Note/API] Upsert Note',
  props<{note: GigaNoteDoc}>()
);

export const addNotes = createAction(
  '[Note/API] Add Notes',
  props<{notes: GigaNoteDoc[]}>()
);

export const upsertNotes = createAction(
  '[Note/API] Upsert Notes',
  props<{notes: GigaNoteDoc[]}>()
);

export const updateNote = createAction(
  '[Note/API] Update Note',
  props<{note: Update<GigaNoteDoc>}>()
);

export const updateNotes = createAction(
  '[Note/API] Update Notes',
  props<{notes: Update<GigaNoteDoc>[]}>()
);

export const mapNote = createAction(
  '[Note/API] Map Note',
  props<{entityMap: EntityMapOne<GigaNoteDoc>}>()
);

export const mapNotes = createAction(
  '[Note/API] Map Notes',
  props<{entityMap: EntityMap<GigaNoteDoc>}>()
);

export const deleteNote = createAction(
  '[Note/API] Delete Note',
  props<{id: string}>()
);

export const deleteNoteFromfirestore = createAction(
  '[eNote/API] Delete eNote From Firestore',
  props<{id: string}>()
);

export const deleteNotes = createAction(
  '[Note/API] Delete Notes',
  props<{ids: string[]}>()
);

export const loadNotes = createAction(
  '[Note/API] Load Notes',
  props<{notes: GigaNoteDoc[]}>()
);

export const setNotes = createAction(
  '[Note/API] Set Notes',
  props<{notes: GigaNoteDoc[]}>()
);

export const clearNotes = createAction('[Note/API] Clear Notes');

export const selectNoteID = createAction(
  '[Note/API] Select Note',
  props<{id: string}>()
);

export const updateFirestorePartialNote = createAction(
  '[Note/API] Save Partial Note',
  props<{changes: Partial<GigaNoteDoc>; note: GigaNoteDoc}>()
);

export const clearSelectedNote = createAction('[Note/API] Clear Selected Note');
