import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  model,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {getQuizStateText, QUIZ_STATE} from '@gigasoftware/shared/api';
import {Store} from '@ngrx/store';

import {DlcRoundedTextIconButtonComponent} from '../../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  selector: 'dlc-note-quiz-btn',
  styleUrl: './dlc-note-quiz-btn.component.scss',
  templateUrl: './dlc-note-quiz-btn.component.html'
})
export class DlcNoteQuizBtnComponent {
  private store = inject(Store);

  btnState = model(QUIZ_STATE.ACTIVE);

  btnText = computed(() => {
    console.log('btnState', this.btnState());
    return getQuizStateText(this.btnState());
  });

  doCreateQuiz: OutputEmitterRef<boolean> = output();

  isDisabled = computed(() => {
    const btnState = this.btnState();
    return btnState ? btnState !== QUIZ_STATE.ACTIVE : false;
  });

  isActive = computed(() => {
    const btnState = this.btnState();
    return btnState ? btnState === QUIZ_STATE.ACTIVE : true;
  });

  onGenerateQuiz() {
    this.doCreateQuiz.emit(true);
    this.btnState.update(() => QUIZ_STATE.CREATING);
  }
}
