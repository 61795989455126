import {cloneDeep, isPlainObject} from 'es-toolkit';
import {get} from 'es-toolkit/compat';
import {DocumentSnapshot} from 'firebase/firestore';

import {NgPatTimeStamp} from "../evolving-cognition/firestore";

export type RemoveCtorTimeStampFn<T> = (data: any) => T;

function removeCtorTimeStamp<T>(_data: T): T;
function removeCtorTimeStamp(_data: any): any {
    if (_data && _data['createdAt']) {
        const createdAt: NgPatTimeStamp = {
            nanoseconds: _data.createdAt
                ? parseInt(_data.createdAt.nanoseconds, 10)
                : 0,
            seconds: _data.createdAt ? parseInt(_data.createdAt.seconds, 10) : 0
        };

        delete _data.createdAt;
        _data.createdAt = createdAt;
    }

    if (_data && _data['updatedAt']) {
        const updatedAt = <NgPatTimeStamp>{
            nanoseconds: _data.updatedAt
                ? parseInt(_data.updatedAt.nanoseconds, 10)
                : 0,
            seconds: _data.updatedAt ? parseInt(_data.updatedAt.seconds) : 0
        };

        delete _data.updatedAt;
        _data.updatedAt = updatedAt;
    }

    return _data;
}

function recurseDataObject<T>(
    data: any | null,
    removeCtorFn: RemoveCtorTimeStampFn<T>,
    recurseFn: (...args: any[]) => T
): T {
    if (data) {
        const keys: string[] = Object.keys(data);

        for (let index = 0; index < keys.length; index++) {
            if (isPlainObject(data[keys[index]])) {
                data[keys[index]] = removeCtorFn(data[keys[index]]);
            } else if (Array.isArray(keys[index])) {
                for (let arrIndex = 0; arrIndex < keys[index].length; arrIndex++) {
                    data[keys[index]][arrIndex] = recurseFn(
                        keys[index],
                        removeCtorFn,
                        recurseFn
                    );
                }
            }
        }
    }

    return removeCtorFn(data);
}

export function removeTimeStampCTorFromData<T>(_data: any): T {
    return cloneDeep<T>(
        recurseDataObject(_data, removeCtorTimeStamp, recurseDataObject) as unknown as T
    );
}

export function removeTimestampCTorFromDocumentSnapshot<T>(
    snap: DocumentSnapshot
): T {
    return removeTimeStampCTorFromData(snap.data());
}

export function getUpdatedAtSeconds(data: any) {
    return get(data, 'updatedAt.seconds', null);
}
