import {
  FIREBASE_STORAGE_IMAGE_SIZE,
  FIREBASE_STORAGE_IMAGE_SIZE_ARRAY,
  MAX_IMAGE_SIZES,
  ResizeConfig
} from '@gigasoftware/shared/api';

export interface FileNameParts {
  ext: string;
  name: string;
}

/**
 * Input the file name as [filename].[extension] and return
 * the file name and extention as an object as
 *
 * {
 *   name: [filename],
 *   ext: [extension]
 * }
 *
 * with the interface FileNameParts.
 */
export function getFileNameParts(fileName: string): FileNameParts {
  if (!fileName.includes('.')) {
    return {
      ext: '',
      name: fileName
    };
  }

  const [...parts] = fileName.split('.');

  const ext = <string>parts.pop();
  const name = parts.join('.');

  return {
    ext,
    name
  };
}

export function getFirebaseResizedImageThumbPaths(
  fileNameWithoutExtension: string,
  thumbSize: FIREBASE_STORAGE_IMAGE_SIZE | string
): string {
  return `${fileNameWithoutExtension}_${thumbSize}`;
}

/**
 * Create the filename without extension from the image name and the thumb size
 * @param fileNameWithoutExtension - name of the file without extension
 * @param fileNameExtension - extension of the file
 * @param maxImageSizes - array of max image size numbers
 */
export function getFilenamesWithSize(
  fileNameWithoutExtension: string,
  fileNameExtension: string,
  maxImageSizes: number[] = MAX_IMAGE_SIZES
): ResizeConfig[] {
  return maxImageSizes.map((thumbSize: number) => {
    return {
      filenameExtension: fileNameExtension,
      filenameWithoutExtension: getFirebaseResizedImageThumbPaths(
        fileNameWithoutExtension,
        `${thumbSize}x${thumbSize}`
      ),
      thumbSize: thumbSize
    };
  });
}

/**
 *
 * @deprecated
 * Create the path from the image url and the thumb size
 *
 * Combinds the image path with the filename and thumg size to get the resized image.
 * Patthen is `imagePath/thumbs/filename_thumbSize.jpg`
 *
 * @param url - path/to/filename.png
 * @param thumbSize - 100x100
 * @param thumbPath - path of the thumbnails
 */
export function getFirebaseResizedImageThumbPath_deprecated(
  url: string,
  thumbSize: FIREBASE_STORAGE_IMAGE_SIZE | string,
  thumbPath: string | null = null
): string {
  if (url.includes('/') && url.includes('.')) {
    const [...parts] = url.split('/');

    // get the filename
    const fileName: string = <string>parts.pop();

    // Split the file name by "."
    const [...segments] = fileName.split('.');

    // get the file extension
    const fileExtension = segments.pop();

    // get the name of the file without extension
    const fileNameWithoutExtension = segments.join('.');

    const thumbPathToUse = thumbPath ? `${thumbPath}/` : '';

    return `${parts.join(
      '/'
    )}/${thumbPathToUse}/${fileNameWithoutExtension}_${thumbSize}.${fileExtension}`;
  }

  return '';
}

/**
 * @deprecated
 * Get All image paths from firebase storage
 */
export function getFirebaseResizedImagePaths_deprecated(url: string): string[] {
  return FIREBASE_STORAGE_IMAGE_SIZE_ARRAY.map(
    (thumbSize: FIREBASE_STORAGE_IMAGE_SIZE) => {
      if (thumbSize === FIREBASE_STORAGE_IMAGE_SIZE.FULL) {
        return url;
      }

      return getFirebaseResizedImageThumbPath_deprecated(url, thumbSize);
    }
  );
}
