import {URL_PROPS} from '@gigasoftware/shared/api';

import {NavItemDict} from '../../../+ui/ui.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictResearch: NavItemDict = {
  [NAV.MOBILE_RESEARCH_ALL_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_RESEARCH_ALL_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'All Grades',
    navigateTo: ['mobile', 'research-all-grades'],
    paramKeys: [URL_PROPS.RESEARCH_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_ITEM_PAGE,
    url: '/mobile/research-all-grades'
  },
  [NAV.MOBILE_RESEARCH_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_RESEARCH_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Research',
    navigateTo: ['mobile', 'research-create'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_RESEARCH_LIST_PAGE,
    url: '/mobile/research-create'
  },
  [NAV.MOBILE_RESEARCH_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_RESEARCH_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit',
    navigateTo: ['mobile', 'research-edit'],
    paramKeys: [URL_PROPS.RESEARCH_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_LIST_PAGE,
    url: '/mobile/research-edit'
  },
  [NAV.MOBILE_RESEARCH_ITEM_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_RESEARCH_ITEM_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Research',
    navigateTo: ['mobile', 'research-item'],
    paramKeys: [URL_PROPS.RESEARCH_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_LIST_PAGE,
    url: '/mobile/research-item'
  },
  [NAV.MOBILE_RESEARCH_LIST_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_RESEARCH_LIST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Research',
    navigateTo: ['mobile', 'research-list'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/research-list'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_RESEARCH_QUIZ_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Add Quiz',
    navigateTo: ['mobile', 'research-quiz-create'],
    paramKeys: [URL_PROPS.RESEARCH_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_ITEM_PAGE,
    url: '/mobile/research-quiz-create'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_RESEARCH_QUIZ_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Quiz',
    navigateTo: ['mobile', 'research-quiz-edit'],
    paramKeys: [URL_PROPS.RESEARCH_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_STATS_PAGE,
    url: '/mobile/research-quiz-edit'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_RESEARCH_QUIZ_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Grades',
    navigateTo: ['mobile', 'research-quiz-grades'],
    paramKeys: [URL_PROPS.RESEARCH_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_STATS_PAGE,
    url: '/mobile/research-quiz-grades'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_QUESTION_CREATE_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_RESEARCH_QUIZ_QUESTION_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Question',
    navigateTo: ['mobile', 'research-quiz-question-create'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_EDIT_PAGE,
    url: '/mobile/research-quiz-question-create'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_QUESTION_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_RESEARCH_QUIZ_QUESTION_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Question',
    navigateTo: ['mobile', 'research-quiz-question-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_EDIT_PAGE,
    url: '/mobile/research-quiz-question-edit'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_REVIEW_PAGE]: {
    icon: 'read_more',
    id: NAV.MOBILE_RESEARCH_QUIZ_REVIEW_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Review',
    navigateTo: ['mobile', 'research-quiz-review'],
    paramKeys: [
      URL_PROPS.RESEARCH_ID,
      URL_PROPS.QUIZ_ID,
      URL_PROPS.QUIZ_REVIEW_ID
    ],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_GRADES_PAGE,
    url: '/mobile/research-quiz-review'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_STATS_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_RESEARCH_QUIZ_STATS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'research-quiz-stats'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.RESEARCH_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_ITEM_PAGE,
    url: '/mobile/research-quiz-stats'
  },
  [NAV.MOBILE_RESEARCH_QUIZ_TEST_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_RESEARCH_QUIZ_TEST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'research-quiz-item-test'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.RESEARCH_ID],
    previousNavItemID: NAV.MOBILE_RESEARCH_QUIZ_STATS_PAGE,
    url: '/mobile/research-quiz-item-test'
  }
};
