import {Injectable} from '@angular/core';
import {
  firestoreSubjectDoc,
  firestoreSubjectsCollection
} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {Update} from '@ngrx/entity';
import {collection, onSnapshot, query} from 'firebase/firestore';
import {Observable} from 'rxjs';

import {NgPatEntityStore} from '../../custom-store/entity-store/ng-pat-entity-store';
import {EcFirestoreSubject} from '../../models/subjects.model';

@Injectable()
export class SearchFirestoreSubjectsService {
  subjectsCollectionPath = firestoreSubjectsCollection();

  subjectCollection = new NgPatEntityStore<EcFirestoreSubject>();
  subjects$: Observable<EcFirestoreSubject[]> =
    this.subjectCollection.selectAll$;

  unsubscribe: () => void = () => {
    return true;
  };

  constructor(private customerFirestoreService: NgPatFirestoreService) {
    this.listenToSubjectsCollection();
  }

  listenToSubjectsCollection(): void {
    const q = query(
      collection(this.customerFirestoreService.db, this.subjectsCollectionPath)
    );

    this.unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        const doc = change.doc.data() as EcFirestoreSubject;
        if (change.type === 'added') {
          // console.log("New city: ", change.doc.data());
          this.subjectCollection.addOne(doc);
        }
        if (change.type === 'modified') {
          // console.log("Modified city: ", change.doc.data());

          this.subjectCollection.updateOne(<Update<EcFirestoreSubject>>{
            changes: doc,
            id: doc.id
          });
        }
        if (change.type === 'removed') {
          // console.log("Removed city: ", change.doc.data());
          this.subjectCollection.removeOne(doc.id);
        }
      });
    });
  }

  deleteSubject(subject: EcFirestoreSubject): void {
    this.customerFirestoreService
      .deleteDoc(firestoreSubjectDoc(subject.id))
      .then(() => {
        // console.log('Document successfully deleted!', subject.id, subject.name);
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }
}
