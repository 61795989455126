import {BASE_COLLECTION_NAME} from '../base-collection-path';

export function firestoreUserResearchCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.RESEARCH}`;
}

export function firestoreUserResearchDoc(
  uid: string,
  researchID: string
): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.RESEARCH}/${researchID}`;
}

export function firestoreResearchCollection() {
  return `${BASE_COLLECTION_NAME.RESEARCH}`;
}
export function firestoreResearchDoc(researchID: string): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}`;
}
export function firestoreResearchChatTopicsCollection(
  researchID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreResearchChatTopicsDoc(
  researchID: string,
  chatTopicID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreResearchByIDQuizzesCollection(
  researchID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
