import {v4 as uuid} from 'uuid';

import {CopyAssetToPathParams} from '../../asset-paths';
import {
  firestoreEntityPathByParams,
  firestoreEntitySubPath,
  firestorePublishedQuizQuestionDoc,
  firestoreQuizQuestionByEntityAndQuestionId
} from '../database-paths';
import {ReferenceLink} from '../dlc-input-link.model';
import {createCollaborativeEntity, createEntity} from '../entity.fns';
import {
  BaseEntity,
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
} from '../entity.model';
import {createOwnerUserRole} from '../user.fns';
import {
  Answer,
  CreateQuizParams,
  CreateQuizParamsV2Uid,
  CreateReviewQuizFromWrongAnswers,
  CreateSubQuizParams,
  Question,
  QUESTION_TYPE,
  QuestionWithAnswer,
  QuestionWithPath,
  Quiz,
  QUIZ_STATE,
  QuizGrade,
  TakeQuizResult
} from './quiz.model';
import {
  OpenAiMultipleChoiceQuestion,
  OpenAiQuestion,
  OpenAiTrueFalseChoiceAnswer,
  OpenAiTrueFalseQuestion
} from './quiz.openai';

export const quizFns = 'quizFns';

export function createQuestion(quiz: Quiz, questionType: number): Question {
  return {
    answers: {},
    id: uuid(),
    imagePath: null,
    placeholder: 'Enter Question',
    question: '',
    questionType,
    quizID: quiz.id,
    referenceLink: '',
    referenceLinks: []
  };
}

export function createTruFalseQuestion(
  quiz: Quiz,
  questionType: number
): Question {
  const q: Question = {
    ...createQuestion(quiz, questionType),
    answers: {}
  };

  const trueAnswer: Answer = {
    answerText: 'Do Not Display',
    id: uuid(),
    imagePath: null,
    isCorrect: false,
    trueFalseAnswer: true
  };

  q.answers[trueAnswer.id] = trueAnswer;

  return q;
}

export function selectCorrectAnswer<T>(
  question: Question
): Answer | null | undefined {
  return Object.keys(question.answers).reduce(
    (a: Answer | null | undefined, key: string) => {
      if (!a && question.answers[key] && question.answers[key].isCorrect) {
        return question.answers[key];
      }
      return a;
    },
    null
  );
}

export function selectTrueFalseAnswer(
  question: Question
): Answer | null | undefined {
  return Object.values(question.answers)[0];
}

export function createMultichoiceAnswer(): Answer {
  return {
    answerText: '',
    id: uuid(),
    imagePath: null,
    isCorrect: false,
    trueFalseAnswer: false
  };
}

export function createInitialQuizResult(): TakeQuizResult {
  return {
    createdByUID: '',
    id: '',
    isPrivate: true,
    questions: {},
    quiz: <Quiz>createEntity({
      description: '',
      entityType: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
      entityTypeValue: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
      id: '',
      isCollaborative: true,
      isPrivate: true,
      name: ''
    }),
    quizID: '',
    timestamp: 0
  };
}

export function createTestByQuiz(
  quiz: Quiz,
  questions: Question[],
  uid: string
): TakeQuizResult {
  const _questions = questions.reduce(
    (dict: {[key: string]: QuestionWithAnswer}, question: Question) => {
      dict[question.id] = {
        answers: null,
        isAnswered: false,
        isCorrect: null,
        question,
        questionID: question.id,
        timeToAnswerMS: 0
      };

      return dict;
    },
    {}
  );

  return {
    createdByUID: uid,
    id: uuid(),
    isPrivate: true,
    questions: _questions,
    quiz,
    quizID: quiz.id,
    timestamp: Date.now().valueOf()
  };
}

export function createQuizByTest(
  r: TakeQuizResult
): CreateReviewQuizFromWrongAnswers {
  const quiz: Quiz = {
    ...r.quiz,
    id: uuid(),
    name: `Quiz Review: ${r.quiz.name}`
  };

  const questionsWithWrongAnswer: Question[] = updateQuestionsQuizId(
    Object.values(r.questions)
      .filter((q: QuestionWithAnswer) => !q.isAnswered || !q.isCorrect)
      .map((q: QuestionWithAnswer) => q.question),
    quiz
  );

  return {
    questions: questionsWithWrongAnswer,
    quiz
  };
}

export function calculateGrade(
  totalCorrect: number | undefined,
  totalAnswers: number | undefined
): number {
  if (
    totalCorrect !== undefined &&
    totalAnswers !== undefined &&
    totalAnswers > 0
  ) {
    return (totalCorrect / totalAnswers) * 100;
  }
  return 0;
}

export function createGradeChartData(
  r: TakeQuizResult
): QuizGrade<TakeQuizResult> {
  const allAnswers: QuestionWithAnswer[] = Object.values(r.questions);

  const totalAnswers = allAnswers.length;
  const totalCorrect = allAnswers.filter(
    (a: QuestionWithAnswer) => a.isCorrect
  ).length;
  const grade = calculateGrade(totalCorrect, totalAnswers);
  const progress = parseInt(grade.toFixed(0));
  const totalTimeToTakeQuizMS = allAnswers.reduce(
    (a: number, q: QuestionWithAnswer) => a + q.timeToAnswerMS,
    0
  );

  return {
    chartData: {
      max: 100,
      min: 0,
      progress,
      status: progress < 70 ? 'error' : progress === 100 ? 'success' : 'warn',
      units: '%'
    },
    grade,
    id: r.id,
    name: r.quiz.name,
    rawData: r,
    result: r,
    timestamp: r.timestamp,
    totalCorrect,
    totalTimeToTakeQuizMS,
    totalWrong: totalAnswers - totalCorrect
  };
}

export function updateQuestionsQuizId(questions: Question[], quiz: Quiz) {
  return questions.map((q: Question) => ({...q, quizID: quiz.id}));
}

/**
 * Will return old and new path to copy assets in firebase storage
 * @param questions
 * @param quiz
 */
export function getImagePathsFromQuizAndQuestions(
  questions: (Quiz | Question)[]
): CopyAssetToPathParams[] {
  return questions
    .filter((q: Quiz | Question) => {
      return q.imagePath !== null && q.imagePath !== undefined;
    })
    .map((q: Quiz | Question) => {
      const oldBasePath = (<string>q.imagePath)
        .split('/')
        ?.slice(0, -1)
        ?.join('/');

      const oldID = (<string>q.imagePath)
        .split('/')
        ?.pop()
        ?.split('.')
        ?.shift();
      const replace = new RegExp(<string>oldID, 'g');
      const newImagePath = (<string>q.imagePath).replace(replace, q.id);
      const newBasePath = newImagePath.replace(<string>oldID, q.id);

      return <CopyAssetToPathParams>{
        newAssetImagePath: newImagePath,
        newBasePath,
        newID: q.id,
        oldAssetImagePath: <string>q.imagePath,
        oldBasePath,
        oldID: <string>oldID
      };
    });
}

/**
 * return imagePaths from array of Objects with property imagePath:
 * @param p - { imagePath: string | null | undefined }[]
 */
export function getImagePaths(
  p: {
    imagePath: string | null | undefined;
  }[]
): string[] {
  return p
    .filter((p: {imagePath: string | null | undefined}) => {
      return p.imagePath !== null && p.imagePath !== undefined;
    })
    .map((p: {imagePath: string | null | undefined}) => {
      return <string>p.imagePath;
    });
}

/**
 * Update imagePath with id of quiz or question
 * @param p - BaseEntity
 */
export function updateImagePathWithID<T>(p: {
  id: string;
  imagePath: string | null | undefined;
}): T {
  if (p.imagePath !== null && p.imagePath !== undefined) {
    const oldID = (<string>p.imagePath).split('/')?.pop()?.split('.')?.shift();

    const replace = new RegExp(<string>oldID, 'g');

    return <T>{
      ...p,
      imagePath: p.imagePath.replace(replace, p.id)
    };
  }

  return <T>{
    ...p
  };
}

/**
 * Update imagePath with id of quiz or question
 * @param entities - BaseEntity[]
 */
export function updateImagePathsWithID(entities: BaseEntity[]) {
  return entities.map(updateImagePathWithID);
}

export function combineQuestionsAndPath(
  questions: Question[],
  quiz: Quiz,
  uid: string
): QuestionWithPath[] {
  return questions.map((question: Question) => {
    return <QuestionWithPath>{
      path: firestoreQuizQuestionByEntityAndQuestionId(
        quiz as BaseEntity,
        question.id,
        uid
      ),
      question
    };
  });
}

export function combinePublishedQuestionsAndPath(
  questions: Question[],
  quizID: string
): QuestionWithPath[] {
  return questions.map((question: Question) => {
    return <QuestionWithPath>{
      path: firestorePublishedQuizQuestionDoc(quizID, question.id),
      question
    };
  });
}

export function convertReferenceLinktStringToReferenceLink(
  link: ReferenceLink | string
): ReferenceLink {
  if (typeof link === 'string') {
    return {
      label: link,
      url: link
    };
  }

  return link;
}

export function getReferenceLinksFromQuestion(
  question: Question
): ReferenceLink[] {
  const links: ReferenceLink[] = [];

  if (
    question.referenceLinks !== null &&
    question.referenceLinks !== undefined
  ) {
    question.referenceLinks
      .filter((link: string | ReferenceLink | null | undefined) => {
        return link !== null && link !== undefined;
      })
      .map(convertReferenceLinktStringToReferenceLink)
      .forEach((link: ReferenceLink) => links.push(link));
  }

  if (question.referenceLink !== null && question.referenceLink !== undefined) {
    links.push(
      convertReferenceLinktStringToReferenceLink(question.referenceLink)
    );
  }

  return links;
}

export function updateQuestionLinks(question: Question) {
  question.referenceLinks = getReferenceLinksFromQuestion(question);
  delete question.referenceLink;
  return {
    ...question
  };
}

export function convertQuestionLinkToLinksArray(questionsWithAnswerDict: {
  [questionID: string]: QuestionWithAnswer;
}): {
  [questionID: string]: QuestionWithAnswer;
} {
  // Rebuild dictionary
  return Object.keys(questionsWithAnswerDict).reduce(
    (a: {[questionID: string]: QuestionWithAnswer}, questionID: string) => {
      a[questionID] = {
        ...questionsWithAnswerDict[questionID],
        question: updateQuestionLinks(
          questionsWithAnswerDict[questionID].question
        )
      };

      return a;
    },
    {}
  );
}

export function getQuizByParentEntityIdOrAllQuizzes(
  quizzes: Quiz[],
  personalQuizzes: Quiz[],
  parentEntityID: string | null | undefined
): Quiz[] {
  if (parentEntityID) {
    return quizzes.filter(
      (q: Quiz | undefined) =>
        q !== undefined && q.parentEntityID === parentEntityID
    );
  }

  return personalQuizzes;
}

export function isTrueFalseQuestion(
  question: Question | null | undefined
): boolean {
  return question ? question.questionType === QUESTION_TYPE.TRUE_FALSE : false;
}

export function isMultipleChoiceQuestion(
  question: Question | null | undefined
): boolean {
  return question
    ? question.questionType === QUESTION_TYPE.MULTIPLE_CHOICE
    : false;
}

// See libs/store/src/lib/+quizzes/quiz.effects.ts:110
export const createQuizBasedOnParams = (
  params: CreateQuizParams,
  uid: string
): BaseEntity | CollaborativeEntity => {
  const entity: BaseEntity | CollaborativeEntity = {
    // create entity based on params
    ...(params.isCollaborative
      ? createCollaborativeEntity(createOwnerUserRole(uid), params)
      : createEntity(params)),
    // add params to entity
    ...params
  };

  entity.firestoreDocumentPath = firestoreEntityPathByParams(
    params,
    uid,
    entity.id
  );

  // remove last part of path
  entity.firestoreCollectionPath = entity.firestoreDocumentPath.substring(
    0,
    entity.firestoreDocumentPath.lastIndexOf('/')
  );

  return entity;
};

// See libs/store/src/lib/+quizzes/quiz.effects.ts:163
export const createSubQuizBasedOnParams = (
  params: CreateSubQuizParams,
  uid: string
): BaseEntity | CollaborativeEntity => {
  const baseParams: CreateSubQuizParams = {
    ...params,
    entityTypeValue:
      params.parentEntity?.entityTypeValue || params.parentEntity?.entityType
  };

  const entity: BaseEntity | CollaborativeEntity = {
    // create entity based on params
    ...(params.isCollaborative
      ? createCollaborativeEntity(createOwnerUserRole(uid), params)
      : createEntity(params)),
    // add params to entity
    ...params
  };

  entity.firestoreDocumentPath = `${firestoreEntityPathByParams(
    baseParams,
    uid,
    baseParams.parentEntity.id
  )}/${firestoreEntitySubPath(params, entity.id)}`;

  entity.firestoreCollectionPath = entity.firestoreDocumentPath.substring(
    0,
    entity.firestoreDocumentPath.lastIndexOf('/')
  );

  (entity as Quiz).state = QUIZ_STATE.DRAFT;

  return entity;
};

// OPEN AI OPEN AI OPEN AI
// OPEN AI OPEN AI OPEN AI
// OPEN AI OPEN AI OPEN AI

export function createOpenAiMultipleChoiceQuestion(
  question: OpenAiMultipleChoiceQuestion,
  quiz: Quiz
): Question {
  const answers: Answer[] = question.answers.map(answer => {
    const answerObj: Answer = createMultichoiceAnswer();
    answerObj.answerText = answer.answer;
    answerObj.isCorrect = answer.isCorrect;
    return answerObj;
  });

  const questionObj: Question = createQuestion(
    quiz,
    QUESTION_TYPE.MULTIPLE_CHOICE
  );

  questionObj.question = question.question;

  answers.forEach((currentAnswer: Answer) => {
    questionObj.answers[currentAnswer.id] = currentAnswer;
  });

  return questionObj;
}

export function createOpenAiTrueFalseQuestion(
  question: OpenAiTrueFalseQuestion,
  quiz: Quiz
): Question {
  const trueFalseAnswer = question.answers.reduce(
    (trueFalseAnswer: boolean, answer: OpenAiTrueFalseChoiceAnswer) => {
      if (answer.isCorrect) {
        trueFalseAnswer = answer.answer;
      }

      return trueFalseAnswer;
    },
    false
  );

  const questionObj: Question = createTruFalseQuestion(
    quiz,
    QUESTION_TYPE.TRUE_FALSE
  );

  questionObj.question = question.question;

  const answerObj: Answer =
    questionObj.answers[Object.keys(questionObj.answers)[0]];
  answerObj.trueFalseAnswer = trueFalseAnswer;

  questionObj.answers[answerObj.id] = answerObj;

  return questionObj;
}

export function createQuestionsFromOpenAiResponse(
  quiz: Quiz,
  openAiQuestions: OpenAiQuestion[]
): Question[] {
  // console.log(openAiQuestions);

  return openAiQuestions.map((openAiQuestion: OpenAiQuestion) => {
    if (openAiQuestion.type === 'true_false') {
      return createOpenAiTrueFalseQuestion(
        <OpenAiTrueFalseQuestion>openAiQuestion,
        quiz
      );
    }
    return createOpenAiMultipleChoiceQuestion(
      <OpenAiMultipleChoiceQuestion>openAiQuestion,
      quiz
    );
  });
}

export const createQuizEntity = (params: CreateQuizParamsV2Uid) => {
  return params.parentEntity
    ? createSubQuizBasedOnParams(params as CreateSubQuizParams, params.uid)
    : createQuizBasedOnParams(params, params.uid);
};
