import {DlcNoteProcessQueue} from './note.model';

export const addProcessQueueIdNoteDoc = (
  item: DlcNoteProcessQueue
): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 1
  };
};

export const addProcessQueueIdNoteTitle = (
  item: DlcNoteProcessQueue
): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 2
  };
};

export const addProcessQueueIdNoteVersion = (
  item: DlcNoteProcessQueue
): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 3
  };
};

export const addProcessQueueIdTranscriptionVersion = (
  item: DlcNoteProcessQueue
): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 4
  };
};
