import {Injectable, NgZone} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {Store} from '@ngrx/store';
import {where} from 'firebase/firestore';

import {NgPatAccountState} from '../../../+account/account.model';
import {NgPatServiceConnector} from '../../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../../+websocket-registry/websocket-registry.models';
import {aggregateUpdates} from '../../../fns/aggregate-updates';
import {StripeFirestorePathsService} from '../firestore-paths/stripe-firestore-paths.service';
import {
  ngPatDeleteStripeSubscriptions,
  ngPatUpdateStripeSubscriptions,
  ngPatUpsertStripeSubscriptions
} from './subscription.actions';
import {NgPatStripeSubscriptionItem} from './subscription.model';
import {subscriptionFeatureKey} from './subscription.reducer';

// import {firestoreSubscriptionCollection} from '@gigasoftware/shared/models';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements NgPatFirebaseConnectionService {
  private _queryService!: NgPatFirestoreCollectionQuery<NgPatStripeSubscriptionItem>;

  connection: NgPatServiceConnector;

  connectionKey = subscriptionFeatureKey;

  constructor(
    private _firestore: NgPatFirestoreService,
    private customFirestoreService: NgPatFirestoreService,
    private store: Store,
    private _zone: NgZone,
    private paths: StripeFirestorePathsService
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  ngPatOnInit() {
    this._queryService =
      new NgPatFirestoreCollectionQuery<NgPatStripeSubscriptionItem>(
        {
          deleteManyAction: (ids: string[]) =>
            ngPatDeleteStripeSubscriptions({ids}),
          logUpsert: false,
          mapFirestoreID: true,
          queryConstrains: [where('status', '==', 'active')],
          queryMember: false,
          updateManyAction: (subscriptions: NgPatStripeSubscriptionItem[]) =>
            ngPatUpdateStripeSubscriptions({
              subscriptions: aggregateUpdates(subscriptions)
            }),
          upsertManyAction: (subscriptions: NgPatStripeSubscriptionItem[]) =>
            ngPatUpsertStripeSubscriptions({subscriptions})
        },
        this.store,
        this._firestore
      );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    // console.log(user);
    // console.log(firestoreUserSubscriptionsCollection(<string>user.uid));

    if (this._queryService) {
      this._queryService.onConnect(
        this.paths.subscriptions(<string>user.uid),
        null,
        <string>user.uid
      );
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    if (this._queryService) {
      this._queryService.onDisconnect();
    }
  }
}
