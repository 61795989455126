import {GSStorageAssetBlob, MAX_IMAGE_SIZES} from '@gigasoftware/shared/api';

export function getTotalBytesFromResizedImages(
  resizedImages: GSStorageAssetBlob[]
): number {
  return resizedImages.reduce((acc, curr: GSStorageAssetBlob) => {
    const currentSize = curr.blob?.size || 0;
    return acc + currentSize;
  }, 0);
}

/**
 * Input a number representing the width or height of an image.
 * Output the image size that is larger but closest to the input
 * from the MAX_IMAGE_SIZES array.
 * @param imageSize
 */
export function getMaxImageSizeFromStandardImageSizes(
  imageSize: number
): number {
  const maxSizeFromMaxImageSizes = Math.max(...MAX_IMAGE_SIZES);
  const minSizeFromMaxImageSizes = Math.min(...MAX_IMAGE_SIZES);

  if (imageSize >= maxSizeFromMaxImageSizes) {
    return maxSizeFromMaxImageSizes;
  }

  if (imageSize <= minSizeFromMaxImageSizes) {
    return minSizeFromMaxImageSizes;
  }

  const maxImageSizes = MAX_IMAGE_SIZES.filter((size: number) => {
    return size >= imageSize;
  });

  return Math.min(...maxImageSizes);
}

/**
 * Get image path to thumbnail from image path to full size image.
 * @param imagePath - path/to/image.png
 * @param thumbSize number - 200
 * @return string - path/to/image/image_200x200.png
 */
export function getThumbnailPathFromImagePath(
  imagePath: string,
  thumbSize: number
): string {
  const pathParts = imagePath.split('/');
  if (pathParts && pathParts.length > 0) {
    const filename = pathParts.pop();
    const filenameParts = filename?.split('.') || [];
    const filenameWithoutExtension = filenameParts[0];
    const filenameExtension = filenameParts[1];

    return `${pathParts.join(
      '/'
    )}/${filenameWithoutExtension}_${thumbSize}x${thumbSize}.${filenameExtension}`;
  } else {
    return '';
  }
}
