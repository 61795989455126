import {URL_PROPS} from '@gigasoftware/shared/api';

import {NavItemDict} from '../../../+ui/ui.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictQuiz: NavItemDict = {
  [NAV.MOBILE_QUIZ_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_QUIZ_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Quiz',
    navigateTo: ['mobile', 'quiz-create'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    url: '/mobile/quiz-create'
  },
  [NAV.MOBILE_QUIZ_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_QUIZ_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Quiz',
    navigateTo: ['mobile', 'quiz-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    url: '/mobile/quiz-edit'
  },
  [NAV.MOBILE_QUIZ_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_QUIZ_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Grades',
    navigateTo: ['mobile', 'quiz-grades'],
    paramKeys: [URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    url: '/mobile/quiz-grades'
  },
  [NAV.MOBILE_QUIZ_LIST_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_QUIZ_LIST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quizzes',
    navigateTo: ['mobile', 'ec-quiz-list'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/quiz-list'
  },
  [NAV.MOBILE_QUIZ_QUESTION_CREATE_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_QUIZ_QUESTION_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Question',
    navigateTo: ['mobile', 'quiz-question-create'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_EDIT_PAGE,
    url: '/mobile/quiz-question-create'
  },
  [NAV.MOBILE_QUIZ_QUESTION_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_QUIZ_QUESTION_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Question',
    navigateTo: ['mobile', 'quiz-question-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_EDIT_PAGE,
    url: '/mobile/quiz-question-edit'
  },
  [NAV.MOBILE_QUIZ_REVIEW]: {
    icon: 'read_more',
    id: NAV.MOBILE_QUIZ_REVIEW,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Review',
    navigateTo: ['mobile', 'quiz-review'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_GRADES_PAGE,
    url: '/mobile/quiz-review'
  },
  [NAV.MOBILE_QUIZ_STATS_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_QUIZ_STATS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'quiz-stats'],
    paramKeys: [URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    url: '/mobile/quiz-stats'
  },
  [NAV.MOBILE_QUIZ_TEST_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_QUIZ_TEST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'quiz-test'],
    paramKeys: [URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_QUIZ_STATS_PAGE,
    url: '/mobile/quiz-test'
  }
};
