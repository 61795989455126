import {inject, Injectable} from '@angular/core';
import {GigaNoteDoc} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {GsAssetService} from '@gigasoftware/shared/media';
import {NgPatProcessQueue} from '@gigasoftware/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class DlcDeleteNoteService {
  firestore = inject(NgPatFirestoreService);
  private assetService = inject(GsAssetService);

  processQueue = new NgPatProcessQueue<GigaNoteDoc>();

  constructor() {
    this.processQueue.currentItem$.subscribe(async (doc: GigaNoteDoc) => {
      if (doc.imagePath && doc.imagePath.length > 0) {
        await this.assetService.deleteImagesInSetBasedOnImagePath(
          doc.imagePath
        );
      }
      await this.firestore.recursiveDelete(doc.firestorePath);

      this.processQueue.next();
    });
  }

  deleteNote(doc: GigaNoteDoc) {
    this.processQueue.addItem(doc);
  }

  deleteNotes(doc: GigaNoteDoc[]) {
    this.processQueue.addItems(doc);
  }
}
