import {BASE_COLLECTION_NAME} from '../base-collection-path';

export function firestoreEvolvingCognitionAppVersion() {
  return `app/ec-app-release`;
}

export function firestoreQuizKitMarketingVersion() {
  return `app/quiz-kit-marketing-release`;
}

export function firestorePatternsVersion() {
  return `app/pattern-app-release`;
}

export function firestorePromoCodeCollection() {
  return `${BASE_COLLECTION_NAME.PROMO_CODES}`;
}
