import {inject} from '@angular/core';
import {NgPatAggregateFirebaseSnapshotChanges} from '@gigasoftware/shared/api';
import {
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  QuerySnapshot
} from 'firebase/firestore';
import {Observable, Observer} from 'rxjs';

import {aggregateDocChangesFns} from '../fns/aggregate-doc-changes.fns';
import {removeTimestampCTorFromDocumentSnapshot} from '../fns/firestore.fns';
import {NgPatFirestoreService} from './ng-pat-firestore.service';

/**
 * @private use onSnapshotDoc$ instead
 * @param firestore
 */
export function _onSnapshotDoc$<T>(
  firestore: NgPatFirestoreService
): (source: Observable<string | null | undefined>) => Observable<T> {
  return <T>(source: Observable<string | null | undefined>) => {
    return new Observable((observer: Observer<T>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let firebaseUnsubscribe: () => void = () => {};
      let previousPath: string | null = null;

      const subscription = source.subscribe({
        complete() {
          observer.complete();
        },
        error(err) {
          observer.error(err);
        },
        next(currentPath: string | null | undefined) {
          if (currentPath && currentPath !== previousPath) {
            previousPath = currentPath;
            firebaseUnsubscribe();
            firebaseUnsubscribe = onSnapshot(
              firestore.docRef(currentPath),
              (snap: DocumentSnapshot<DocumentData>) => {
                if (snap.data() !== null && snap.data() !== undefined) {
                  observer.next(
                    <T>removeTimestampCTorFromDocumentSnapshot(snap)
                  );
                }
              }
            );
          } else if (!currentPath) {
            firebaseUnsubscribe();
            previousPath = null;
          }
        }
      });

      // Return the finalization logic. This will be invoked when
      // the result errors, completes, or is unsubscribed.
      return () => {
        firebaseUnsubscribe();
        subscription.unsubscribe();
      };
    });
  };
}

export function onSnapshotDoc<T>() {
  return _onSnapshotDoc$<T>(inject(NgPatFirestoreService));
}

export function _onSnapshotCollection$<T>(
  firestore: NgPatFirestoreService,
  id = 'id',
  mapFirestoreId = false
): (
  source: Observable<string | null | undefined>
) => Observable<NgPatAggregateFirebaseSnapshotChanges<T>> {
  return (source: Observable<string | null | undefined>) => {
    return new Observable(
      (observer: Observer<NgPatAggregateFirebaseSnapshotChanges<T>>) => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let firebaseUnsubscribe: () => void = () => {};
        let previousPath: string | null = null;

        const subscription = source.subscribe({
          complete() {
            observer.complete();
          },
          error(err) {
            observer.error(err);
          },
          next(currentPath: string | null | undefined) {
            if (currentPath && currentPath !== previousPath) {
              previousPath = currentPath;
              firebaseUnsubscribe();
              firebaseUnsubscribe = onSnapshot(
                firestore.collectionRef(currentPath),
                (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
                  observer.next(
                    aggregateDocChangesFns<T>(
                      snapshot.docChanges(),
                      id,
                      mapFirestoreId
                    )
                  );
                }
              );
            } else if (!currentPath) {
              firebaseUnsubscribe();
              previousPath = null;
            }
          }
        });

        // Return the finalization logic. This will be invoked when
        // the result errors, completes, or is unsubscribed.
        return () => {
          firebaseUnsubscribe();
          subscription.unsubscribe();
        };
      }
    );
  };
}

export function onSnapshotCollection$<T>(
  id = 'id',
  mapFirestoreId = false
): (
  source: Observable<string | null | undefined>
) => Observable<NgPatAggregateFirebaseSnapshotChanges<T>> {
  return _onSnapshotCollection$<T>(
    inject(NgPatFirestoreService),
    id,
    mapFirestoreId
  );
}
