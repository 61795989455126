import {FirebaseApp} from 'firebase/app';
import {Auth} from 'firebase/auth';
import {Firestore} from 'firebase/firestore';
import {Functions} from 'firebase/functions';
import {RemoteConfig} from 'firebase/remote-config';
import {FirebaseStorage} from 'firebase/storage';

import {ENV_SERVER_ENUM} from '../../env-server-enum';

export interface Exists<T> {
  data: T;
  exists: boolean;
}

export interface NgPatTriggerEmailMessageConfig {
  /**
   * Email Subject
   */
  subject: string;

  /**
   * Text format of email message.
   */
  text: string;

  /**
   * HTML format of email message.
   */
  // html: string;
}

/**
 * See https://firebase.google.com/docs/extensions/official/firestore-send-email
 */
export interface NgPatFirestoreTriggerEmailDoc {
  id?: string;
  message: NgPatTriggerEmailMessageConfig;

  /**
   * An array containing the BCC recipient UIDs.
   */
  // bccUids: string[];

  /**
   * A single recipient email address
   * or an array containing multiple
   * recipient email addresses.
   */
  to: string[];
}

/**
 * For angular service to write NgPatFirestoreTriggerEmailDoc
 * to Firestore
 */
export interface FirestoreWriteEmailConfig {
  /**
   * Firestore  Email Doc - NgPatFirestoreTriggerEmailDoc
   */
  doc: NgPatFirestoreTriggerEmailDoc;

  /**
   * Firestore Document ID
   */
  id: string;
}

/**
 * Keys and values must match remove config keys
 * https://console.firebase.google.com/u/0/project/evolving-cognition-app/config
 */
export enum RemoteConfigKey {
  trialDays = 'trialDays',
  maxNumberQuizzesWhileInTrial = 'maxNumberQuizzesWhileInTrial'
}

/**
 * Configuration from firebaseConfigParams project.
 */
export interface FirebaseConfig {
  apiKey: string;
  appId: string;
  authDomain: string;
  measurementId: string;
  // databaseURL?: string; // used for firebaseConfigParams real time database, not firestore
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
}

export interface DatabasePaths {
  // Path to users collection
  users: string;
}

export interface RemoteConfigParams {
  settings: {
    /**
     * // https://firebase.google.com/docs/remote-config/get-started?platform=web
     * Defines the maximum age in milliseconds of an entry in the config cache before
     * it is considered stale. Defaults to 43200000 (Twelve hours).
     */
    minimumFetchIntervalMillis: number;

    /**
     * The time in milliseconds to refresh the remote config.
     */
    refreshIntervalMillis: number;
  };
}

/**
 * Firebase services configuration.
 * Wraps firebaseConfigParams config with additional params
 * for path to user collection and remote config
 * polling params.
 */
export interface NgPatFirebaseAppConfig {
  appName: string | null;

  appNameInFirebaseConfig: boolean;
  /** database paths */
  databasePaths?: DatabasePaths;
  defaultRemoteConfig?: any;

  env: ENV_SERVER_ENUM;
  firebase: FirebaseConfig;

  remoteConfigParams?: RemoteConfigParams;
}

export interface NgPatFirebaseAppInstance extends NgPatFirebaseAppConfig {
  analytics: any;
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  functions: Functions;
  remoteConfig: RemoteConfig;
  storage: FirebaseStorage;
}

export interface NgPatAggregateFirebaseSnapshotChanges<T> {
  added: T[];
  // modified: Update<{ id: string }>[];
  modified: T[];
  removed: string[];
}

export interface NgPatFirebaseConfigCreatorParams {
  appName: string | null;
  appNameInFirebaseConfig: boolean;
  databasePaths: any;
  // Add app name to firebaseConfigParams
  defaultRemoteConfig?: any;
  env: ENV_SERVER_ENUM;
  firebase: FirebaseConfig;
  remoteConfigParams: RemoteConfigParams;
}

/**
 * Sync with GigaTranscribeImageDoc in
 * apps/firebase/functions/src/gigasoftware-shared/api/lib/models.ts
 * libs/shared/api/src/lib/models.ts
 */
export enum GigaActionState {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

/**
 * Sync with GigaTranscribeImageDoc in
 * apps/firebase/functions/src/gigasoftware-shared/api/lib/models.ts
 * libs/shared/api/src/lib/models.ts
 */
export enum GigaAssetState {
  PENDING = 'pending',
  UPLOADED = 'uploaded',
  FAILED = 'failed'
}

export interface GigaNoteTranscribedVersion {
  // number in the sequence of transcribed versions
  order: number;
  text: string;
  transcribedTextId: string;
}

export interface GigaUserNoteVersion {
  // number in the sequence of transcribed versions
  order: number;
  text: string;
  userNoteId: string;
}

/**
 * Sync with GigaTranscribeImageDoc in
 * apps/firebase/functions/src/gigasoftware-shared/api/lib/models.ts
 * libs/shared/api/src/lib/models.ts
 * apps/firebase/functions/src/gigasoftware-shared/api/lib/models.ts
 * libs/shared/firebase/src/lib/models/firestore.model.ts
 */
export interface GigaNoteDoc {
  // Path to this document in Firestore
  firestorePath: string;
  id: string;

  // Path to the image in Firebase Storage
  imagePath: string;
  imageState: GigaAssetState;
  // Personal or uuid of Study Group or Classroom
  parentId: string;
  title: string;

  // OpenAI transcribed text from the image doc id
  transcribedTextId: string;

  transcribeState: GigaActionState;

  // User note id
  userNoteId: string;
}

export interface GigaNoteTranscribeNoteVersionUpdate {
  doc: GigaNoteDoc;
  text: string;
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
