export enum DlcBtnState {
  IN_PROGRESS = 'in-progress',
  DISABLED = 'disabled',
  ACTIVE = 'active',
  PENDING = 'pending'
}

/**
 * State of the save button
 */
export enum DlcSaveBtnState {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  IN_PROGRESS = 'in-progress'
}

/**
 * Button Action
 */
export enum DlcBtnActionState {
  // no action, this is to update distinctUntilChanged
  IDLE = 'idle',
  DO_SAVE = 'do-save'
}
