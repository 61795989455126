import {isEqual} from '@gigasoftware/shared/fn';
import {createAction, createReducer, on, props, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

export const updateComponentState = createAction(
  '[Component Store] Update Action',
  props<{[componentName: string]: any}>()
);

export const componentStateFeatureKey = 'componentState';

export const initialComponentState: any = {};

export const componentStateReducer = createReducer(
  initialComponentState,
  on(updateComponentState, (state, {componentName, componentState}) => {
    return {[componentName]: {...componentState}};
  })
);

/**
 * @use
 * @Injectable()
 * export class DashboardStore extends ComponentStore<DashboardState> {
 *   constructor(private globalStore: Store) {
 *     super(initialState);
 *     linkToGlobalState(this.state$, 'DashboardStore', this.globalStore);
 *   }
 *
 * @param componentState$
 * @param componentName
 * @param globalStore
 */
export const linkToGlobalState = (
  componentState$: Observable<any>,
  componentName: string,
  globalStore: Store
) => {
  componentState$
    .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)))
    .subscribe(componentState => {
      globalStore.dispatch(
        updateComponentState({componentName, componentState})
      );
    });
};
