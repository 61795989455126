import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {UiNotification} from './notification.model';

export const addNotification = createAction(
  '[Notification/API] Add Notification',
  props<{notification: UiNotification}>()
);

export const setNotification = createAction(
  '[Notification/API] Set Notification',
  props<{notification: UiNotification}>()
);

export const upsertNotification = createAction(
  '[Notification/API] Upsert Notification',
  props<{notification: UiNotification}>()
);

export const addNotifications = createAction(
  '[Notification/API] Add Notifications',
  props<{notifications: UiNotification[]}>()
);

export const upsertNotifications = createAction(
  '[Notification/API] Upsert Notifications',
  props<{notifications: UiNotification[]}>()
);

export const updateNotification = createAction(
  '[Notification/API] Update Notification',
  props<{notification: Update<UiNotification>}>()
);

export const updateNotifications = createAction(
  '[Notification/API] Update Notifications',
  props<{notifications: Update<UiNotification>[]}>()
);

export const mapNotification = createAction(
  '[Notification/API] Map Notification',
  props<{entityMap: EntityMapOne<UiNotification>}>()
);

export const mapNotifications = createAction(
  '[Notification/API] Map Notifications',
  props<{entityMap: EntityMap<UiNotification>}>()
);

export const deleteNotification = createAction(
  '[Notification/API] Delete Notification',
  props<{id: string}>()
);

export const deleteNotificationFromfirestore = createAction(
  '[eNotification/API] Delete eNotification From Firestore',
  props<{id: string}>()
);

export const deleteNotifications = createAction(
  '[Notification/API] Delete Notifications',
  props<{ids: string[]}>()
);

export const loadNotifications = createAction(
  '[Notification/API] Load Notifications',
  props<{notifications: UiNotification[]}>()
);

export const setNotifications = createAction(
  '[Notification/API] Set Notifications',
  props<{notifications: UiNotification[]}>()
);

export const clearNotifications = createAction(
  '[Notification/API] Clear Notifications'
);

export const selectNotificationID = createAction(
  '[Notification/API] Select Notification',
  props<{id: string}>()
);

export const updateFirestorePartialNotification = createAction(
  '[Notification/API] Save Partial Notification',
  props<{changes: Partial<UiNotification>; notification: UiNotification}>()
);

export const onNotificationsEffectInit = createAction(
  '[Notification/API]: Init'
);
