import {InjectionToken} from '@angular/core';
import {
  AuthURLs,
  ENV_SERVER_ENUM,
  NgPatFirebaseAppConfig
} from '@gigasoftware/shared/api';
import {AlgoliaSearchKeys, DeviceBuild} from '@gigasoftware/shared/models';

export const EC_ENVIRONMENT = new InjectionToken('EC_ENVIRONMENT');

export interface EvolutionCognitionEnvironment {
  algoliaPublishedQuizzes: AlgoliaSearchKeys;
  algoliaSubjects: AlgoliaSearchKeys;
  authURLs: AuthURLs;
  deviceBuild: DeviceBuild;
  env: ENV_SERVER_ENUM;
  environment: string;
  firebaseConfigParams: NgPatFirebaseAppConfig;
  isIosOrAndroid: boolean;
  local: boolean; // flaf for local development
  production: boolean;
  stripePublishableKey: string;
}
