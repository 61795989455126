import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../../+account/account.actions';
import * as AppstoreInAppPurchaseActions from './in-app-purchase.actions';
import {
  appStoreCheckoutInProgress,
  loadHardCodedSubscriptionToIAPProducts,
  upsertAppStoreProducts
} from './in-app-purchase.actions';
import {
  IAPMessage,
  IAPProduct,
  IAPProductAndIRegisterProduct
} from './in-app-purchase.model';

export const appstoreInAppPurchaseFeatureKey =
  'ngPat_in_app_Purchase_app_store';

export interface AppstoreInAppPurchaseState extends EntityState<IAPProduct> {
  checkoutInProgress: boolean;
  /**
   * Hard Coded subscription products
   * used to get features for each subscription product
   */
  hardCodedSubscriptionProducts: IAPProductAndIRegisterProduct[];
  message: IAPMessage;
  products: {[id: string]: IAPProduct};

  // additional entities state properties
  selectedAppstoreInAppPurchaseID: string | null;
}

export interface PartialAppstoreInAppPurchaseState {
  readonly [appstoreInAppPurchaseFeatureKey]: AppstoreInAppPurchaseState;
}

export const appstoreInAppPurchaseAdapter: EntityAdapter<IAPProduct> =
  createEntityAdapter<IAPProduct>();

export const initialAppstoreInAppPurchaseState: AppstoreInAppPurchaseState =
  appstoreInAppPurchaseAdapter.getInitialState({
    checkoutInProgress: false,
    hardCodedSubscriptionProducts: [],
    message: {
      isError: false,
      message: '',
      offerId: '',
      productId: ''
    },
    products: {},
    // additional entity state properties
    selectedAppstoreInAppPurchaseID: null
  });

export const iapReducer = createReducer(
  initialAppstoreInAppPurchaseState,
  on(AppstoreInAppPurchaseActions.addAppstoreInAppPurchase, (state, action) =>
    appstoreInAppPurchaseAdapter.addOne(action.appstoreInAppPurchase, state)
  ),
  on(AppstoreInAppPurchaseActions.setAppstoreInAppPurchase, (state, action) =>
    appstoreInAppPurchaseAdapter.setOne(action.appstoreInAppPurchase, state)
  ),
  on(AppstoreInAppPurchaseActions.addAppstoreInAppPurchases, (state, action) =>
    appstoreInAppPurchaseAdapter.addMany(action.appstoreInAppPurchases, state)
  ),
  on(
    AppstoreInAppPurchaseActions.updateAppstoreInAppPurchase,
    (state, action) =>
      appstoreInAppPurchaseAdapter.updateOne(
        action.appstoreInAppPurchase,
        state
      )
  ),
  on(
    AppstoreInAppPurchaseActions.updateAppstoreInAppPurchases,
    (state, action) =>
      appstoreInAppPurchaseAdapter.updateMany(
        action.appstoreInAppPurchases,
        state
      )
  ),
  on(
    AppstoreInAppPurchaseActions.upsertAppstoreInAppPurchase,
    (state, action) =>
      appstoreInAppPurchaseAdapter.upsertOne(
        action.appstoreInAppPurchase,
        state
      )
  ),
  on(
    AppstoreInAppPurchaseActions.upsertAppstoreInAppPurchases,
    (state, action) =>
      appstoreInAppPurchaseAdapter.upsertMany(
        action.appstoreInAppPurchases,
        state
      )
  ),
  on(
    AppstoreInAppPurchaseActions.mapAppstoreInAppPurchase,
    (state, {entityMap}) => {
      return appstoreInAppPurchaseAdapter.mapOne(entityMap, state);
    }
  ),
  on(
    AppstoreInAppPurchaseActions.mapAppstoreInAppPurchases,
    (state, {entityMap}) => {
      return appstoreInAppPurchaseAdapter.map(entityMap, state);
    }
  ),
  on(
    AppstoreInAppPurchaseActions.deleteAppstoreInAppPurchase,
    (state, action) => appstoreInAppPurchaseAdapter.removeOne(action.id, state)
  ),
  on(
    AppstoreInAppPurchaseActions.deleteAppstoreInAppPurchases,
    (state, action) =>
      appstoreInAppPurchaseAdapter.removeMany(action.ids, state)
  ),
  on(AppstoreInAppPurchaseActions.loadAppstoreInAppPurchases, (state, action) =>
    appstoreInAppPurchaseAdapter.setAll(action.appstoreInAppPurchases, state)
  ),
  on(AppstoreInAppPurchaseActions.setAppstoreInAppPurchases, (state, action) =>
    appstoreInAppPurchaseAdapter.setMany(action.appstoreInAppPurchases, state)
  ),
  on(AppstoreInAppPurchaseActions.clearAppstoreInAppPurchases, state =>
    appstoreInAppPurchaseAdapter.removeAll(state)
  ),
  on(ngPatLogout, state => ({
    ...initialAppstoreInAppPurchaseState,
    ...appstoreInAppPurchaseAdapter.removeAll(state)
  })),
  on(AppstoreInAppPurchaseActions.appstoreInAppPurchaseID, (state, action) => {
    return {
      ...state,
      selectedAppstoreInAppPurchaseID: action.id
    };
  }),
  on(upsertAppStoreProducts, (state, action) => {
    // console.log(state);
    // console.log(action);

    const productsDict: {[id: string]: IAPProduct} = action.products.reduce(
      (a: {[id: string]: IAPProduct}, p: IAPProduct) => {
        a[p.id] = p;

        return a;
      },
      {}
    );

    return {
      ...state,
      products: {
        ...state.products,
        ...productsDict
      }
    };
  }),
  on(appStoreCheckoutInProgress, (state, action) => {
    return {
      ...state,
      checkoutInProgress: action.checkoutInProgress
    };
  }),
  on(loadHardCodedSubscriptionToIAPProducts, (state, action) => {
    return {
      ...state,
      hardCodedSubscriptionProducts: action.hardCodedSubscriptionProducts
    };
  })
);
