import {inject, Injectable} from '@angular/core';
import {BaseEntity} from '@gigasoftware/shared/api';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {
  AddedAndDeletedMemoizedSelector,
  NgPatAddedAndDeletedEntities
} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NgPatEntityStore} from '../custom-store/entity-store/ng-pat-entity-store';

/**
 * Needs to be set up in app
 */
@Injectable({
  providedIn: 'root'
})
export class NgPatAllBaseEntitiesService {
  private firestoreService: NgPatFirestoreService = inject(
    NgPatFirestoreService
  );

  private store: Store = inject(Store);

  baseEntityStore: NgPatEntityStore<BaseEntity> =
    new NgPatEntityStore<BaseEntity>();

  private _onDestroy$: Subject<boolean> = new Subject();

  get uid(): string | null {
    return this.firestoreService.uid;
  }

  allBaseEntities$: Observable<BaseEntity[]> = this.baseEntityStore.selectAll$;

  addSelectorAddedAndDeletedBaseEntities(
    selector: AddedAndDeletedMemoizedSelector<BaseEntity>
  ) {
    // Stop listening to the store in case of a new subscription
    this._onDestroy$.next(true);

    this.store
      .select(selector)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((update: NgPatAddedAndDeletedEntities<BaseEntity>) => {
        if (update.deletedIds.length > 0) {
          this.baseEntityStore.removeMany([...update.deletedIds]);
        }

        if (update.addedEntities.length > 0) {
          this.baseEntityStore.upsertMany([...update.addedEntities]);
        }
      });
  }

  destroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
