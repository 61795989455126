import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Browser} from '@capacitor/browser';
import {Bookmark} from '@gigasoftware/shared/api';

import {DlcBookmarkService} from '../../dlc-create-bookmark/dlc-bookmark.service';
import {DlcCopyBookmarkDialogComponent} from '../dlc-copy-bookmark/dlc-copy-bookmark-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-bookmark'
  },
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule
  ],
  selector: 'dlc-bookmark, [dlc-bookmark]',
  styleUrl: './dlc-bookmark.component.scss',
  templateUrl: './dlc-bookmark.component.html'
})
export class DlcBookmarkComponent {
  bookmarkService = inject(DlcBookmarkService);
  dialog: MatDialog = inject(MatDialog);

  doEditBookmark: OutputEmitterRef<Bookmark> = output();

  bookmark: InputSignal<Bookmark> = input.required();

  viewBookmark() {
    Browser.open({url: this.bookmark().url});
  }

  editBookmark() {
    this.bookmarkService.editBookmark(this.bookmark());
    this.doEditBookmark.emit(this.bookmark());
  }

  copyBookmark() {
    this.dialog.open(DlcCopyBookmarkDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: this.bookmark()
    });
  }

  deleteBookmark() {
    this.bookmarkService.deleteBookmark(this.bookmark());
  }
}
