import {inject} from '@angular/core';
import {
  BASE_COLLECTION_NAME,
  Bookmark,
  Classroom,
  firestoreUserBookmarkCollection,
  NgPatAggregateFirebaseSnapshotChanges
} from '@gigasoftware/shared/api';
import {
  NgPatFirestoreService,
  onSnapshotCollection$
} from '@gigasoftware/shared/firebase';
import {NgPatAddedAndDeletedEntities} from '@gigasoftware/shared/models';
import {createEffect} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, filter, map, tap} from 'rxjs/operators';

import {selectNgPatLoggedInUID} from '../+account/account.selectors';
import {selectAddedAndDeletedClassrooms} from '../+classrooms';
import {
  Investigation,
  selectAddedAndDeletedInvestigations
} from '../+investigation';
import {Journal, selectAddedAndDeletedJournals} from '../+journal';
import {Project, selectAddedAndDeletedProjects} from '../+project';
import {Research, selectAddedAndDeletedResearchs} from '../+research/index';
import {selectAddedAndDeletedStudyGroups, StudyGroup} from '../+study-groups';
import {BookmarkActions} from './bookmark.actions';
import {createBookmarkConfigs} from './bookmark.fns';
import {BookmarkService} from './bookmark.service';

// @Injectable()
// export class BookmarkEffects {
//   constructor(private actions$: Actions) {}
// }

export const getUserBookmarks$ = createEffect(
  (store = inject(Store), firestore = inject(NgPatFirestoreService)) => {
    return store.select(selectNgPatLoggedInUID).pipe(
      filter(
        (uid: string | null) =>
          uid !== null && uid !== undefined && uid.length > 0
      ),
      map((uid: string | null) => firestoreUserBookmarkCollection(<string>uid)),
      onSnapshotCollection$<Bookmark>(),
      concatMap(
        (aggregate: NgPatAggregateFirebaseSnapshotChanges<Bookmark>) => {
          const actions = [];
          if (aggregate.added.length) {
            actions.push(
              BookmarkActions.upsertBookmarks({bookmarks: [...aggregate.added]})
            );
          }

          if (aggregate.modified.length) {
            actions.push(
              BookmarkActions.upsertBookmarks({
                bookmarks: [...aggregate.modified]
              })
            );
          }

          if (aggregate.removed.length) {
            actions.push(
              BookmarkActions.deleteBookmarks({ids: [...aggregate.removed]})
            );
          }

          return actions;
        }
      )
    );
  },
  {functional: true}
);

export const getResearchBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedResearchs).pipe(
      tap((data: NgPatAddedAndDeletedEntities<Research>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.RESEARCH
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.RESEARCH
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);

export const getClassroomBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedClassrooms).pipe(
      tap((data: NgPatAddedAndDeletedEntities<Classroom>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.CLASSES
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.CLASSES
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);

export const getInvestigationBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedInvestigations).pipe(
      tap((data: NgPatAddedAndDeletedEntities<Investigation>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.INVESTIGATIONS
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.INVESTIGATIONS
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);

export const getJournalBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedJournals).pipe(
      tap((data: NgPatAddedAndDeletedEntities<Journal>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.JOURNAL
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.JOURNAL
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);

export const getProjectsBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedProjects).pipe(
      tap((data: NgPatAddedAndDeletedEntities<Project>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.PROJECTS
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.PROJECTS
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);

export const getStudyGroupBookmarks$ = createEffect(
  (store = inject(Store), bookmarkService = inject(BookmarkService)) => {
    return store.select(selectAddedAndDeletedStudyGroups).pipe(
      tap((data: NgPatAddedAndDeletedEntities<StudyGroup>) => {
        if (data.addedEntities.length) {
          bookmarkService.addBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.STUDY_GROUPS
            )
          );
        }

        if (data.deletedEntities.length) {
          bookmarkService.deleteBookmarksQuery(
            createBookmarkConfigs(
              data.addedEntities,
              BASE_COLLECTION_NAME.STUDY_GROUPS
            )
          );
        }
      })
    );
  },
  {dispatch: false, functional: true}
);
